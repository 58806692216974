import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import VehicleSelection from '../components/Report/VehicleSelection';
import { useTranslate } from '../hooks/LanguageProvider';
import { toast, Bounce } from 'react-toastify';
import { toTimestamp } from '../utilities/functions';
import { useNavigate, useParams } from "react-router-dom";
import ReportFleetSelection from '../components/Report/ReportFleetSelection';


const backendUrl = process.env.REACT_APP_BACKEND_URL;


function CustomTab({ title, stepNumber, isActive, onSelect }: any) {
  const circleStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: isActive ? 'green' : 'gray',
    display: 'inline-block',
    marginRight: '10px',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={onSelect}>
      <div style={circleStyle} />
      <span>{title}</span>
    </div>
  );
}

function NewFleetReport() { 

  const [selectedVehicles, setSelectedVehicles] = useState<{ id: any; }[]>([]);
 
  const Midnight = new Date();
  const Now = new Date();
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(new Date(Midnight.setHours(0, 0, 0, 0)));
  const [selectedToDate, setSelectedToDate] = useState<Date>(Now);
  const navigate = useNavigate();

  const userID = localStorage.getItem("userID");


  const handleVehicleSelect = (vehicles: { id: any;}[]) => {
    setSelectedVehicles(vehicles); 
  }; 

  const handleFromDate = (FromDate: Date) => {
    setSelectedFromDate(FromDate); 
  };


  const handleToDate = (ToDate: Date) => {
    setSelectedToDate(ToDate); 
  };

  interface ReportInterface {
    id_user: number;
    from_date: string; 
    to_date: string;
    reports_type: string; 
    dispositifs: string; // Peut-être une liste d'ID de dispositifs séparés par des virgules ou un tableau d'ID, selon la structure de votre base de données
  }

  const [activeTab, setActiveTab] = useState<string>('reportSelection');
  const [selectedReports, setSelectedReports] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);;
  const [selectedReport, setSelectedReport] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(0);
  const { translate } = useTranslate();




  useEffect(() => {
    setSelectedReport(selectedReports.length);
  }, [selectedReports]);

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };
 
  const handleNext = () => {
    if (activeTab === 'reportSelection') {
      // Logique pour valider l'étape 1 et passer à l'étape 2
      setActiveTab('vehicleSelection');
    }
  };

  const handlePrev = () => {
    if (activeTab === 'vehicleSelection') {
      // Revenir de l'étape 3 à l'étape 2
      setActiveTab('reportSelection');
    }
  };

     
  const createReport = async () => {
    try {
     
 
     if(selectedVehicles.length==0){ console.log('selected Vehicles length'+selectedVehicles.length) }
     else{ console.log('selected Vehicles length'+selectedVehicles.length) } 

      const dispositifs: string[] = selectedVehicles.map(obj => obj.id);  
      const reportData = {
        id_user: userID, 
        from_date: toTimestamp(selectedFromDate.toISOString()),
        to_date:  toTimestamp(selectedToDate.toISOString()),
        reports_type: selectedReports.join('-'),   
        dispositifs: dispositifs.join('-'),
      };

      const res = await fetch(`${backendUrl}/api/reports/new-reports`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(reportData),
      });

      if (!res.ok) {
        console.error("Error creating report");

        toast.warn(translate("Can't create a report"), {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        return;
      }


      if (res.ok) {
 
      console.log("Report currently being created"); 

      toast.success(translate("Report currently being created !"), {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce, 
      });  
      

    //  navigate("/fleet-reports");  
  
    }

    } catch (error) {
      console.error("Can't create report", error);

      toast.warn(translate("Can't create a report"), {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }  
  };
    
  return (
    <div>
      <div className='row'>
        <div className="col-sm-12 col-md-4">
          <h4 className="mb-3">
            <i className="las la-file-alt" data-rel="bootstrap-tooltip" title="Rapports" ></i>
            {translate("New fleet report")}
          </h4>
        </div>
        {/* <ul className="steps col-sm-12 col-md-4">
          <li className="step step-success">
            <div className="step-content">
              <span className="step-circle">1</span>
              <span className="step-text">   {translate("Reports")}</span>
            </div>
          </li>
          <li className="step step-active">
            <div className="step-content">
              <span className="step-circle">2</span>
              <span className="step-text"> {translate("Setting")}</span>
            </div>
          </li>
          <li className="step">
            <div className="step-content">
              <span className="step-circle">3</span>
              <span className="step-text">{translate("Assignments")}</span>
            </div>
          </li>
        </ul> */}
      </div>
      <Tabs activeKey={activeTab} onSelect={handleSelect} style={{ border: "1px solid #ddd", padding: "15px" }} >
        <Tab eventKey="reportSelection" title={<CustomTab title="Report Selection" stepNumber={1} isActive={activeTab === 'reportSelection'} onSelect={() => handleSelect('reportSelection')} />} >
          <ReportFleetSelection
            selectedReports={selectedReports}
            onReportSelect={(reports) => setSelectedReports(reports)}
          />
        </Tab>
        <Tab eventKey="vehicleSelection" title={<CustomTab title="Vehicle Selection" stepNumber={3} isActive={activeTab === 'vehicleSelection'} onSelect={() => handleSelect('vehicleSelection')} />} >
          <VehicleSelection
            onVehicleSelect={ handleVehicleSelect} fromDate={handleFromDate} todDate={handleToDate}          />
        </Tab>
      </Tabs>
 
      <div className="wizard-buttons">
        {activeTab !== 'reportSelection' && (
          <a className="btn btn-outline-info" onClick={handlePrev} style={{ textAlign: "center", marginRight: "5px" }}>
            <i className="las la-angle-double-left"></i>
            {translate('Previous')}
          </a>

        )}
        {activeTab !== 'vehicleSelection' && (
          <a className={`btn btn-info  ${selectedReport == 0 ? 'disabled' : ''}`} onClick={handleNext} style={{ textAlign: "center" }}>
            {translate('Next')}
            <i className="las la-angle-double-right"></i>
          </a>
        ) || (
            <a className={`btn btn-outline-info ${selectedVehicles.length == 0 ? 'disabled' : ''}`} onClick={ createReport} style={{ textAlign: "center" }}>
              {translate('Create')}
              <i className="las la-angle-double-right"></i>
            </a>
          )}
      </div>
    </div>
  );
}

export default NewFleetReport;
