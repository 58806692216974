import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslate } from "../hooks/LanguageProvider";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { Bounce, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface UserOption {
  label: string; // Afficher le nom et le prénom de l'utilisateur
  value: string; // ID de l'utilisateur
  nom: string; // Nom de l'utilisateur
  prenom: string; // Prénom de l'utilisateur
}

interface User {
  id_user: string;
  nom_user: string;
  company: string;
  prenom_user: string;
  nom_role: string;
  id_role: string;

  // ... autres champs
}

interface RequestUpdateBody{
  first_name: string;
  middle_name: string;
  company: string;
  phone: string;
  email: string;
  username: string;
  passwd: string;
  userM: string | null;
  roleUser: string;
  wilaya: string;
  validite: string;
  manager?: string; // Ajoutez cette ligne
  changePassword: boolean; // Ajoutez cette ligne

}
interface RequestBody {
  first_name: string;
  middle_name: string;
  company: string;
  phone: string;
  email: string;
  username: string;
  passwd: string;
  userM: string | null;
  roleUser: string;
  wilaya: string;
  validite: string;
  manager?: string; // Ajoutez cette ligne

}



const User: React.FC = () => {
  const [userData, setUserData] = useState({
    nom: "",
    prenom: "",
    company: "",
    telephone: "",
    email: "",
    username: "",
    motDePasse: "",
    wilaya: "",
    affecteA: "",
    roles: "",
    valideJusqua: "",
  });
  const navigate = useNavigate();
  const userID = localStorage.getItem("userID");
  const [affecteAOptions, setAffecteAOptions] = useState<UserOption[]>([]);
  const [rolesOptions, setRolesOptions] = useState<UserOption[]>([]);
  const [userExists, setUserExists] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [confirmationMotDePasse, setConfirmationMotDePasse] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { translate } = useTranslate();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [requiredFieldError, setRequiredFieldError] = useState<string>("");
  const [passwordMismatchError, setPasswordMismatchError] =
    useState<string>("");
  const [passwordValidationError, setPasswordValidationError] =
    useState<string>("");

  // const [otherErrors, setOtherErrors] = useState<string[]>([]);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [passwordLength, setPasswordLength] = useState(8); // Longueur par défaut du mot de passe
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);
  const [selectedWilaya, setSelectedWilaya] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const { userurlID } = useParams();
  const isEditMode = !!userurlID;
  useEffect(() => {
    const fetchUserData = async () => {
      if (isEditMode) {
        try {
          const response = await fetch(
            `${backendUrl}/api/userform/${userurlID}`
          );
          const userData = await response.json();

          // Remplir le formulaire avec les données de l'utilisateur
          setUserData({
            nom: userData.userDetails.nom_user,
            prenom: userData.userDetails.prenom_user,
            company: userData.userDetails.company,
            telephone: userData.userDetails.phone_user,
            email: userData.userDetails.email_user,
            username: userData.userDetails.username_user,
            motDePasse: "", // Vous pouvez choisir de ne pas remplir le mot de passe en mode édition
            wilaya: userData.userDetails.wilaya,
            affecteA: userData.managedUserId.id_user,
            roles: userData.userDetails.id_role,
            valideJusqua: userData.userDetails.validite,
          });
          setSelectedWilaya({
            value: userData.userDetails.wilaya,
            label: userData.userDetails.wilaya,
          });

          const selectedUserOption = affecteAOptions.find(
            (option) => option.value === userData.managedUserId.id_user
          );
          if (selectedUserOption) {
            setSelectedUser(selectedUserOption);
          } else {
            setSelectedUser(null); // ou une autre valeur par défaut si nécessaire
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de l'utilisateur :",
            error
          );
        }
      }
    };

    fetchUserData();
  }, [isEditMode, userurlID, affecteAOptions]);

  useEffect(() => {
    const fetchAffecteAOptions = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/users/find/${userID}`);
        const data = await response.json();

        if (Array.isArray(data)) {
          setAffecteAOptions(
            data.map((user: User) => ({
              label: `${user.nom_user} ${user.prenom_user}`, // Concaténez le nom et le prénom
              value: user.id_user,
              nom: user.nom_user, // Ajoutez le nom de l'utilisateur
              prenom: user.prenom_user, // Ajoutez le prénom de l'utilisateur
            }))
          );
        } else {
          console.error("La réponse de l'API n'est pas un tableau :", data);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des options du sélecteur",
          error
        );
      }
    };
    const fetchRolesOptions = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/roles/user/${userID}`);
        const data = await response.json();

        if (Array.isArray(data)) {
          setRolesOptions(
            data.map((role: User) => ({
              label: role.nom_role,
              value: role.id_role,
              nom: role.nom_user, // Ajouter le nom de l'utilisateur
              prenom: role.prenom_user, // Ajouter le prénom de l'utilisateur
            }))
          );
        } else {
          console.error(
            "La réponse de l'API des rôles n'est pas un tableau :",
            data
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des rôles du sélecteur",
          error
        );
      }
    };

    fetchAffecteAOptions();
    fetchRolesOptions();
  }, [userID]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleUsernametChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newUsername = e.target.value;
    setUserData({
      ...userData,
      username: newUsername,
    });

    try {
      if (newUsername !== "") {
        // Check if in edit mode and the new username is the same as the current user's username
        if (isEditMode && newUsername === userData.username) {
          // If it's the same username in edit mode, set userExists to false and return
          setUserExists(false);
          return;
        }

        const response = await fetch(`${backendUrl}/api/verifierUtilisateur`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ thisusername: newUsername }),
        });

        if (!response.ok) {
          console.error("Error during API request:", response.statusText);
          return;
        }

        const data = await response.json();

        // Check if the username already exists, excluding the current user's username
        if (data.exist && newUsername !== userData.username) {
          setUserExists(true);
        } else {
          setUserExists(false);
        }
      } else {
        // If the username field is empty, reset userExists to false
        setUserExists(false);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  // Dans votre composant
  const handleUserChange = (selectedOption: UserOption | null) => {
    setSelectedUser(selectedOption); // Stockez l'objet complet de l'utilisateur
  };

  const handleWilayaChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setSelectedWilaya(
      selectedOption
        ? { value: selectedOption.value, label: selectedOption.label }
        : null
    );
  };

  const wilayasOptions = [
    { value: "Adrar", label: "Adrar" },
    { value: "Chlef", label: "Chlef" },
    { value: "Laghouat", label: "Laghouat" },
    { value: "Oum El Bouaghi", label: "Oum El Bouaghi" },
    { value: "Batna", label: "Batna" },
    { value: "Béjaïa", label: "Béjaïa" },
    { value: "Biskra", label: "Biskra" },
    { value: "Béchar", label: "Béchar" },
    { value: "Blida", label: "Blida" },
    { value: "Bouira", label: "Bouira" },
    { value: "Tamanrasset", label: "Tamanrasset" },
    { value: "Tébessa", label: "Tébessa" },
    { value: "Tlemcen", label: "Tlemcen" },
    { value: "Tiaret", label: "Tiaret" },
    { value: "Tizi Ouzou", label: "Tizi Ouzou" },
    { value: "Alger", label: "Alger" },
    { value: "Djelfa", label: "Djelfa" },
    { value: "Jijel", label: "Jijel" },
    { value: "Sétif", label: "Sétif" },
    { value: "Saïda", label: "Saïda" },
    { value: "Skikda", label: "Skikda" },
    { value: "Sidi Bel Abbès", label: "Sidi Bel Abbès" },
    { value: "Annaba", label: "Annaba" },
    { value: "Guelma", label: "Guelma" },
    { value: "Constantine", label: "Constantine" },
    { value: "Médéa", label: "Médéa" },
    { value: "Mostaganem", label: "Mostaganem" },
    { value: "M'Sila", label: "M'Sila" },
    { value: "Mascara", label: "Mascara" },
    { value: "Ouargla", label: "Ouargla" },
    { value: "Oran", label: "Oran" },
    { value: "El Bayadh", label: "El Bayadh" },
    { value: "Illizi", label: "Illizi" },
    { value: "Bordj Bou Arréridj", label: "Bordj Bou Arréridj" },
    { value: "Boumerdès", label: "Boumerdès" },
    { value: "El Tarf", label: "El Tarf" },
    { value: "Tindouf", label: "Tindouf" },
    { value: "Tissemsilt", label: "Tissemsilt" },
    { value: "El Oued", label: "El Oued" },
    { value: "Khenchela", label: "Khenchela" },
    { value: "Souk Ahras", label: "Souk Ahras" },
    { value: "Tipaza", label: "Tipaza" },
    { value: "Mila", label: "Mila" },
    { value: "Aïn Defla", label: "Aïn Defla" },
    { value: "Naâma", label: "Naâma" },
    { value: "Aïn Témouchent", label: "Aïn Témouchent" },
    { value: "Ghardaïa", label: "Ghardaïa" },
    { value: "Relizane", label: "Relizane" },
  ];

  const handleCancel = () => {
    // Annuler l'ajout et revenir à la page des utilisateurs
    setRequiredFieldError("");
    setPasswordMismatchError("");
    setPasswordValidationError("");
    setShowConfirmationModal(false);
    navigate("/users");
  };

  const handleAddUser = async () => {
    try {
      const requestBody: RequestBody = {
        first_name: userData.nom,
        middle_name: userData.prenom,
        company: userData.company,
        passwd: userData.motDePasse,
        email: userData.email,
        username: userData.username,
        wilaya: selectedWilaya ? selectedWilaya.value : "", // Utiliser la wilaya sélectionnée
        phone: userData.telephone,
        userM: selectedUser ? selectedUser.value : "",
        roleUser: userData.roles,
        validite: userData.valideJusqua,
      };

      const response = await fetch(`${backendUrl}/api/addUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();

        // Afficher la modal de succès
        toast.success(
          isEditMode
            ? translate("Utilisateur modifié avec succès")
            : translate("Utilisateur ajouté avec succès"),
          {
            position: "bottom-right",
            autoClose: 2400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        console.error("Échec de la requête API", response.statusText);
      }
    } catch (error) {
      console.error("Erreur lors de l'appel de l'API", error);
    }
    try {
      const timestamp = new Date(Date.now() + 60 * 60 * 1000)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      const details = `Add new user: ${userData.nom}`; // Details for the log

      await fetch(`${backendUrl}/api/log-user-action/${userID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "add",
          page: "Users",
          operation: "ajout",
          timestamp,
          details,
        }),
      });
    } catch (error) {
      console.error("Error logging user action:", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      const requestBody: RequestUpdateBody = {
        first_name: userData.nom,
        middle_name: userData.prenom,
        company: userData.company,
        passwd: userData.motDePasse,
        email: userData.email,
        username: userData.username,
        wilaya: selectedWilaya ? selectedWilaya.value : "",
        phone: userData.telephone,
        userM: selectedUser ? selectedUser.value : "",
        roleUser: userData.roles,
        validite: userData.valideJusqua,
        changePassword: changePassword, // Ajout de changePassword
      };
  
      const response = await fetch(`${backendUrl}/api/updateUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userurlID,
          ...requestBody,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        toast.success(
          isEditMode
            ? translate("Utilisateur modifié avec succès")
            : translate("Utilisateur ajouté avec succès"),
          {
            position: "bottom-right",
            autoClose: 2400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        console.error("Échec de la requête API", response.statusText);
      }
    } catch (error) {
      console.error("Erreur lors de l'appel de l'API", error);
    }
  
    try {
      const timestamp = new Date(Date.now() + 60 * 60 * 1000)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      const details = `Update user: ${userData.nom}`;
  
      await fetch(`${backendUrl}/api/log-user-action/${userID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "update",
          page: "Users",
          operation: "modification",
          timestamp,
          details,
        }),
      });
    } catch (error) {
      console.error("Erreur de journalisation de l'action utilisateur:", error);
    }
  };
  

  // open modal for user action
  const handleOpenConfirmationModal = () => {
    // Réinitialiser les erreurs
    setRequiredFieldError("");
    setPasswordMismatchError("");
    setPasswordValidationError("");
    setUserExists(false);

    // Vérifiez si tous les champs requis sont remplis
    if (
      !userData.nom ||
      !userData.prenom ||
      !userData.username ||
      !selectedWilaya ||
      !selectedUser ||
      !userData.roles ||
      // Vérifiez si le champ mot de passe est vide
      (!userData.motDePasse && isEditMode && changePassword) ||
      // Vérifiez si les mots de passe correspondent
      (userData.motDePasse !== confirmationMotDePasse && changePassword) ||
      // Vérifiez si le mot de passe ne répond pas aux critères de validation
      (!isPasswordValid(userData.motDePasse) && changePassword) ||
      selectedUser.value == "" || // Vérifiez si l'option sélectionnée n'est pas la valeur par défaut
      userData.roles === "" || // Vérifiez si l'option sélectionnée n'est pas la valeur par défaut
      userData.roles === "0" // Vérifiez si l'option sélectionnée n'est pas la valeur par défaut
    ) {
      // Ajouter un message d'erreur pour les champs requis manquants ou les erreurs de mot de passe
      setRequiredFieldError(
        translate("Veuillez remplir tous les champs obligatoires")
      );
      if (!isPasswordValid(userData.motDePasse) && changePassword) {
        // Ajouter un message d'erreur spécifique si le mot de passe n'est pas valide
        setPasswordValidationError(
          translate(
            "Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre, un caractère spécial et faire au moins 8 caractères de long"
          )
        );
      }
      if (userData.motDePasse !== confirmationMotDePasse && changePassword) {
        // Ajouter un message d'erreur pour les mots de passe non correspondants
        setPasswordMismatchError(
          translate("Les mots de passe ne correspondent pas")
        );
      }
      return; // Ajoutez un return pour éviter d'ouvrir le modal si des champs requis sont manquants ou si le mot de passe est invalide
    }

    // Vérifiez si le nom d'utilisateur existe déjà
    if (userExists) {
      // Ajouter un message d'erreur pour le nom d'utilisateur existant
      setUserExists(true);
      return; // Ajoutez un return pour éviter d'ouvrir le modal si le nom d'utilisateur existe déjà
    }

    // Ouvrez la modal de confirmation si aucune erreur n'est détectée
    setShowConfirmationModal(true);
  };

  // confirm button for user action add and modify
  const handleConfirmUpdate = async () => {
    setShowConfirmationModal(false);

    // Effectuer l'action correspondante (ajout ou modification de l'utilisateur)
    if (isEditMode) {
      await handleUpdateUser();
    } else {
      await handleAddUser();
    }
    // Rediriger après l'action
    navigate("/users");
  };

  // Define regular expressions for password validation
  const lower = new RegExp("(?=.*[a-z])");
  const upper = new RegExp("(?=.*[A-Z])");
  const number = new RegExp("(?=.*[0-9])");
  const special = new RegExp("(?=.*[!@#\\$%^&*()\\[\\]\"';,:?/+~`|_-]+)");
  const length = new RegExp("(?=.{8,})");

  // Function to check if password meets all criteria
  const isPasswordValid = (password: string) => {
    return (
      lower.test(password) &&
      upper.test(password) &&
      number.test(password) &&
      special.test(password) &&
      length.test(password)
    );
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    const newErrors = [];

    // Vérifier si le champ de mot de passe est vide
    if (password.trim() === "") {
      // Si le champ de mot de passe est vide, effacer les erreurs
      setPasswordErrors([]);
    } else {
      // Vos vérifications d'erreurs ici...
      if (!lower.test(password)) {
        newErrors.push(
          translate("Le mot de passe doit contenir au moins une minuscule")
        );
      }
      if (!upper.test(password)) {
        newErrors.push(
          translate("Le mot de passe doit contenir au moins une majuscule")
        );
      }
      if (!number.test(password)) {
        newErrors.push(
          translate("Le mot de passe doit contenir au moins un chiffre")
        );
      }
      if (!special.test(password)) {
        newErrors.push(
          translate(
            "Le mot de passe doit contenir au moins un caractère spécial"
          )
        );
      }
      if (!length.test(password)) {
        newErrors.push(
          translate("Le mot de passe doit faire au moins 8 caractères de long")
        );
      }

      // Mise à jour des erreurs
      setPasswordErrors(newErrors);
    }

    // Mettre à jour l'état du mot de passe
    setUserData({
      ...userData,
      motDePasse: password,
    });

    // Style pour les entrées de mot de passe invalides
    const inputStyle = {
      borderColor: newErrors.length > 0 ? "red" : "", // Couleur de la bordure pour les entrées invalides
    };
  };
  // Validation for the confirmation password
  const isConfirmationPasswordValid = (password: string) => {
    return password === userData.motDePasse;
  };

  const handleConfirmationPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const password = e.target.value;
    if (!isConfirmationPasswordValid(password)) {
      // If confirmation password does not match, show an error
      setErrors([translate("Les mots de passe ne correspondent pas")]);
    } else {
      // If confirmation password matches, clear errors
      setErrors([]);
    }
    // Update confirmation password state
    setConfirmationMotDePasse(password);
  };

  const generateRandomPassword = (length: number) => {
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numberChars = "0123456789";
    const specialChars = "[!@#$%^&*?:,;]%~-|+{}()_=<>,";
    const allChars = lowerChars + upperChars + numberChars + specialChars;

    let password = "";

    // Générer au moins un caractère de chaque type
    password += lowerChars[Math.floor(Math.random() * lowerChars.length)];
    password += upperChars[Math.floor(Math.random() * upperChars.length)];
    password += numberChars[Math.floor(Math.random() * numberChars.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Générer les caractères restants jusqu'à atteindre la longueur spécifiée
    for (let i = 0; i < length - 4; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Mélanger les caractères du mot de passe
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  };
  const handleGeneratePassword = () => {
    // Logique de génération de mot de passe aléatoire
    const newPassword = generateRandomPassword(passwordLength);
    // Mettre à jour l'état avec le nouveau mot de passe généré
    setGeneratedPassword(newPassword);
    // Afficher le modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Réinitialiser le champ de mot de passe généré
    setGeneratedPassword("");
    // Fermer le modal
    setShowModal(false);
  };

  const handleCopyPassword = () => {
    // Copier le mot de passe dans le presse-papiers
    navigator.clipboard.writeText(generatedPassword);
  };

  return (
    <div className="container mt-5">
      <div className="mb-3 row">
        <div className="col-sm-12">
          <div className="border d-flex align-items-center mb-4">
            <i className="las la-edit" style={{ fontSize: " 2.5em" }}></i>
            <h2 className="me-5">{isEditMode ? "modify User" : "Add User"}</h2>
          </div>
        </div>
      </div>
      <form>
        <div className="mb-3 row">
          <label htmlFor="nom" className="col-sm-2 col-form-label">
            {translate("Family Name")} * :
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="nom"
              name="nom"
              value={userData.nom}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="prenom" className="col-sm-2 col-form-label">
            {translate("first name")} * :
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="prenom"
              name="prenom"
              value={userData.prenom}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="company" className="col-sm-2 col-form-label">
            {translate("Company")} * :
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              value={userData.company}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="phone" className="col-sm-2 col-form-label">
            {translate("Phone")} :
          </label>

          <div className="col-sm-10 d-flex align-items-center">
            {" "}
            {/* Utilisation de flexbox pour aligner l'icône et le champ de saisie */}
            <i className="las la-phone" style={{ marginRight: "10px" }}></i>{" "}
            {/* Ajout de l'icône de téléphone */}
            <input
              type="tel"
              className="form-control"
              id="telephone"
              name="telephone"
              value={userData.telephone}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="email" className="col-sm-2 col-form-label">
            {translate("Email")} :
          </label>
          <div className="col-sm-10 d-flex align-items-center">
            {" "}
            {/* Utilisation de flexbox pour aligner l'icône et le champ de saisie */}
            <i className="las la-envelope" style={{ marginRight: "10px" }}></i>{" "}
            {/* Ajout de l'icône de téléphone */}
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="username" className="col-sm-2 col-form-label">
            {translate("User name")} * :
          </label>

          <div className="col-sm-10 d-flex flex-column">
            {" "}
            {/* Utilisation de flexbox pour aligner les éléments verticalement */}
            <div className="d-flex align-items-center">
              {" "}
              {/* Utilisation de flexbox pour aligner l'icône et le champ de saisie */}
              <i className="las la-user" style={{ marginRight: "10px" }}></i>{" "}
              {/* Ajout de l'icône de l'utilisateur */}
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={userData.username}
                required
                onChange={handleUsernametChange}
                disabled={isEditMode} // Ajout de la condition pour désactiver le champ en mode édition
              />
            </div>
            {!isEditMode && userExists && (
              <p
                id="textindiqusername"
                style={{ color: "red", fontSize: "12px" }}
              >
                {translate("Le nom d'utilisateur existe déjà")}
              </p>
            )}
            {!isEditMode && !userExists && userData.username !== "" && (
              <p
                id="textindiqusername"
                style={{ color: "green", fontSize: "12px" }}
              >
                {translate("Nom d'utilisateur accepté")}
              </p>
            )}
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="ville" className="col-sm-2 col-form-label">
            {translate("Ville")} :
          </label>
          <div className="col-sm-10">
            <Select
              options={wilayasOptions}
              value={
                selectedWilaya
                  ? wilayasOptions.find(
                      (option) => option.value === selectedWilaya.value
                    )
                  : null
              }
              onChange={handleWilayaChange}
              placeholder={translate("Sélectionnez une wilaya")}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="affecteA" className="col-sm-2 col-form-label">
            {translate("Affecter à")} * :
          </label>
          <div className="col-sm-10">
            <Select
              name="affecteA"
              value={selectedUser}
              onChange={handleUserChange}
              options={affecteAOptions}
              placeholder={translate("User")}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="profil" className="col-sm-2 col-form-label">
            {translate("Profil")} * :
          </label>
          <div className="col-sm-10">
            <select
              className="form-select"
              id="roles"
              name="roles"
              value={userData.roles}
              onChange={handleSelectChange}
              required
            >
              <option value="0"> {translate("Select a role")}</option>
              {rolesOptions.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {isEditMode && (
          <i
            className={`las ${changePassword ? "la-minus" : "la-plus"}`}
            style={{
              cursor: "pointer",
              color: "#007bff", // Couleur du texte
              marginRight: "5px", // Marge à droite
              display: "inline-block", // Affichage en bloc
              border: "1px solid #007bff", // Bordure
              padding: "5px 10px", // Rembourrage
              borderRadius: "5px", // Bordure arrondie
            }}
            onClick={() => setChangePassword(!changePassword)}
          >
            {changePassword
              ? translate("Cancel")
              : translate("Change password")}
          </i>
        )}
        {isEditMode && changePassword && (
          <div>
            <br />
            <>
              {/* Bouton pour ouvrir le modal */}
              <Button
                variant="dark"
                onClick={() => setShowModal(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                title={translate("Generate Password")} // Ajout de l'infobulle
              >
                <i
                  className="las la-lock"
                  style={{
                    fontSize: "20px",
                    marginRight: "0px",
                    alignItems: "center",
                  }}
                ></i>
              </Button>

              {/* Modal pour afficher le mot de passe généré */}
              <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>{translate("Generate Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="align-items-center">
                    <Col Col xs="auto">
                      <Form.Group controlId="generatedPassword">
                        <Form.Control
                          type="text"
                          readOnly
                          value={generatedPassword}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group controlId="passwordLength">
                        <Form.Control
                          type="number"
                          value={passwordLength}
                          onChange={(e) =>
                            setPasswordLength(Number(e.target.value))
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button variant="dark" onClick={handleCopyPassword}>
                        {translate("Copy")}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="dark" onClick={handleGeneratePassword}>
                    {translate("Generate Password")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
            {/* Votre formulaire de modification de mot de passe ici */}
            <div className="mb-3 row">
              <label htmlFor="motDePasse" className="col-sm-2 col-form-label">
                {translate("Mot de passe")} * :
              </label>
              <div className="col-sm-10 position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="motDePasse"
                  name="motDePasse"
                  value={userData.motDePasse}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  className={`password-toggle-icon ${
                    showPassword ? "visible" : ""
                  }`}
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <IoEyeOff /> : <IoEye />}
                </span>
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="confirmationMotDePasse"
                className="col-sm-2 col-form-label"
              >
                {translate("Confirmer le mot de passe *")}
              </label>
              <div className="col-sm-10">
                <input
                  type="password"
                  className={`form-control ${
                    userData.motDePasse !== confirmationMotDePasse
                      ? "is-invalid"
                      : ""
                  }`}
                  id="confirmationMotDePasse"
                  name="confirmationMotDePasse"
                  value={confirmationMotDePasse}
                  onChange={(e) => setConfirmationMotDePasse(e.target.value)}
                  required
                />

                {userData.motDePasse !== confirmationMotDePasse && (
                  <p className="invalid-feedback">
                    {translate("Les mots de passe ne correspondent pas")}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {!isEditMode && (
          <div>
            <br />
            <>
              {/* Bouton pour ouvrir le modal */}
              <Button
                variant="dark"
                onClick={() => setShowModal(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                title={translate("Generate Password")} // Ajout de l'infobulle
              >
                <i
                  className="las la-lock"
                  style={{
                    fontSize: "20px",
                    marginRight: "0px",
                    alignItems: "center",
                  }}
                ></i>
              </Button>

              {/* Modal pour afficher le mot de passe généré */}
              <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>{translate("Generate Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="align-items-center">
                    <Col Col xs="auto">
                      <Form.Group controlId="generatedPassword">
                        <Form.Control
                          type="text"
                          readOnly
                          value={generatedPassword}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group controlId="passwordLength">
                        <Form.Control
                          type="number"
                          value={passwordLength}
                          onChange={(e) =>
                            setPasswordLength(Number(e.target.value))
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button variant="dark" onClick={handleCopyPassword}>
                        {translate("Copy")}
                      </Button>
                    </Col>
                  </Row>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="dark" onClick={handleGeneratePassword}>
                    {translate("Generate Password")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>

            {/* Password Input */}
            <div className="mb-3 row">
              <label htmlFor="motDePasse" className="col-sm-2 col-form-label">
                {translate("Mot de passe")} * :
              </label>
              <div className="col-sm-10 position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="motDePasse"
                  name="motDePasse"
                  value={userData.motDePasse}
                  onChange={handlePasswordChange}
                  required
                />
                {/* Password toggle icon */}
                <span
                  className={`password-toggle-icon ${
                    showPassword ? "visible" : ""
                  }`}
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <IoEyeOff /> : <IoEye />}
                </span>
              </div>
            </div>

            {/* Confirmation Password Input */}
            <div className="mb-3 row">
              <label
                htmlFor="confirmationMotDePasse"
                className="col-sm-2 col-form-label"
              >
                {translate("Confirmer le mot de passe *")}
              </label>
              <div className="col-sm-10">
                <input
                  type="password"
                  className={`form-control ${
                    userData.motDePasse !== confirmationMotDePasse
                      ? "is-invalid"
                      : ""
                  }`}
                  id="confirmationMotDePasse"
                  name="confirmationMotDePasse"
                  value={confirmationMotDePasse}
                  onChange={handleConfirmationPasswordChange}
                  required
                />
                {/* Error message for mismatched confirmation password */}
                {userData.motDePasse !== confirmationMotDePasse && (
                  <p className="invalid-feedback">
                    {translate("Les mots de passe ne correspondent pas")}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {/* <div className="mb-3 row">
          <label htmlFor="valideJusqua" className="col-sm-2 col-form-label">
            {translate("Valid until")}
          </label>
          <div className="col-sm-10">
            <input
              type="date"
              className="form-control"
              id="valideJusqua"
              name="valideJusqua"
              value={userData.valideJusqua}
              onChange={handleInputChange}
            />
          </div>
        </div> */}
        {requiredFieldError && (
          <div
            className="error-message"
            style={{ color: "red", textAlign: "center" }}
          >
            {requiredFieldError}
          </div>
        )}
        <div className="mb-3 row">
          <div className="col-sm-10">
            {errors.map((error, index) => (
              <p
                key={index}
                style={{
                  color: "red",
                  fontSize: "0.875rem",
                  marginTop: "0.25rem",
                  textAlign: "center",
                }}
              >
                {error}
              </p>
            ))}
          </div>
          {/* Affichage des erreurs */}
          {passwordErrors.length > 0 && (
            <div
              className="error-message"
              style={{ color: "#FF5200", textAlign: "center" }}
            >
              <div className="col-sm-10">
                {passwordErrors.map((error, index) => (
                  <p
                    key={index}
                    style={{
                      color: "#FF5200",
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      textAlign: "center",
                    }}
                  >
                    <i
                      className="las la-exclamation-triangle"
                      color="#FF5200"
                    ></i>
                    {error}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="mb-3 row">
          <div className="col-sm-12 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleCancel}
            >
              <i className="fa fa-times" />
              {translate("Cancel")}
            </button>

            <button
              id="saveuser"
              type="button"
              className={`btn ${isEditMode ? "btn-warning" : "btn-success"}`}
              onClick={handleOpenConfirmationModal} // Ouvrir la modal de confirmation au lieu d'appeler directement handleAddUser ou handleUpdateUser
            >
              <i className={`fa ${isEditMode ? "fa-edit" : "fa-user"}`} />
              {isEditMode ? translate("modify User") : translate("Add User")}
            </button>
          </div>

          <Modal
            show={showConfirmationModal}
            onHide={() => setShowConfirmationModal(false)} // Fermer la modal de confirmation si l'utilisateur clique sur le bouton "Cancel" ou en dehors de la modal
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {isEditMode
                  ? translate("Confirmation of modification.")
                  : translate("Confirmation of add.")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isEditMode
                ? translate("Are you sure you want to modify this user?")
                : translate("Are you sure you want to add this user?")}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmationModal(false)}
              >
                {translate("Cancel")}
              </Button>
              <Button variant="primary" onClick={handleConfirmUpdate}>
                {translate("Confirm")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </form>
    </div>
  );
};

export default User;
