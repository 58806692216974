import React, { useState } from 'react';

interface FileUploadDragDropProps {
  onFileSelect: (file: File | null) => void;
  maxSize?: number; // Maximum size in bytes (default is 5MB)
  allowedExtensions?: string[]; // Allowed extensions (e.g., ['image/jpeg', 'image/png'])
}

const FileUploadDragDrop: React.FC<FileUploadDragDropProps> = ({
  onFileSelect,
  maxSize = 5 * 1024 * 1024, // Default size to 5MB
  allowedExtensions = [
    'image/jpeg', 
    'image/png', 
    'application/pdf', 
    'text/csv', 
    'text/plain', 
    'application/vnd.ms-excel', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ] // Default allowed extensions
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateFile = (file: File): boolean => {
    // Check the file size
    if (file.size > maxSize) {
      setErrorMessage(`The file exceeds the maximum size of ${maxSize / 1024 / 1024} MB.`);
      return false;
    }

    // Check the file extension
    if (!allowedExtensions.includes(file.type)) {
      setErrorMessage(`Unsupported file type. Only ${allowedExtensions.join(', ')} files are allowed.`);
      return false;
    }

    setErrorMessage(null); // No error
    return true;
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (validateFile(droppedFile)) {
        setFile(droppedFile);
        onFileSelect(droppedFile);
      } else {
        setFile(null);
        onFileSelect(null);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (validateFile(selectedFile)) {
        setFile(selectedFile);
        onFileSelect(selectedFile);
      } else {
        setFile(null);
        onFileSelect(null);
      }
    }
  };

  return (
    <div>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragActive ? '2px dashed #00f' : '2px dashed #ccc',
          padding: '20px',
          borderRadius: '10px',
          textAlign: 'center',
          backgroundColor: dragActive ? '#f0f8ff' : '#f9f9f9',
          transition: 'background-color 0.3s',
          cursor: 'pointer',
        }}
      >
        <p>{file ? `Selected file: ${file.name}` : 'Drop a file here or click to select one'}</p>

        <input
          type="file"
          onChange={handleFileChange}
          style={{
            display: 'none',
          }}
          id="fileInput"
        />
        <label htmlFor="fileInput" style={{ cursor: 'pointer', color: '#00f' }}>
          {file ? 'Change file' : 'Select a file'}
        </label>
      </div>

      {/* Display error message if necessary */}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default FileUploadDragDrop;
