/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Nav, Image } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { useTranslate } from "../hooks/LanguageProvider";
import Cookies from "universal-cookie";
import Logout from "./Logout";
import { useTheme } from "../hooks/ThemeContext";

interface SidebarProps {
  onToggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onToggleSidebar }) => {
  const { translate } = useTranslate();
  const [isOpen, setIsOpen] = useState("");
  const [activeLogo, setActiveLogo] = useState("header-logo-show");
  const [activeMenuText, setActiveMenuText] = useState("");
  const [menuButtonSidebar, setMenuButtonSidebar] = useState("iq-menu-bt-sidebar-show");
  const [sidebar, setSidebar] = useState("sidebar-open");
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([]);
  const navigate = useNavigate();




  const { isDarkMode, toggleTheme } = useTheme();


  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.matchMedia('(max-width: 1299px)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1299px)');

    const handleMediaQueryChange = (event: MediaQueryListEvent | MediaQueryList) => {
      setIsSmallScreen(event.matches);
    };

    // Add the listener
    mediaQuery.addListener(handleMediaQueryChange);

    // Clean the listener when unmounting the component
    if (isSmallScreen) {


      handleSetIsOpen()


    }
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  interface Permission {
    id_rel: number;
    id_role: number;
    id_permission: number;
    nom_permission: string;
    can_create: number;
    can_read: number;
    can_update: number;
    can_delete: number;
  }

  let userPermissions: Permission[] = [];

  try {
    const permissionsString = localStorage.getItem("userPermissions");
    if (permissionsString) {
      userPermissions = JSON.parse(permissionsString);
    }
  } catch (e) {
    console.error("JSON parsing error", e);
  }

  const checkPermission = (idPermission: number): boolean => {

    return userPermissions.some(
      (permission) => permission.id_permission === idPermission
    );
  };

  const handleSubmenuClick = (submenuId: string): void => {
    const isOpen: boolean = openSubmenus.includes(submenuId);

    if (isOpen) {
      setOpenSubmenus(openSubmenus.filter((id) => id !== submenuId));
    } else {
      setOpenSubmenus([...openSubmenus, submenuId]);
    }
  };

  const handleSetIsOpen = () => {
    isOpen == "open" ? setIsOpen("") : setIsOpen("open");

    sidebar == "sidebar-close"
      ? setSidebar("sidebar-open")
      : setSidebar("sidebar-close");

    activeLogo == "header-logo-show"
      ? setActiveLogo("header-logo-hide")
      : setActiveLogo("header-logo-show");
    activeMenuText == "iq-menu-span-hide"
      ? setActiveMenuText("")
      : setActiveMenuText("iq-menu-span-hide");
    menuButtonSidebar == "iq-menu-bt-sidebar-show"
      ? setMenuButtonSidebar("iq-menu-bt-sidebar-hide")
      : setMenuButtonSidebar("iq-menu-bt-sidebar-show");
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userID");
    const cookies = new Cookies();
    cookies.remove("jwtToken");
    localStorage.removeItem("userPermissions");
    navigate("/login");
  };

  interface MenuItem {
    id: number;
    label: string;
    icon: string;
    to?: string;
    permissionId: number;
    subItems?: MenuItem[];
    divider?: boolean;
  }

  const menuItems: MenuItem[] = [
    {
      id: 1,
      label: "Dashboard",
      icon: "las la-home",
      to: "/",
      permissionId: 1,
    },
    {
      id: 2,
      label: "Roles",
      icon: "las la-check-circle",
      to: "/role",
      permissionId: 2,
    },
    {
      id: 3,
      label: "Map",
      icon: "las la-map",
      to: "/Map",
      permissionId: 3,
    },
    {
      id: 4,
      label: "Fleet",
      icon: "fa fa-light fa-chart-line",
      to: "/Fleet",
      permissionId: 4,
    },
    {
      id: 120,
      label: "TMS",
      icon: "las la-road",
      to: "/tms",
      permissionId: 47,
    }
  ]
  const menuItems2: MenuItem[] = [
    {
      id: 5,
      label: "Vehicle reports",
      icon: "las la-chart-bar",
      permissionId: 8,
      subItems: [
        {
          id: 6,
          label: "List reports",
          icon: "las la-minus",
          to: "/reports",
          permissionId: 8,
        },
        {
          id: 7,
          label: "New report",
          icon: "las la-minus",
          to: "/new-report",
          permissionId: 8,
        },
      ],
    },
    {
      id: 8,
      label: "Fleet reports",
      icon: "las la-users-cog",
      permissionId: 8,
      subItems: [
        {
          id: 9,
          label: "Fleet reports",
          icon: "las la-minus",
          to: "/fleet-reports",
          permissionId: 8,
        },
        {
          id: 10,
          label: "New report",
          icon: "las la-minus",
          to: "/new-fleet-report",
          permissionId: 8,
        },
      ],
    }]
  const menuItems3: MenuItem[] = [
    {
      id: 11,
      label: "Vehicles",
      icon: "las la-car",
      permissionId: 6,
      subItems: [
        {
          id: 12,
          label: "List vehicles",
          icon: "las la-minus",
          to: "/Vehicles",
          permissionId: 6,
        },
        {
          id: 13,
          label: "Vehicle group",
          icon: "las la-minus",
          to: "/groupevehicles",
          permissionId: 6,
        },
      ],
    },
    {
      id: 14,
      label: "Drivers",
      icon: "las la-user-nurse",
      to: "/Drivers",
      permissionId: 6,
    },
    {
      id: 15,
      label: "iButton Fleets",
      icon: "las la-tags",
      to: "/Ibutton",
      permissionId: 13,
    },
    {
      id: 16,
      label: "Points of Interest",
      icon: "las la-map-pin",
      to: "/pois",
      permissionId: 13,
    },
    {
      id: 17,
      label: "SIM cards",
      icon: "las la-sim-card",
      to: "/cartes-sim",
      permissionId: 7,
    },
    {
      id: 18,
      label: "GPS devices",
      icon: "las la-compass",
      permissionId: 17,
      subItems: [
        {
          id: 19,
          label: "List devices",
          icon: "las la-minus",
          to: "/Devices",
          permissionId: 17,
        },
        {
          id: 20,
          label: "Devices Group",
          icon: "las la-minus",
          to: "/groupe-device",
          permissionId: 17,
        },
      ],
    },
    {
      id: 19,
      label: "Alarms",
      icon: "las la-bell",
      permissionId: 18,
      subItems: [
        {
          id: 19,
          label: "Alarm list",
          icon: "las la-minus",
          to: "/Alarms",
          permissionId: 18,
        },
        {
          id: 20,
          label: "Group Alarms",
          icon: "las la-minus",
          to: "/group-alarms",
          permissionId: 18,
        },
        {
          id: 20,
          label: "Notifications",
          icon: "las la-minus",
          to: "/notifications",
          permissionId: 18,
        },
      ],
    },
    {
      id: 21,
      label: "User Activity",
      icon: "las la-user-clock",
      permissionId: 20,
      subItems: [
        {
          id: 22,
          label: "History",
          icon: "las la-minus",
          to: "/history-user",
          permissionId: 20,
        },
        {
          id: 23,
          label: "Log connection",
          icon: "las la-minus",
          to: "/Connection",
          permissionId: 20,
        },
      ],
    },
    {
      id: 24,
      label: "Users",
      icon: "las la-user",
      to: "/Users",
      permissionId: 25,
    },
    {
      id: 28,
      label: "Divider",
      icon: "",
      permissionId: 0,
      divider: true,
    }]

  const menuItems4: MenuItem[] = [
    {
      id: 36,
      label: "Service Ticketing",
      icon: "las la-ticket-alt",
      permissionId: 31,
      subItems: [
        {
          id: 36,
          label: "Tickets",
          icon: "las la-minus",
          to: "/tickets",
          permissionId: 31,
        },
        // {
        //   id: 38,
        //   label: "Agents",
        //   icon: "las la-minus",
        //   to: "/agents",
        //   permissionId: 31,
        // },
      ],
    },
    {
      id: 39,
      label: "Help",
      icon: "lar la-life-ring",
      to: "/Help",
      permissionId: 33,
    },
  ];

  return (
    <div style={{ zIndex: 10015 }} className={`iq-sidebar  sidebar-default  ${sidebar}`}>
      <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
        <Nav.Link to="/" className={`header-logo ${activeLogo}`} as={NavLink}>
          <Image
            className={`img-fluid rounded-normal light-logo`}
            style={{ height: "22px", width: "136px" }}
            src={isDarkMode ? "asset/images/logo_dark.png" : "asset/images/logo.png"}
          ></Image>
        </Nav.Link>
        <div
          className={`iq-menu-bt-sidebar ml-0 ${menuButtonSidebar}`}
          onClick={() => {
            handleSetIsOpen();
            // Use a specific function if needed
            // Add here the code for changing the sidebar if needed
            onToggleSidebar();
          }}
        >
          <i className={`las la-bars wrapper-menu ${isOpen}`}></i>
        </div>
      </div>
      <div
        className={`data-scrollbar `}
        data-scroll="1"
        data-scrollbar="true"
        style={{ overflow: "hidden", outline: "none" }}
      >
        <div className="scroll-content">
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
              {menuItems.map((menuItem) =>
                checkPermission(menuItem.permissionId) ? (
                  menuItem.divider ? (
                    <li key={menuItem.id} className=""></li>
                  ) : (
                    <li key={menuItem.id}>
                      {menuItem.to ? (
                        <Nav.Link to={menuItem.to} className="svg-icon" as={NavLink}>
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>{translate(menuItem.label)}</span>
                        </Nav.Link>
                      ) : (
                        <Nav.Link

                          onClick={() => handleSubmenuClick(menuItem.label)}
                        >
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>
                            {translate(menuItem.label)}
                          </span>
                          <svg style={{ minWidth: "fit-content" }} className="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <polyline points="10 15 15 20 20 15"></polyline> <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>{" "} </svg>
                        </Nav.Link>
                      )}

                      {menuItem.subItems && (
                        <ul
                          className={`iq-submenu ${openSubmenus.includes(menuItem.label)
                            ? "submenu-enter-active"
                            : "submenu-enter"
                            }`}
                        >
                          {menuItem.subItems.map(
                            (subItem) =>
                              checkPermission(subItem.permissionId) && (
                                <Nav.Link
                                  key={subItem.id}
                                  to={subItem.to || "/"}
                                  className="svg-icon"
                                  as={NavLink}
                                  onClick={() => { handleSetIsOpen(); onToggleSidebar(); }}
                                >
                                  <i className={subItem.icon} />
                                  <span>{translate(subItem.label)}</span>
                                </Nav.Link>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  )
                ) : null
              )}
            </ul>

            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="divider" style={{ margin: "0 0 5px" }}></li>
              {menuItems2.map((menuItem) =>
                checkPermission(menuItem.permissionId) ? (
                  menuItem.divider ? (
                    <li key={menuItem.id} className=""></li>
                  ) : (
                    <li key={menuItem.id}>
                      {menuItem.to ? (
                        <Nav.Link to={menuItem.to} className="svg-icon" as={NavLink}>
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>{translate(menuItem.label)}</span>
                        </Nav.Link>
                      ) : (
                        <Nav.Link

                          onClick={() => handleSubmenuClick(menuItem.label)}
                        >
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>
                            {translate(menuItem.label)}
                          </span>
                          <svg style={{ minWidth: "fit-content" }} className="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <polyline points="10 15 15 20 20 15"></polyline> <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>{" "} </svg>
                        </Nav.Link>
                      )}

                      {menuItem.subItems && (
                        <ul
                          className={`iq-submenu ${openSubmenus.includes(menuItem.label)
                            ? "submenu-enter-active"
                            : "submenu-enter"
                            }`}
                          onClick={() => { handleSetIsOpen(); onToggleSidebar(); }}

                        >
                          {menuItem.subItems.map(
                            (subItem) =>
                              checkPermission(subItem.permissionId) && (
                                <Nav.Link
                                  key={subItem.id}
                                  to={subItem.to || "/"}
                                  className="svg-icon"
                                  as={NavLink}
                                >
                                  <i className={subItem.icon} />
                                  <span>{translate(subItem.label)}</span>
                                </Nav.Link>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  )
                ) : null
              )}
            </ul>


            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="divider" style={{ margin: "0 0 5px" }}></li>
              {menuItems3.map((menuItem) =>
                checkPermission(menuItem.permissionId) ? (
                  menuItem.divider ? (
                    <li key={menuItem.id} className=""></li>
                  ) : (
                    <li key={menuItem.id}>
                      {menuItem.to ? (
                        <Nav.Link to={menuItem.to} className="svg-icon" as={NavLink}>
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>{translate(menuItem.label)}</span>
                        </Nav.Link>
                      ) : (
                        <Nav.Link

                          onClick={() => handleSubmenuClick(menuItem.label)}
                        >
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>
                            {translate(menuItem.label)}
                          </span>
                          <svg style={{ minWidth: "fit-content" }} className="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <polyline points="10 15 15 20 20 15"></polyline> <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>{" "} </svg>
                        </Nav.Link>
                      )}

                      {menuItem.subItems && (
                        <ul
                          className={`iq-submenu ${openSubmenus.includes(menuItem.label)
                            ? "submenu-enter-active"
                            : "submenu-enter"
                            }`}
                          onClick={() => { handleSetIsOpen(); onToggleSidebar(); }}
                        >
                          {menuItem.subItems.map(
                            (subItem) =>
                              checkPermission(subItem.permissionId) && (
                                <Nav.Link
                                  key={subItem.id}
                                  to={subItem.to || "/"}
                                  className="svg-icon"
                                  as={NavLink}
                                >
                                  <i className={subItem.icon} />
                                  <span>{translate(subItem.label)}</span>
                                </Nav.Link>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  )
                ) : null
              )}
            </ul>


            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="divider" style={{ margin: "0 0 5px" }}></li>
              {menuItems4.map((menuItem) =>
                checkPermission(menuItem.permissionId) ? (
                  menuItem.divider ? (
                    <li key={menuItem.id} className=""></li>
                  ) : (
                    <li key={menuItem.id}>
                      {menuItem.to ? (
                        <Nav.Link to={menuItem.to} className="svg-icon" as={NavLink}>
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>{translate(menuItem.label)}</span>
                        </Nav.Link>
                      ) : (
                        <Nav.Link

                          onClick={() => handleSubmenuClick(menuItem.label)}
                        >
                          <i className={menuItem.icon} />
                          <span className={`ml-4 ${activeMenuText}`}>
                            {translate(menuItem.label)}
                          </span>
                          <svg style={{ minWidth: "fit-content" }} className="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <polyline points="10 15 15 20 20 15"></polyline> <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>{" "} </svg>
                        </Nav.Link>
                      )}

                      {menuItem.subItems && (
                        <ul
                          className={`iq-submenu ${openSubmenus.includes(menuItem.label)
                            ? "submenu-enter-active"
                            : "submenu-enter"
                            }`}
                          onClick={() => { handleSetIsOpen(); onToggleSidebar(); }}
                        >
                          {menuItem.subItems.map(
                            (subItem) =>
                              checkPermission(subItem.permissionId) && (
                                <Nav.Link
                                  key={subItem.id}
                                  to={subItem.to || "/"}
                                  className="svg-icon"
                                  as={NavLink}
                                >
                                  <i className={subItem.icon} />
                                  <span>{translate(subItem.label)}</span>
                                </Nav.Link>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  )
                ) : null
              )}
            </ul>
          </nav>

          <div className="position-relative sidebar-bottom" style={{ padding: "0!important", margin: 0 }}>
            <nav className="iq-sidebar-menu">
              <ul className="iq-menu" style={{ padding: 0 }}>
                <li className="divider" style={{ margin: "0 0 5px" }}></li>
                <Logout
                  onLogout={handleLogout}
                  activeMenu={activeMenuText}
                  title={translate("Logout")}
                  margin="ml-4"
                />
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
