import React, { useState } from 'react';

interface ChatInputProps {
  onSendMessage: (message: string) => void; // Fonction pour envoyer le message au parent
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [currentMessage, setCurrentMessage] = useState<string>(''); // Suivi du texte actuel

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMessage(e.target.value); // Mettre à jour la valeur de l'entrée
  };

  const handleSendMessage = () => {
    if (currentMessage.trim()) {
      onSendMessage(currentMessage); // Envoyer le message au composant parent
      setCurrentMessage(''); // Réinitialiser l'entrée après l'envoi
    }
  };

  return (
    <div className="chat-input-container" style={{ display: 'flex', gap: '0.5rem' }}>
      <input
        type="text"
        value={currentMessage}
        onChange={handleInputChange}
        onClick={handleSendMessage}
        placeholder="Type a message..."
        className="chat-input"
        style={{ flex: 1, padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
      />
    
      <button  className="gp" style={{ padding: '0.5rem 0.5rem', background:"#fff" }}>
        <i className="la la-paperclip"></i>
      </button>
      <button onClick={handleSendMessage} className="gp" style={{ padding: '0.5rem 1rem' }}>
        Send
        <i className='las la-paper-plane'>  </i>
      </button>
    </div>
  );
};

export default ChatInput;
