import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Widget } from '../../type'; // Import du type Widget

interface WidgetProps {
  widget: Widget;
  onRemove: (id: string) => void;
}

const WidgetComponent: React.FC<WidgetProps> = ({ widget, onRemove }) => {
  // État pour contrôler l'affichage du modal de confirmation
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  // Fonction pour rendre le contenu basé sur le type de widget
  const renderContent = () => {
    switch (widget.type) {
      case 'chart':
        // Remplacez par votre composant de graphique réel
        return <p>Widget Graphique : {widget.content}</p>;
      case 'table':
        // Remplacez par votre composant de tableau réel
        return <p>Widget Tableau : {widget.content}</p>;
      case 'text':
      default:
        return <p>Ceci est {widget.content}</p>;
    }
  };

  // Fonction pour gérer la suppression après confirmation
  const handleDelete = () => {
    onRemove(widget.id);
    setShowConfirm(false);
  };

  return (
    <div className="widget border bg-light h-100 d-flex flex-column">
      <div className="widget-header d-flex justify-content-between align-items-center p-2 bg-secondary text-white">
        <span>{widget.content}</span>
        {/* Bouton de suppression qui ouvre le modal de confirmation */}
        <Button variant="danger" size="sm" onClick={() => setShowConfirm(true)}>
          &times;
        </Button>
      </div>
      <div className="widget-body p-2 flex-grow-1">
        {renderContent()}
      </div>

      {/* Modal de Confirmation */}
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer ce widget ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WidgetComponent;
