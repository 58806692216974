// src/components/Dashboard/Dashboard.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Responsive, WidthProvider, Layouts, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import WidgetComponent from '../components/Dashboard/Widget'; // Chemin corrigé
import AddWidgetModal from '../components/Dashboard/AddWidgetModal'; // Chemin corrigé
import { fetchDashboardWidgets, addWidget, updateDashboard, deleteWidget } from '../components/Dashboard/api'; // Inclure 'updateDashboard' dans l'import
import debounce from 'lodash/debounce'; // Pour le debounce des appels API
import { Widget } from '../type';

// Pour le debounce des appels API
// Wrap Responsive avec WidthProvider pour le comportement responsive
const ResponsiveGridLayout = WidthProvider(Responsive);


const Dashboard: React.FC = () => {
    // État pour les layouts responsives
    const [layouts, setLayouts] = useState<Layouts>({});
    // État pour les widgets
    const [widgets, setWidgets] = useState<Widget[]>([]);
    // État pour la visibilité du modal
    const [showModal, setShowModal] = useState<boolean>(false);
    // État pour le id_dashboard (à définir selon votre logique)
    const id_dashboard = 1; // Exemple statique

    // État pour afficher le statut de sauvegarde (optionnel)
    const [saveStatus, setSaveStatus] = useState<'saving' | 'success' | 'error' | ''>('');

    // Fonction pour sauvegarder le dashboard avec debounce
    // const debouncedSave = useCallback(
    //     debounce(async (updatedWidgets: Widget[]) => {
    //         setSaveStatus('saving');
    //         try {
    //             console.log('Saving widgets:', updatedWidgets);
    //             await updateDashboard(updatedWidgets);
    //             setSaveStatus('success');
    //             console.log('Dashboard sauvegardé avec succès.');
    //         } catch (error) {
    //             setSaveStatus('error');
    //             console.error('Erreur lors de la sauvegarde du dashboard:', error);
    //         }
    //     }, 1000), // 1 seconde de délai
    //     []
    // );
    
    const colsByBreakpoint: { [key: string]: number } = {
        lg: 12,
        md: 10,
        sm: 6,
        xs: 4,
        xxs: 2,
      };
    useEffect(() => {
   
  
            const getDashboardWidgets = async () => {
                try {
                    const fetchedWidgets = await fetchDashboardWidgets(id_dashboard);
                    console.log('Widgets récupérés:', fetchedWidgets); // Log des widgets récupérés
        
                    // Mapper 'id_widget' en 'id'
                    const widgetsWithId = fetchedWidgets.map((w: any) => ({
                        ...w,
                        id: w.id_widget.toString(), // Convertir en string si nécessaire
                    }));
        
                    // Validation des positions et tailles
                    const validatedWidgets = widgetsWithId.map((w: Widget) => {
                        // S'assurer que x + w ne dépasse pas les cols pour 'lg'
                        if (w.x + w.w > colsByBreakpoint.lg) {
                            console.warn(`Widget ${w.id} dépasse les limites de la grille 'lg'. Ajustement automatique.`);
                            w.w = colsByBreakpoint.lg - w.x;
                        }
                        return w;
                    });
        
                    setWidgets(validatedWidgets);
        
                    // Construire les layouts pour react-grid-layout
                    const newLayouts: Layouts = {
                        lg: validatedWidgets.map((w: Widget) => ({
                            i: w.id, // Utiliser 'id' comme 'i' pour react-grid-layout
                            x: w.x,
                            y: w.y,
                            w: w.w,
                            h: w.h,
                        })),
                        md: validatedWidgets.map((w: Widget) => ({
                            i: w.id,
                            x: Math.floor(w.x / 2),
                            y: w.y,
                            w: Math.floor(w.w / 2) || 1,
                            h: w.h,
                        })),
                        sm: validatedWidgets.map((w: Widget) => ({
                            i: w.id,
                            x: Math.floor(w.x / 3),
                            y: w.y,
                            w: Math.floor(w.w / 3) || 1,
                            h: w.h,
                        })),
                        xs: validatedWidgets.map((w: Widget) => ({
                            i: w.id,
                            x: Math.floor(w.x / 4),
                            y: w.y,
                            w: Math.floor(w.w / 4) || 1,
                            h: w.h,
                        })),
                        xxs: validatedWidgets.map((w: Widget) => ({
                            i: w.id,
                            x: 0,
                            y: Math.floor(w.y / 5),
                            w: 1,
                            h: w.h,
                        })),
                    };
                    console.log('Nouveaux layouts:', newLayouts); // Log des layouts construits
                    setLayouts(newLayouts);
                } catch (error) {
                    console.error('Erreur lors de la récupération des widgets:', error);
                    setWidgets([]); // Assure que 'widgets' est toujours un tableau
                }
            };
        
      

        getDashboardWidgets();
    }, [id_dashboard]);



    // src/components/Dashboard/Dashboard.tsx

    const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
        console.log("allLayouts: ", allLayouts);
        console.log("Widgets before update: ", widgets);
        setLayouts(allLayouts); 
        const updatedWidgets = widgets.map((widget) => {
            const layout = currentLayout.find((l) => l.i === widget.id);
            if (layout) {
                console.log(`Updating widget ${widget.id} with layout:`, layout);
                return { ...widget, x: layout.x, y: layout.y, w: layout.w, h: layout.h };
            }
            return widget;
        });
        console.log("Updated Widgets: ", updatedWidgets);
        setWidgets(updatedWidgets);
        //debouncedSave(updatedWidgets); // Appel à la sauvegarde automatique
    };
    

    const handleSave = async () => {
        setSaveStatus('saving');
        try {
            await updateDashboard(widgets);
            setSaveStatus('success');
            console.log('Dashboard sauvegardé avec succès.');
        } catch (error) {
            setSaveStatus('error');
            console.error('Erreur lors de la sauvegarde du dashboard:', error);
        }
    };


    // Gérer l'ajout d'un nouveau widget
    const handleAddWidget = async (content: string, type: string) => {
        try {
            const widgetData: Omit<Widget, 'id'> = {
                id_dashboard: id_dashboard,
                id_widget: 0,
                id_user: 1, // À ajuster selon l'utilisateur connecté
                content,
                type,
                x: 0,
                y: 0,
                w: getDefaultWidth('lg'),
                h: 2,
            };

            const addedWidget = await addWidget(widgetData);

            const id_widget = addedWidget.id_widget; // Extraction correcte

            console.log('Widget ajouté avec ID:', id_widget);
    
            const uniqueId = id_widget?.toString();
    
            const newWidget: Widget = {
                id: uniqueId|| "0",
                ...widgetData,
                id_widget: id_widget, // Maintenant, c'est un nombre
            };
           

            const updatedWidgets = [...widgets, newWidget];
            setWidgets(updatedWidgets);

            const updatedLayouts = { ...layouts };
            Object.keys(updatedLayouts).forEach((breakpoint) => {
                updatedLayouts[breakpoint].push({
                    i: uniqueId || "0", // Utiliser 'i' comme 'id' pour react-grid-layout
                    x: 0,
                    y: 0, // Remplacer Infinity par 0
                    w: getDefaultWidth(breakpoint),
                    h: 2,
                });
            });
            setLayouts(updatedLayouts);

            console.log('Nouveau widget ajouté et dashboard sauvegardé.');
        } catch (error) {
            console.error('Erreur lors de l\'ajout du widget:', error);
        }

        setShowModal(false);
    };

    // Gérer la suppression d'un widget
    const handleRemoveWidget = async (id: string) => {
        try {
            console.log(id);
            await deleteWidget(id);
            const updatedWidgets = widgets.filter((w) => w.id !== id);
            setWidgets(updatedWidgets);

            const updatedLayouts = { ...layouts };
            Object.keys(updatedLayouts).forEach((breakpoint) => {
                updatedLayouts[breakpoint] = updatedLayouts[breakpoint].filter((l) => l.i !== id);
            });
            setLayouts(updatedLayouts);

            console.log('Widget supprimé et dashboard sauvegardé.');
        } catch (error) {
            console.error('Erreur lors de la suppression du widget:', error);
        }
    };

    // Fonction utilitaire pour obtenir la largeur par défaut en fonction du breakpoint
    const getDefaultWidth = (breakpoint: string): number => {
        switch (breakpoint) {
            case 'lg':
                return 4;
            case 'md':
                return 3;
            case 'sm':
                return 2;
            case 'xs':
                return 1;
            case 'xxs':
                return 1;
            default:
                return 2;
        }
    };


    const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('lg');


    return (
        <Container fluid className="mt-4">
            <h2>Mon Dashboard</h2>
            <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
                Ajouter un Widget
            </Button>

            <Button variant="success" onClick={handleSave} className="mb-3 ml-2">
                Sauvegarder les Modifications
            </Button>

            <ResponsiveGridLayout
                className="layout"
                layouts={layouts}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                rowHeight={30}
                onLayoutChange={onLayoutChange}
                onBreakpointChange={(newBreakpoint) => setCurrentBreakpoint(newBreakpoint)}
                draggableHandle=".widget-header"
                margin={[10, 10]}
                compactType="vertical"
                preventCollision={true}
                isResizable={true}
                isDraggable={true}
            >
                {widgets && widgets.length > 0 ? (
                    widgets.map((widget) => (
                        <div key={widget.id} /* Utilisez `widget.id` au lieu de `widget.id_widget` */
                        // Supprimez `data-grid` car les layouts sont déjà gérés via le prop `layouts`
                        >
                            <WidgetComponent widget={widget} onRemove={handleRemoveWidget} />
                        </div>
                    ))
                ) : (
                    <div>Pas de widgets disponibles.</div>
                )}

            </ResponsiveGridLayout>

            {/* Modal pour ajouter un nouveau widget */}
            <AddWidgetModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleAdd={handleAddWidget}
            />
        </Container>
    );
};

export default Dashboard;