import React from "react";

const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    margin: '20px',
  },
  headings: {
    color: '#333',
  },
  pre: {
    backgroundColor: 'rgb(63 63 63)',
    padding: '10px',
    borderRadius: '5px',
    width: "fit-content"
  },
  code: {
    color: '#fff',
    padding: '2px 4px',
    borderRadius: '3px',
  },
};

const FleetManagementDocumentation = () => {
  return (
    <div>
      <h4>API Usage Documentation for Fleet Management Platform</h4>
      <section>
        <h3>1. Introduction PI Endpoints</h3>
        <p>This route retrieves data based on the specified type after verifying the provided API key. It allows users to access various types of data related to a specific PSN (Personal Serial Number) within a specified time range. The API key is used to authenticate and authorize access to the requested data, ensuring secure retrieval of information.</p>
      </section>

      <h4>1. Vehicle Records History by Type</h4>

      <h5>Type 1: GPS, FUEL, DOORS, TEMPERATURE</h5>
      <p><strong>URL:</strong> <code>https://geotrackin.com/api/records</code></p>
      <p><strong>Method:</strong> GET</p>
      <p><strong>Parameters:</strong></p>
      <ul>
        <li><code>key</code> (string): API key for authentication.</li>
        <li><code>type</code> (int): Record type, here <code>1</code>.</li>
        <li><code>PSN</code> (string): Vehicle serial number.</li>
        <li><code>from_date</code> (datetime): Start date and time in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
        <li><code>to_date</code> (datetime): End date and time in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
      </ul>
      <p><strong>Example Request:</strong></p>
      <pre style={styles.pre}><code style={styles.code}>https://geotrackin.com/api/records?key=your_api_key&type=1&PSN=864636065361202&from_date=2024-05-22T00:00:00&to_date=2024-05-22T23:59:59</code></pre>

      <h4>Type 2: DISTANCE, FUEL</h4>
      <p><strong>URL:</strong> <code>https://geotrackin.com/api/records</code></p>
      <p><strong>Method:</strong> GET</p>
      <p><strong>Parameters:</strong></p>
      <ul>
        <li><code>key</code> (string): API key for authentication.</li>
        <li><code>type</code> (int): Record type, here <code>2</code>.</li>
        <li><code>PSN</code> (string): Vehicle serial number.</li>
        <li><code>from_date</code> (datetime): Start date and time in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
        <li><code>to_date</code> (datetime): End date and time in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
      </ul>
      <p><strong>Example Request:</strong></p>
      <pre style={styles.pre}><code style={styles.code}>https://geotrackin.com/api/records?key=your_api_key&type=2&PSN=864636065361202&from_date=2024-05-22T00:00:00&to_date=2024-05-22T23:59:59</code></pre>

      <h4>Type 3: TEMPERATURE, SPEED</h4>
      <p><strong>URL:</strong> <code>https://geotrackin.com/api/records</code></p>
      <p><strong>Method:</strong> GET</p>
      <p><strong>Parameters:</strong></p>
      <ul>
        <li><code>key</code> (string): API key for authentication.</li>
        <li><code>type</code> (int): Record type, here <code>3</code>.</li>
        <li><code>PSN</code> (string): Vehicle serial number.</li>
        <li><code>from_date</code> (datetime): Start date and time in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
        <li><code>to_date</code> (datetime): End date and time in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
      </ul>
      <p><strong>Example Request:</strong></p>
      <pre style={styles.pre}><code style={styles.code}>https://geotrackin.com/api/records?key=your_api_key&type=3&PSN=864636065361202&from_date=2024-05-22T00:00:00&to_date=2024-05-22T23:59:59</code></pre>

      <h3>2. History of All Latest Fleet Positions</h3>
      <p><strong>URL:</strong> <code>https://geotrackin.com/api/record/hello</code></p>
      <p><strong>Method:</strong> GET</p>
      <p><strong>Parameters:</strong></p>
      <ul>
        <li><code>key</code> (string): API key for authentication.</li>
      </ul>
      <p><strong>Example Request:</strong></p>
      <pre style={styles.pre}><code style={styles.code}>https://geotrackin.com/api/record/hello?key=your_api_key</code></pre>

      <h3>3. History of a Vehicle Last Position</h3> 
      <p><strong>URL:</strong> <code>https://geotrackin.com/api/record</code></p>
      <p><strong>Method:</strong> GET</p>
      <p><strong>Parameters:</strong></p>
      <ul>
        <li><code>key</code> (string): API key for authentication.</li>
        <li><code>PSN</code> (string): Vehicle serial number.</li>
      </ul>
      <p><strong>Example Request:</strong></p>
      <pre style={styles.pre}><code style={styles.code}>https://geotrackin.com/api/record?key=your_api_key&PSN=864636065361202</code></pre>

      <h2>Notes</h2>
      <ul>
        <li><strong>Authentication:</strong> All requests require an API key (<code>key</code>) for user authentication.</li>
        <li><strong>Date Format:</strong> Dates must be in the format <code>YYYY-MM-DDTHH:MM:SS</code>.</li>
        <li><strong>Serial Number (PSN):</strong> Ensure you use the correct vehicle serial number for the information you wish to retrieve.</li>
      </ul>


      <h4>Utilisation de API avec curl, Node.js, Python </h4>
      <h4>Base URL</h4>
      <p>
        <code>http://geotrackin.com/</code>
      </p>
      <h4>Routes</h4>
      <h5>1. Verify and Retrieve Data</h5>
      <p><code>GET /record?key=valeur&type=valeur&PSN=valeur&from_date=valeur&to_date=valeur</code></p>
      <p><strong>Parameters:</strong></p>
      <ul>
        <li><code>key</code>: API key.</li>
        <li><code>type</code>: Type of data to retrieve (1, 8, 19).</li>
        <li><code>PSN</code>: Serial number.</li>
        <li><code>from_date</code>: Start date (ISO 8601 format).</li>
        <li><code>to_date</code>: End date (ISO 8601 format).</li>
      </ul>
      <h4>Usage with curl</h4>
      <pre>
        <code>
          curl -X GET
          "https://geotrackin.com/records?key=yourApiKey&type=1&PSN=yourPSN&from_date=2024-01-01T00:00:00&to_date=2024-01-02T00:00:00"
        </code>
      </pre>
      <h4>Usage with Node.js</h4>
      <pre style={styles.pre}><code style={styles.code}>{
        `const axios = require('axios');
  const key = 'yourApiKey';
  const type = 1;
  const PSN = 'yourPSN';
  const from_date = '2024-01-01T00:00:00';
  const to_date = '2024-01-02T00:00:00';

  axios.get(\`https://geotrackin.com/records?key=yourApiKey&type=1&PSN=yourPSN&from_date=2024-01-01T00:00:00&to_date=2024-01-02T00:00:00 \`)
  .then(response => {
  console.log(response.data);
  })
  .catch(error => {
  console.error('Error:', error.response.data);
});
       `}</code>
      </pre>
      <h4>Usage with Python</h4>
      <pre style={styles.pre}><code style={styles.code}>
        {`
 import requests

  key = 'yourApiKey'
  type = 1
  PSN = 'yourPSN'
  from_date = '2024-01-01T00:00:00'
  to_date = '2024-01-02T00:00:00'

  url = f"https://geotrackin.com/records?key=yourApiKey&type=1&PSN=yourPSN&from_date=2024-01-01T00:00:00&to_date=2024-01-02T00:00:00"
  response = requests.get(url)

  if response.status_code == 200:
  print(response.json())
  else:
  print(f"Error: {response.status_code} - {response.json()}")

`}
      </code>
      </pre>

      <h4>API Responses</h4>
      <h5>Success Response (200)</h5>
      <pre style={styles.pre}><code style={styles.code}>{
        `{
  "data": [
    {
      "LAT": 48.8566,
      "LON": 2.3522,
      "TIMESTAMP": "2024-01-01T12:00:00"
      // other data...
    }
  ]
}
        `}</code></pre>
      <h5>Missing API Key Response (400)</h5>
      <pre style={styles.pre}><code style={styles.code}>{`
{
 "message": "No API key found in request",
 "hint": "No \`apikey\` request header or url param was found."
}`}</code></pre>
      <h5>Invalid API Key Response (404)</h5>
      <pre style={styles.pre}><code style={styles.code}>{`{"message": "No user found with the provided API key"}`}</code></pre>
      <h5>Internal Server Error Response (500)</h5>
      <pre style={styles.pre}><code style={styles.code}>{
        `{ "message": "Internal server error Database query error"}`}</code></pre>
     
    </div>
  );
};

export default FleetManagementDocumentation;
