// components/alerts/AlertComponents.tsx
import React from 'react';
import SpeedAlarm from './TypeAlarms/SpeedAlarm';
import PoiAlarm from './TypeAlarms/PoiAlarm';
import AbsenceOfTransmission from './TypeAlarms/AbsenceOfTransmission';

interface AlarmProps {
  alarm: any; // Adapter le type en fonction de votre structure d'alarme
  onChange: (newMetrics: any) => void;
}
  
const alertComponents: { [key: number]: React.FC<AlarmProps> } = {
  48: ({ alarm, onChange }) => <SpeedAlarm alarm={alarm} onChange={onChange} />,  
  2: PoiAlarm,
  3: ({ alarm, onChange }) => <AbsenceOfTransmission alarm={alarm} onChange={onChange} />, 
  // Ajoutez d'autres composants d'alerte ici
};


export default alertComponents;
