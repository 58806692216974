import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../hooks/LanguageProvider";
import { PropagateLoader } from "react-spinners";

const Connections: React.FC = () => {
  const { translate } = useTranslate();
  const userID = localStorage.getItem("userID");
  const [pageCount, setPageCount] = useState(0);
  const [connection, setConnections] = useState([]);
  let currentPage = 1;
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const Fetchconnexion = async (currentPage: number, limit: number) => {
      setLoading(true);
      const total_pages = await fetch(
        `${backendUrl}/api/connexion/totalpage/${userID}`,
        { mode: "cors" }
      );

      const totalpages = await total_pages.json();
      const total = totalpages[0].total;
      setTotal(total);
    

      const calculatedPageCount = Math.ceil(total / limit);
      setPageCount(calculatedPageCount);

      const res = await fetch(
        `${backendUrl}/api/connexion/${userID}?page=${currentPage}&limit=${limit}`,

        { mode: "cors" }
      );
      const data = await res.json();
      setConnections(data);
      setLoading(false);
    };
    Fetchconnexion(currentPage, limit);
  }, [limit, userID]);

  const fetchConn = async (currentPage: number, limit: number) => {
    const res = await fetch(
      `${backendUrl}/api/connexion/${userID}?page=${currentPage}&limit=${limit}}`,
      { mode: "cors" }
    );
    const data = await res.json();
    return data;
  };
  useEffect(() => {
    fetchConn(currentPage, limit);
  }, [userID, limit, currentPage]);

  const handlePageClick = async (data: { selected: number }) => {
    const currentPage = data.selected + 1;

    const commentsFormServer = await fetchConn(currentPage, limit);

    setConnections(commentsFormServer);

    // Fetchconnexion(currentPage, limit);
  };

  // Function to handle the change in the select element
  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setLimit(newValue);
    const commentsFormServer = await fetchConn(currentPage, newValue);
    setConnections(commentsFormServer);
  };

  return (
    <>
      <h4 className="mb-3">
        <i
          className="las la-user-times"
          data-rel="bootstrap-tooltip"
          title="history"
        ></i>{" "}
        {translate("Historique des authentifications")}({total})
      </h4>

      <div>
        <div className="row m-2">
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                {translate("Show")}
                <select
                  name="DataTables_Table_0_length"
                  aria-controls="DataTables_Table_0"
                  className="custom-select custom-select-sm form-control form-control-sm mr-2 ml-2"
                  style={{ width: "66px" }}
                  onChange={handleSelectChange}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
                {translate("entries")}
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div
              id="DataTables_Table_0_filter"
              className="dataTables_filter mr-3"
            ></div>
          </div>
        </div>
      </div>
      <div className="row m-2">
        <Table>
          <thead className="bg-white text-uppercase">
            <tr className="ligth">
              {/* <th>
                <div className="checkbox d-inline-block">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    id="checkbox1"
                  />
                  <label htmlFor="checkbox1" className="mb-0"></label>
                </div>
              </th> */}
              <th>{translate("Utilisateur")}</th>
              <th>{translate("Nombre d'authentification")}</th>
              <th>{translate("Dernière connexion")}</th>
              <th>{translate("Durée")}</th>
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td colSpan={12}>
                <div className="text-center">
                  <PropagateLoader
                    color={"#123abc"}
                    loading={loading}
                    size={20}
                  />
                </div>
              </td>
            </tr>
          ) : (
            <tbody key="connexion" className="light-body">
              {Array.isArray(connection) &&
                connection.length !== 0 &&
                connection.map((item) => {
                  return (
                    <tr className={item["idlogauth"]} key={item["idlogauth"]}>
                      {/* <td>
                        <div className="checkbox d-inline-block">
                          <input
                            type="checkbox"
                            className="checkbox-input"
                            id={`checkbox-${item["idlogauth"]}`}
                          />
                          <label
                            htmlFor={`checkbox-${item["idlogauth"]}`}
                            className="mb-0"
                          ></label>
                        </div>
                      </td> */}
                      <td>
                        {item["nom_user"]} {item["prenom_user"]}
                      </td>
                      <td>{item["nbre_auth"]}</td>
                      <td>{new Date(item["last_auth"]).toLocaleString()}</td>
                      <td>{item["last_auth_duration"]}</td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </Table>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {connection.length} {translate("out of")}{" "}{total}
          </span>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
};

export default Connections;
