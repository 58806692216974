/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import SelectGroup from "../components/Vehicle/SelectGroup";
import { useTranslate } from "../hooks/LanguageProvider";
import { Link } from "react-router-dom";
import { Bounce, toast, ToastOptions } from 'react-toastify';
import { PropagateLoader } from "react-spinners";
import { logHistory, useClipboard } from '../utilities/functions'


interface User {
  nom_user: string,
  prenom_user: string,
  id_user: string,
}

interface Driver {
  nom_lastaffectationid: string,
  prenom_lastaffectationid: string,
  id_lastaffectationid: string,
}

interface tagsProps {
  id_tag: number,
  tag: string,
  id_user: string,
  lastaffectationid: string,
  tag_note: string,
  datecreationtag: string,
  lastAffectation: string,
  username_user: string
}

interface tagProps {
  id_tag: string,
  id_user: string,
  lastaffectationid: string,
  tag_note: string,
}

  const IButtons: React.FC = () => { 

  const [Ibutton, setIButton] = useState({
    id_tag: "",
    id_user: "",
    lastaffectationid: "",
    tag_note: "",
  });

  const [showModal, setModalIsOpen] = useState(false);
  const userID = localStorage.getItem("userID");
  let [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [list_tags, setTags] = useState<tagsProps[]>([]);
  let [limit, setLimit] = useState(10);
  const [page, setCurrentPage] = useState<number>(1);
  const [deletedTagCount, setDeletedTagCount] = useState<number>(0);
  const [userOptions, setUserOptions] = useState<{ value: string; label: string }[]>([]);
  const [driverOptions, setDriverOptions] = useState<{ value: string; label: string }[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedTagID, setSelectedTagID] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { translate } = useTranslate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSearch, setSelectedSearch] = useState(translate("ID Vehicle"));
  const [colum, setSortColum] = useState(0);
  const [sort, setSort] = useState("ASC");
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [typeSearch, setTypeSearch] = useState("ID Tag");


  const { copyToClipboard, copiedId } = useClipboard("Tag"); // Utiliser la fonction de copie

  const [selectedColumns, setSelectedColumns] = useState({
    id_tag: true,
    tag: true,
    id_user: true,
    lastaffectationid: true,
    tag_note: true,
    datecreationtag: true,
    lastAffectation: true,
    nom_user: true
  });


  

  const searchColum: { [key: string]: number } = {
    id_tag: 0,
    tag: 1,
    id_user: 2,
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Fonction Pour Récupéré Les Tags
  useEffect(() => {
    const fetchTagData = async () => {
      if (isEditMode) {
        try {
          const response = await fetch(`${backendUrl}/api/Tagform/${selectedTagID}`);

          if (!response.ok) {
            throw new Error(`Erreur lors de la récupération des données du Tag. Status: ${response.status}`);
          }
          const tagData = await response.json();

          setIButton({
            id_tag: tagData.id_tag,
            id_user: tagData.id_user,
            lastaffectationid: tagData.lastaffectationid,
            tag_note: tagData.tag_note,
          });
        } catch (error: any) {
          console.error("Erreur FATAL", error.message);
        }
      }
    };
    fetchTagData();
  }, [isEditMode, selectedTagID]);


  const getTags = async (
    limit: number,
    page: number,
    search: string,
    type: number,
    colum: number,
    sort: string
  ) => {


    const bodyData = JSON.stringify({
      limit: limit,
      page: page,
      search: search,
      type: type,
      id_user: userID,
      colum: searchColum[colum],
      sort: sort,
    });

    setLoading(true); 

    const total_pages = await fetch(`${backendUrl}/api/ibutton/totalpage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyData,
      mode: "cors",
    });

    const totalPages = await total_pages.json();
    setTotal(totalPages[0].total);
    total = totalPages[0]["total"];
    setPageCount(Math.ceil(total / limit));

    const res =await fetch(`${backendUrl}/api/ibutton/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyData,
      mode: "cors",
    });

    const data = await res.json();
    setTags(data);
    setLoading(false); 

  };

  useEffect(() => {
    getTags(limit, page, search, type, colum, sort);
  }, [userID, limit]);

  const fetchTag = async (page: number, limit: number) => {
    const res = await fetch(
      `${backendUrl}/api/ibutton/${userID}?page=${page}&limit=${limit}`,

      { mode: 'cors' }
    );
    const data = await res.json();
    return data;
  };


  const refreshTagData = async () => {
    getTags(limit, page,  search, type, colum, sort);
  };


  const handlePageClick = async (data: any) => {
    let page = data.selected + 1;
    const commentsFormServer = await fetchTag(page, limit);

    setTags(commentsFormServer);
    window.scrollTo(0, 0)

  };

  // Function to handle the change in the select element
  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setLimit(newValue);
    const commentsFormServer = await fetchTag(page, newValue);

    setTags(commentsFormServer);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    // Réinitialiser les données du formulaire à leurs valeurs par défaut
    setIButton({
      id_tag: "",
      id_user: "",
      lastaffectationid: "",
      tag_note: ""
    });
  };


  const fetchDeletedTagCount = async () => {
    try {

      const response = await fetch(
        `${backendUrl}/api/deletedcount/${userID}`,
        { mode: "cors" }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching deleted tag count. Status: ${response.status}`
        );
      }

      const data = await response.json();
      setDeletedTagCount(data.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDeletedTagCount();
  }, [userID]);


  useEffect(() => {
    const fetchAffecteAOptions = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/options/${userID}`);
        const data: User[] = await response.json();

        // Traiter les données pour les convertir en format d'options
        const processedOptions = data.map(user => ({
          value: user.id_user.toString(),
          label: `${user.nom_user} ${user.prenom_user}`
        }));

        // Mettre à jour l'état avec les options traitées
        setUserOptions(processedOptions);

      } catch (error) {
        console.error("Erreur lors de la récupération des options du sélecteur", error);
      }
    };

    fetchAffecteAOptions();
  }, [userID]);


  const handleChange = (selectedOption: any) => {
    if (selectedOption) {
      const selectedUserId = selectedOption.value;
      setSelectedUserId(selectedUserId);
      setIButton(prevData => ({
        ...prevData,
        id_user: selectedOption.value
      }));
    }
  };

  useEffect(() => {
    const fetchDriversForSelectedUser = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/optionsdrivers/${selectedUserId}`);
        const data = await response.json();
        if (Array.isArray(data)) {
          setDriverOptions(data.map((driver: Driver) => ({
            label: `${driver.nom_lastaffectationid} ${driver.prenom_lastaffectationid}`,
            value: driver.id_lastaffectationid,
          })));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des options du sélecteur de lastaffectationids", error);
      }
    };

    fetchDriversForSelectedUser();
  }, [selectedUserId]);


  const handleChangedriver = (selectedOption: any) => {
    if (selectedOption) {
      const selectedUserId = selectedOption.value;
      setIButton(prevData => ({
        ...prevData,
        lastaffectationid: selectedUserId
      }));
    }
  };

  const handleModalClose = () => {
    refreshTagData();
    setShowSuccessModal(false);
    closeModal();
  };


  const handleSubmit = async () => {
    // Validation des champs requis
    setErrors([]);

    // Validation des champs requis
    if (
      Ibutton.id_tag === "" ||
      Ibutton.lastaffectationid === "" ||
      Ibutton.id_user === ""
    ) {
      setErrors(["Veuillez remplir tous les champs obligatoires (*) "]);
      return;
    }

    try {
      const tagProps: tagProps = {
        id_tag: Ibutton.id_tag,
        id_user: Ibutton.id_user,
        lastaffectationid: Ibutton.lastaffectationid,
        tag_note: Ibutton.tag_note,
      };





      const response = await fetch(`${backendUrl}/api/addibutton`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tagProps),
      });
      if (response.ok) {
        toast.success("Tag create successfully !", {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        try {
          const details = `Add Tag: ${Ibutton.id_tag}`; // Détails pour le journal
          await logHistory(userID, "Add", "Tag", "Ajout", details);
        } catch (error) {
          console.error("Erreur lors de la journalisation de l'action id_user :", error);
        }
        refreshTagData();
        setModalIsOpen(false);
        return;
      }
    } catch (error) {
      toast.warn("Can't create Tag", {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };


  // Fonction pour mettre à jour le numéro de série dans Ibutton
  const handleid_tagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setIButton(prevData => ({
      ...prevData,
      id_tag: value
    }));
  };

  // Fonction pour mettre à jour la tag_note dans Ibutton
  const handletag_noteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setIButton(prevData => ({
      ...prevData,
      tag_note: value
    }));
  };

  const handleDeleteClick = async (TagID: string) => {
    // Affichez le modal de confirmation avant la suppression
    setSelectedTagID(TagID);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const loggedInUserID = localStorage.getItem("userID");
      const response = await fetch(`${backendUrl}/api/deletetag/${selectedTagID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ loggedInUserID: loggedInUserID }),
      });
      if (response.ok) {
        toast.success("Tag Deleted successfully !", {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        try {
          const details = `Delete Tag: ${Ibutton.id_tag}`; // Détails pour le journal
          await logHistory(userID, "Delete", "Tag", "Supprimer", details);
        } catch (error) {
          console.error("Erreur lors de la journalisation de l'action id_user :", error);
        }
        refreshTagData();
        setShowDeleteModal(false);
        return;
      }
    } catch (error) {
      toast.warn("Can't Deleted Driver", {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    refreshTagData();
  };

  const handleUpdateTag = async () => {
    try {
      const tagProps: tagProps = {
        id_tag: Ibutton.id_tag,
        id_user: Ibutton.id_user,
        lastaffectationid: Ibutton.lastaffectationid,
        tag_note: Ibutton.tag_note
      };

      const response = await fetch(`${backendUrl}/api/Tag/updateTag`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_tag_update: selectedTagID,
          ...tagProps,
        }),
      });
      if (response.ok) {
        toast.success("Tag updated successfully !", {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        try {
          const details = `update Tag: ${Ibutton.id_tag} `; // Détails pour le journal
          await logHistory(userID, "update", "Tag", "Modifie", details);
        } catch (error) {
          console.error("Erreur lors de la journalisation de l'action id_user :", error);
        }
        refreshTagData();
        setModalIsOpen(false);
        return;
      }
    } catch (error) {
      console.error("Erreur lors de l'appel de l'API pour la mise à jour", error);
    }
  };

  const openModal = (editMode = false) => {
    setIsEditMode(editMode);
    setModalIsOpen(true);
  };




  const handleCancel = () => {
    // Réinitialiser les données du formulaire à leurs valeurs par défaut
    setIButton({
      id_tag: "",
      id_user: "",
      lastaffectationid: "",
      tag_note: ""
    });
    // Effacer tous les messages d'erreur
    setErrors([]);
    // Fermer le modal
    setModalIsOpen(false);
  };



  const handleTypeSearch = (selectedValue: string) => {

    switch (selectedValue) {
      case translate("ID Tag"):
        setSelectedSearch(translate("ID Tag"))
        setType(0);
        break;
      case translate("TAG ibutton"):
        setSelectedSearch(translate("TAG ibutton"))
        setType(1);
        break;
      case translate("User"):
        setSelectedSearch(translate("User"))
        setType(2);
        break;
      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSearch(translate(selectedValue));
    console.log("Selected value:", selectedValue);
  }
    

    const menuItems = [
      translate("ID Tag"),
      translate("TAG ibutton"),
      translate("User"),
  ];


  
  const ColumnnOptions = [
    { key: "id_tag", label: 'ID '+translate("ID Tag") },
    { key: "tag", label: translate("TAG ibutton")  },
    { key: "id_user", label: translate("User")  },
];



type SelectedColumnnsType = {
  id_tag: boolean;
  tag: boolean;
  id_user: boolean;
};


    const handleAdvancedSearch = async (event: any) => {
      const newValue = event.target.value;
      setSearch(newValue);
      await getTags(limit, page, newValue, type, colum, sort);
    };


    const handleResetSearch = async () => {
      setSearch("")

      await getTags(limit, page, search, type, colum, sort)
  };


  const handleColumnnChange = (Columnn: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [Columnn]: !prevState[Columnn],
    }));
  };


    return (
      <>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h4>
              <i className="las la-user-tag" data-rel="bootstrap-tooltip" title="Increased"></i>{translate('Tag Management')} ({total})
            </h4>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="text-right">
              <button onClick={() => openModal(false)} className="btn btn-primary mt-2 mr-1">
                <i className="las la-plus mr-3"></i>
                {translate('New tag')}
              </button>
              <Link to="/History-tag" className="btn btn-outline-secondary mt-2 mr-1">
                <i className="las la-history mr-3"></i>
                {translate('History Assignments')}
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-3" style={{ margin: "0px 0px 10px 0px", padding: "10px" }}>
            <div className="input-group">
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" >
                                <i
                                    className="fas fa-chevron-down"
                                    style={{ fontSize: "20" }}
                                ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {menuItems.map((item, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => handleTypeSearch(item)}
                                        eventKey={item}
                                        active={typeSearch === item}
                                        className={typeSearch === item ? "select-active" : ""}
                                    >
                                        {item}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <input type="text" placeholder={` ${translate("Search by")} ${translate(typeSearch)}`} onChange={handleAdvancedSearch} className="form-control" />
                        <Button
                            variant="secondary"
                            onClick={handleResetSearch}
                            className="btn-reset"
                        >
                            <i className="las la-times" style={{ color: "#fff" }}></i>
                        </Button>
                    </div>
            </div>
            <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="dataTables_length">
                        <label style={{ marginBottom: "0" }}>
                            {translate("Show")}
                            <select
                                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                                style={{ width: "66px" }}
                                onChange={handleSelectChange}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </label>
                    </div>
              <Dropdown>
                        <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            title={translate("Display Columnns")}
                        >
                            <i className="las la-eye"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {ColumnnOptions.map((Columnn) => (
                                <Dropdown.Item
                                    as="button"
                                    style={{ display: "flex", alignItems: "center" }}
                                    key={Columnn.key}
                                >
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={selectedColumns[Columnn.key as keyof SelectedColumnnsType]}
                                        onChange={() => handleColumnnChange(Columnn.key as keyof SelectedColumnnsType)}
                                    />
                                    <span style={{ marginLeft: "10px" }}>
                                        {translate(Columnn.label)}
                                    </span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
            </div>
          </div>
          <div className="row m-2">
            <Table>
              <thead className="bg-white text-uppercase">
                <tr className="ligth ligth-data">
                  <th>
                    <div className="checkbox d-inline-block">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkbox1"
                      />
                      <label htmlFor="checkbox1" className="mb-0"></label>
                    </div>
                  </th>
                  <th>Id Tag</th>
                  <th>TAG ibutton</th>
                  <th>{translate('Last Assignment')}</th>
                  <th>{translate('tag_note')}</th>
                  <th>{translate('User')}</th>
                  <th>{translate('Action')}</th>
                </tr>
              </thead>

              <tbody key="Ibutton" className="ligth-body">
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      <p>
                        <PropagateLoader
                          color={"#123abc"}
                          loading={loading}
                          size={20}
                        /></p>
                    </td>
                  </tr>
                ) : list_tags.length > 0 ? (
                  list_tags.map((tag) => (
                    <tr key={tag.id_tag}>
                      <td>
                        <div className="checkbox d-inline-block">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox2"
                          />
                          <label htmlFor="checkbox2" className="mb-0"></label>
                        </div>
                      </td>
                      <td>{tag.id_tag}</td>
                      <td
                        id={`tag-${tag.id_tag}`}
                        onClick={() => copyToClipboard(tag.tag, tag.id_tag.toString())}
                        title="Cliquez pour copier le tag"
                        style={{ cursor: 'pointer', position: 'relative', color: copiedId === tag.id_tag.toString() ? '#28a745' : '#007bff' }}
                      >
                        {tag.id_tag && (
                          <>
                            <i className="las la-tags" style={{ marginRight: '4px' }}></i>
                            <span style={{ color: copiedId === tag.id_tag.toString() ? '#28a745' : '#007bff' }}>{tag.tag}</span>

                            {/* Afficher le feedback "Copié!" si ce tag a été copié */}
                            {copiedId === tag.tag && (
                              <span
                                style={{
                                  position: 'absolute',
                                  top: '-20px',
                                  left: '30%',
                                  transform: 'translateX(-50%)',
                                  backgroundColor: '#28a745',
                                  color: '#fff',
                                  padding: '2px 5px',
                                  borderRadius: '4px',
                                  fontSize: '12px',
                                }}
                              >
                                Copié
                              </span>
                            )}
                          </>
                        )}
                      </td>

                      <td>{new Date(tag.lastAffectation).toLocaleString()}</td>
                      <td>{tag.tag_note}</td>
                      <td> {tag.username_user}</td>
                      <td>
                        <div className="d-flex align-items-center list-action">
                          <a className="badge badge-success mr-2"
                            onClick={() => {
                              setSelectedTagID(tag.id_tag.toString());
                              openModal(true);
                            }}
                          >
                            <i className="ri-pencil-line mr-0" style={{ fontSize: '1.2em' }}></i>
                          </a>
                          <a className="badge bg-warning mr-2"
                            onClick={() => handleDeleteClick(tag.id_tag.toString())}

                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                            data-original-title="Delete"
                          >
                            <i className="ri-delete-bin-line mr-0" style={{ fontSize: '1.2em' }}></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No tag available</td>
                  </tr>
                )}
              </tbody>

              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                dialogClassName="modal-90w"
                aria-labelledby=""
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontWeight: 'bold', color: 'grey' }}>{translate('Deleted tag')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  {translate('Do you really want to remove this TAG ?')}
                </Modal.Body>
                <Modal.Footer className="d-flex">
                  <button className="btn btn-outline-danger mt-2 mx-auto" onClick={() => setShowDeleteModal(false)}>
                    {translate('Cancel')}
                  </button>
                  <button className="btn btn-outline-success mt-2 mx-auto" onClick={handleConfirmDelete}>
                    {translate('Confirm')}
                  </button>
                </Modal.Footer>
              </Modal>
            </Table>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <span>
                {translate("Displaying")} {list_tags.length} {translate("out of")}{" "}{total}
              </span>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <ReactPaginate
               previousLabel={translate("previous")}
               nextLabel={translate("next")}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination justify-content-end"}
               pageClassName={"page-item"}
               pageLinkClassName={"page-link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active"}
               forcePage={page - 1}
              />
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={closeModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? 'Modifier le TAG' : 'Ajouter un nouveau TAG'}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="form-group row">
              <label htmlFor="id_tag" className="col-sm-4 col-form-label" style={{ textAlign: 'left' }}>  {translate('Serial number')}*</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="id_tag"
                  onChange={handleid_tagChange}
                  value={Ibutton.id_tag}
                  placeholder="TAG Number"
                />
              </div>
              <div className="help-block with-errors"></div>
            </div>


            <SelectGroup
              controlId="id_user"
              label="id_user*"
              icon="user"
              options={userOptions}
              valueType={{
                value: Ibutton.id_user,
                label: userOptions.find(user => user.value === Ibutton.id_user)?.label || "Sélectionnez un id_user"
              }}
              onChange={handleChange}
            />
            <div className="help-block with-errors"></div>

            <div className="form-group  mb-3">
              <SelectGroup
                controlId="lastaffectationid"
                label="lastaffectationid*"
                icon="user"
                options={driverOptions}
                valueType={{
                  value: Ibutton.lastaffectationid,
                  label: driverOptions.find(driver => driver.value === Ibutton.lastaffectationid)?.label || "Sélectionnez un lastaffectationid "
                }}
                onChange={handleChangedriver}
              />
              <div className="help-block with-errors"></div>
            </div>

            <div className="form-group row">
              <label htmlFor="tag_note" className="col-sm-4 col-form-label" style={{ textAlign: 'left' }}> {translate('tag_note')}</label>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  id="tag_note"
                  onChange={handletag_noteChange}
                  value={Ibutton.tag_note}
                  placeholder="tag_note"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant=" btn btn-outline-secondary" onClick={handleCancel}>
              <i className="las la-times "></i>{translate('Cancel')}
            </Button>
            <Button variant="btn btn-outline-success" onClick={isEditMode ? handleUpdateTag : handleSubmit}>
              <i className={`fa ${isEditMode ? "fa-edit" : "las la-plus"}`} />
              {isEditMode ? 'Mettre à jour' : 'Ajouter'}
            </Button>
            <div className="col-sm-10">
              {errors.map((error, index) => (
                <p
                  key={index}
                  style={{ color: "red", fontSize: "1.2em", textAlign: "center" }}
                >
                  {error}
                </p>
              ))}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showSuccessModal}
          onHide={handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditMode
                ? "TAG modifié avec succès"
                : "TAG ajouté avec succès"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isEditMode
              ? "Vous avez modifié le TAG avec succès !"
              : "Vous avez ajouté un nouvel TAG avec succès !"}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleModalClose}>
              {translate('Close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
};

export default IButtons;