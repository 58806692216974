import React, { useState, ChangeEvent, FC, useEffect } from 'react';

interface AbsenceOfTransmissionProps {
  alarm: any; // Adapter le type en fonction de votre structure d'alarme
  onChange: (newMetrics: any) => void; // Fonction de rappel pour mettre à jour l'état du composant parent
}

const AbsenceOfTransmission: FC<AbsenceOfTransmissionProps> = ({ alarm, onChange }) => {
  const [absence, setAbsence] = useState<string>("48");

  useEffect(() => {  
    console.log("alarm", alarm);
    if (alarm !== "") {  
      setAbsence(alarm.hours);
    } else {
      setAbsence("48");
    }
  }, [alarm]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    checkText(event.target); // Appel de la fonction checkText 
    setAbsence(value);
    onChange({ ...alarm, hours: value });

    onChange({ type:"AbsenceOfTransmission", hours: value });   
  };

  const checkText = (input: HTMLInputElement) => {
    // Logique de la fonction checkText
    console.log('Text checked:', input.value);
  };
 
  return (
    <div className="input-group col-md-4 col-sm-4">
      <input
        className="form-control input-sm"
        name="no_transmision"
        value={absence}
        placeholder='Absence de transmission'
        type="text"
        size={10}
        maxLength={20}
        onChange={handleInputChange}
      />
      <span style={{paddingLeft:10}} className="input-group-addon">h</span>
    </div>
  );
};

export default AbsenceOfTransmission;
