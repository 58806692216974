import { useState, useEffect } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../hooks/LanguageProvider";
import CreateTicketModal from "../components/Ticket/Modals/CreateTicket";
import { formatToTimestamp } from "../utilities/functions";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast, Bounce } from "react-toastify";

interface TicketProps {
  id_ticket: number;
  description: string;
  title: string;
  id_user: number;
  nom_user: string;
  creation_date: string;
  priority: number;
  status: number;
  criticality: number;
  id_category: number;
  id_subcategory: number;
  category_name: string;
  subcategory_name: string;
  first_name: string;
  last_name: string;
}

interface SelectedColumns {
  id_ticket: boolean;
  description: boolean;
  category: boolean;
  subcategory: boolean;
  title: boolean;
  id_user: boolean;
  creation_date: boolean;
  priority: boolean;
  status: boolean;
  criticality: boolean;
}

interface ColumnItem {
  key: keyof SelectedColumns;
  label: string;
}

export function Ticket() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { translate } = useTranslate();
  const id_user = localStorage.getItem("userID");
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [list_Ticket, setTickets] = useState<TicketProps[]>([]);
  const [colum, setSortColum] = useState("id_ticket");
  const [sort, setSort] = useState("DESC");
  const [search, setSearch] = useState(""); 
  const [type, setType] = useState(0);
  const [typeSearch, setTypeSearch] = useState(translate("Ticket") + " ID");
  const [loading, setLoading] = useState(true);

  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const handleShowCreateTicketModal = () => setShowCreateTicketModal(true);
  const handleCloseCreateTicketModal = () => setShowCreateTicketModal(false);
  const [idTicket, setIdTicket,] = useState(0);

  const handleCreateTicket = async (id_alarme: number, IdUser: string) => {
    try {
      console.log(id_alarme);
      setIdTicket(id_alarme);
    } catch (error) {
      console.error(error);
    }
  };

  const getTicket = async (
    limit: number,
    page: number,
    search: string,
    type: number,
    colum: string,
    sort: string
  ) => {
    try {
      setLoading(true);

      // Preparing the data to send
      const bodyData = JSON.stringify({
        limit,
        page,
        search,
        type,
        id_user:parseInt(id_user || "0"),
        colum: Columns[colum],
        sort,
      });

      // Retrieve the total number of pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/ticket/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      setTotal(total);

      // Retrieving alarm data
      const ticketsResponse = await fetch(
        `${backendUrl}/api/ticket/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const data = await ticketsResponse.json();
      setPageCount(Math.ceil(total / limit));
      setLimit(limit);
      setTickets(data);
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const refreshTickets = () => {
    getTicket(limit, page, search, type, colum, sort);
  };

  const handlePageClick = async (data: any) => {
    let page = data.selected + 1;
    const commentsFormServer = await getTicket(limit, page, search, type, colum, sort);
    setTickets(commentsFormServer);
    window.scrollTo(0, 0);
  };

  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setPage(1);
    setLimit(newValue);
    const commentsFormServer = await getTicket(
      parseInt(newValue),
      1,
      search,
      type,
      colum,
      sort
    );
    setTickets(commentsFormServer);
    window.scrollTo(0, 0);
  };

  const handleSortingColum = (currentColumn: string) => {
    setSortColum(currentColumn);
    sort === "ASC" ? setSort("DESC") : setSort("ASC");
    setPage(1)
    getTicket(limit, 1, search, type, colum, sort);
  };


  useEffect(() => {
    getTicket(limit, page, search, type, colum, sort);
  }, []);

  const [selectedColumns, setSelectedColumns] = useState({
    id_ticket: true,
    id_agent: true,
    description: false,
    category: true,
    subcategory: true,
    title: true,
    id_user: true,
    creation_date: true,
    priority: true,
    status: true,
    criticality: true,
  });

  const Columns: { [key: string]: number } = {
    "id_ticket": 0,
    "agent": 1,
    "description": 2,
    "id_category": 3,
    "id_subcategory": 4,
    "title": 5,
    "id_user": 6,
    "creation_date": 7,
    "priority": 8,
    "status": 9,
    "criticality": 10,
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleTypeSearch = (selectedValue: string) => {

    switch (selectedValue) {
      case translate("Ticket") + " ID":
        setType(0);
        break;
      case translate("Agent"):
        setType(1);
        break;
      case translate("Description"):
        setType(2);
        break;
      case translate("Category"):
        setType(3);
        break;
      case translate("Sub category"):
        setType(4);
        break;

      case translate("Titre"):
        setType(5);
        break;

      case translate("User"):
        setType(6);
        break;

      case translate("Status"):
        setType(7);
        break;

      case translate("Priority"):
        setType(8);
        break;

      case translate("Criticality"):
        setType(9);
        break;

      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSearch(selectedValue);
    console.log("Selected value:", selectedValue);
  };


  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    await getTicket(limit, page, newValue, type, colum, sort);
  };

  const handleResetSearch = async () => {
    setSearch("")

    await getTicket(limit, page, search, type, colum, sort)
  };

  const menuItems = [
    translate("Ticket ID"),
    translate("Agent"),
    translate("Description"),
    translate("Category"),
    translate("Sub category"),
    translate("Titre"),
    translate("Status"),
    translate("Priority"),
    translate("Criticality")
  ];


  const displayColumns: ColumnItem[] = [
    { key: "id_ticket", label: translate("Ticket") + " ID" },
    { key: "title", label: translate("Title") },
    { key: "description", label: translate("Description") },
    { key: "id_user", label: translate("User") },
    { key: "category", label: translate("Category") },
    { key: "subcategory", label: translate("Sub category") },
    { key: "creation_date", label: translate("Creation date") },
    { key: "status", label: translate("Status") },
    { key: "priority", label: translate("Priority") },
    { key: "criticality", label: translate("Criticality") },
  ];



  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h4>
            <i
              className="las la-ticket-alt"
              data-rel="bootstrap-tooltip"
              title="Ticket"
            ></i>{" "}
            {translate("Tickets")} ({total})
          </h4>
        </div>
        <div className="col-md-6 col-sm-12 text-right">
          <Button
            variant="primary"
            className="mt-2 mr-1"
            onClick={handleShowCreateTicketModal}
          >
            <i className="las la-plus mr-3"></i>{translate("Create Ticket")}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4" style={{ margin: "0px 0px 10px 0px", padding: "10px" }} >
          <div className="input-group">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic" >
                <i
                  className="fas fa-chevron-down"
                  style={{ fontSize: "20" }}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {menuItems.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleTypeSearch(item)}
                    eventKey={item}
                    active={typeSearch === item}
                    className={typeSearch === item ? "select-active" : ""}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <input type="text" placeholder={` ${translate("Search by")} ${translate(typeSearch)}`} onChange={handleAdvancedSearch} className="form-control" />
            <Button
              variant="secondary"
              onClick={handleResetSearch}
              className="btn-reset"
            >
              <i className="las la-times" style={{ color: "#fff" }}></i>
            </Button>
          </div>
        </div>
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label style={{ marginBottom: "0" }}>
              {translate("Show")}
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                style={{ width: "66px" }}
                onChange={handleSelectChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </label>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic" title={translate("Display columns")}>
              <i className="las la-list-ol"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {displayColumns.map((column) => (
                <Dropdown.Item
                  key={column.key}
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns[column.key]}
                    onChange={() => handleColumnChange(column.key)}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate(column.label)}
                  </span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <i onClick={handleShow} style={{ fontSize: "20" }} className="las la-filter"> </i>

        </div>
      </div>
      <div className="table-responsive">
        <Table className="dataTable">
          <thead className=" text-uppercase">
            <tr className="ligth ligth-data">
              <th>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label"></label>
                </div>
              </th>
              {selectedColumns.id_ticket && (<th className="sorting" onClick={() => handleSortingColum("id_ticket")} >{"N° " + translate("Ticket")}</th>)}
              {selectedColumns.title && <th className="sorting" onClick={() => handleSortingColum("title")} >{translate("Title")}</th>}
              {selectedColumns.description && <th className="sorting" onClick={() => handleSortingColum("Description")} >{translate("Description")}</th>}
              {selectedColumns.id_agent && <th className="sorting" onClick={() => handleSortingColum("agent")} >{translate("Agent")}</th>}
              {selectedColumns.category && <th className="sorting" onClick={() => handleSortingColum("category")} >{translate("Category")}</th>}
              {selectedColumns.subcategory && (<th className="sorting" onClick={() => handleSortingColum("subcategory")} >{translate("Sub category")}</th>)}
              {selectedColumns.creation_date && (<th className="sorting" onClick={() => handleSortingColum("creation_date")} >{translate("Creation Date")}</th>)}
              {selectedColumns.id_user && <th className="sorting" onClick={() => handleSortingColum("User")} >{translate("User")}</th>}
              {selectedColumns.status && <th className="sorting" onClick={() => handleSortingColum("status")} >{translate("Status")}</th>}
              {selectedColumns.criticality && (<th className="sorting" onClick={() => handleSortingColum("criticality")}>{translate("Criticality")}</th>)}
              {selectedColumns.priority && (<th className="sorting" onClick={() => handleSortingColum("priority")}>{translate("Priority")}</th>)}

              {<th>{translate("Actions")}</th>}
            </tr>
          </thead>
          <tbody key="#" className="ligth-body">
            {loading ? (
              <tr>
                <td className="text-center" colSpan={11}>
                  <p>
                    <PropagateLoader
                      color={"#123abc"}
                      loading={loading}
                      size={20}
                    /></p>
                </td>
              </tr>
            ) : list_Ticket.length > 0 ? (
              list_Ticket.map((ticket) => (
                <tr key={ticket.id_ticket}>
                  <td>
                    <div className="form-check form-check-inline">
                      <input type="checkbox" className="form-check-input" />
                    </div>
                  </td>
                  {selectedColumns.id_ticket && <td>{ticket.id_ticket}</td>}
                  {selectedColumns.title && <td>{ticket.title}</td>}
                  {selectedColumns.description && <td>{ticket.description}</td>}
                  {selectedColumns.id_agent && <td>{ticket.first_name !== null ? ticket.first_name + " " + ticket.last_name : translate("None")}</td>}
                  {selectedColumns.category && <td>{translate(ticket.category_name)}</td>}
                  {selectedColumns.subcategory && <td>{translate(ticket.subcategory_name)}</td>}
                  {selectedColumns.creation_date && (<td>{formatToTimestamp(ticket.creation_date)}</td>)}
                  {selectedColumns.id_user && <td>{ticket.nom_user}</td>}
                  {selectedColumns.status && <td>{translate(ticket.status.toString())}</td>}
                  {selectedColumns.criticality && <td>{translate(ticket.criticality.toString())}</td>}
                  {selectedColumns.priority && <td>{translate(ticket.priority.toString())}</td>}
                  <td>
                    <div className="d-flex align-items-center list-action">
                      <Link
                        to={`/chat/${ticket.id_ticket}/${ticket.id_user}`}
                        className="badge badge-success mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={translate("Detail")}
                      >
                        <i className="las la-comment-dots" style={{ fontSize: "1.2em" }} ></i>
                      </Link>
                      <Link
                        to="#"
                        className="badge bg-warning mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={translate("Update")}
                        data-original-title="Delete"
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="las la-edit mr-0" style={{ fontSize: '1.2em' }}></i>
                      </Link>
                      <Link
                        to="#"
                        className="badge bg-primary mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={translate("Download")}
                        data-original-title="Download"
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="las la-download" style={{ fontSize: '1.2em' }}></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11}>No ticket available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {list_Ticket.length} {translate("out")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
      <CreateTicketModal
        show={showCreateTicketModal}
        onHide={handleCloseCreateTicketModal}
        refreshTickets={refreshTickets}
        id_ticket={idTicket}
      />
    </>
  );
}
