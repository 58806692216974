/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal, Nav, Table } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../hooks/LanguageProvider";
import { DownloadModal, formatToTimestamp, generateExcelFile, generatePDFFile, handleDownloadConfirm, toTimestamp } from "../utilities/functions";
import { PropagateLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import GeoFenceUploadModal from "../components/TMS/GeoFenceUploadModal";
import DeleteTMSModal from "../components/TMS/TMSModal";

interface Trip {
  id_trip: number;               // Identifiant du trajet
  id_user: number;               // Identifiant de l'utilisateur associé au trajet
  PSN: string;                   // Identifiant unique ou numéro de sécurité pour le trajet
  lat_start: number;             // Latitude de départ
  lon_start: number;             // Longitude de départ
  lat_end: number;               // Latitude d'arrivée
  lon_end: number;               // Longitude d'arrivée
  start_time: string;            // Heure de début du trajet
  end_time: string;              // Heure de fin (peut être NULL si le trajet est en cours)
  weight: number;                // Poids transporté en kg
  status: string;                // Types possibles pour le statut du trajet
}

export function TMSs() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { translate } = useTranslate();
  let [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list_Trip, setTrip] = useState<Trip[]>([]);
  const id_user = localStorage.getItem("userID");
  const [loading, setLoading] = useState(true); // Add loading state
  const [pageCount, setPageCount] = useState(0);
  let [total, setTotal] = useState(0);
  const [colum, setSortColum] = useState("id_trip");
  const [sort, setSort] = useState("ASC");
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [typeSearch, setTypeSearch] = useState("ID Trip");
  const [selectedSearch, setSelectedSearch] = useState(translate("ID Trip"));
  const [selectedTMSs, setSelectedTMSs] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showCreateTMSModal, setShowCreateTMSModal] = useState(false);
  const handleShowCreateTMSModal = () => setShowCreateTMSModal(true);
  const handleCloseCreateTMSModal = () => setShowCreateTMSModal(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [modalStatus, setModalStatus] = useState<string | null>(null);
  const [titleStatus, setTitleStatus] = useState<string | null>(null);
  const [IdUser, setIdUser] = useState<number>(0);
  const [IdTMS, setIdTMS] = useState<number>(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    // Votre logique de soumission ici
  };


  function generateExcelFileModel() {
    // Définir les données de l'en-tête et les lignes du modèle
    const data = [
      [ "id_trip",              // Identifiant du trajet
        "id_user",             // Identifiant de l'utilisateur associé au trajet
        "PSN",                  // Identifiant unique ou numéro de sécurité pour le trajet
        "lat_start",            // Latitude de départ
        "lon_start",           // Longitude de départ
        "lat_end",              // Latitude d'arrivée
        "lon_end",              // Longitude d'arrivée
        "start_time",          // Heure de début du trajet
        "end_time",             // Heure de fin (peut être NULL si le trajet est en cours)
        "weight",                // Poids transporté en kg
        "status"],
    ];

    // Créer une nouvelle feuille de calcul (worksheet)
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Créer un nouveau classeur (workbook)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "GeoFenceModel");

    // Générer le fichier Excel (XLSX) et le déclencher en téléchargement
    XLSX.writeFile(workbook, "GeoFence_Model.xlsx");
  }

  const getTrip = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sort: string
  ) => {
    try {
      setLoading(true);

      // Préparation des données à envoyer
      const bodyData = JSON.stringify({
        limitValue,
        currentPage,
        search,
        type,
        id_user,
        colum,
        sort,
      });

      // Récupération du nombre total de pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/tms/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      setTotal(total);

      // Retrieving alarm data
      const TMSResponse = await fetch(`${backendUrl}/api/tms/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      if (!TMSResponse.ok) {
        console.error("Error retrieving TMS listI");
        setLoading(false);
        return;
      }


      const data = await TMSResponse.json();
      setPageCount(Math.ceil(total / limitValue));
      setLimit(limitValue);
      setTrip(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const refreshTMS = () => {
    getTrip(limit, currentPage, search, type, colum, sort);
  };


  const getTriplimitValue = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sort: string
  ) => {
    try {
      setLoading(true);

      // Preparing the data to send
      const bodyData = JSON.stringify({
        limitValue,
        currentPage,
        search,
        type,
        id_user,
        colum,
        sort,
      });

      // Retrieve the total number of pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/tms/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      setTotal(total);

      // Retrieving alarm data
      const TMSResponse = await fetch(`${backendUrl}/api/tms/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const data = await TMSResponse.json();
      setPageCount(Math.ceil(total / limitValue));
      setLimit(limitValue);

      return data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTrip(
      limit,
      currentPage,
      search,
      type,
      colum,
      sort
    );
    // setTrip(commentsFormServer);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getTrip(limit, currentPage, search, type, colum, sort);
  }, []);

  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setCurrentPage(1); // Réinitialiser currentPage à 1 lorsque la limite change
    setLimit(newValue);
    const commentsFormServer = await getTriplimitValue(
      parseInt(newValue),
      1,
      search,
      type,
      colum,
      sort
    ); // Ajouter await ici
    setTrip(commentsFormServer);
    window.scrollTo(0, 0);
  };

  const [selectedColumns, setSelectedColumns] = useState({
    id_trip: true,            // Identifiant du trajet
    id_user: true,              // Identifiant de l'utilisateur associé au trajet
    PSN: true,                // Identifiant unique ou numéro de sécurité pour le trajet
    address_start: true,          // Latitude de départ          // Longitude de départ
    address_end: true,             // Latitude d'arrivée               // Longitude d'arrivée
    start_time: true,              // Heure de début du trajet
    end_time: true,         // Heure de fin (peut être NULL si le trajet est en cours)
    weight: true,                  // Poids transporté en kg
    status: true,
  });

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleTypeSearch = (event: any) => {
    const selectedValue = event.target.textContent;


    switch (selectedValue) {
      case translate("ID Trip"):
        setSelectedSearch(translate("ID Trip"))
        setType(0);
        break;
      case translate("PSN"):
        setSelectedSearch(translate("PSN"))
        setType(1);
        break;
      case translate("Start time"):
        setSelectedSearch(translate("Start time"))
        setType(2);
        break;
      case translate("End time"):
        setSelectedSearch(translate("End time"))
        setType(3);
        break;
      case translate("Weight"):
        setSelectedSearch(translate("Weight"))
        setType(4);
        break;
      case translate("Status"):
        setSelectedSearch(translate("Status"))
        setType(5);
        break;
      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSearch(selectedValue);
    console.log("Selected value:", selectedValue);
  };

  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    await getTrip(limit, currentPage, newValue, type, colum, sort);
  };

  const handleSortingColum = (curentColum: string) => {
    setSortColum(curentColum);
    sort == "ASC" ? setSort("DESC") : setSort("ASC");
    setCurrentPage(1)
    getTrip(limit, 1, search, type, colum, sort);
  };

  const options = [
    "ID Trip",                
    "PSN",                     
    "Start date",           
    "End date",         
    "Weight",               
    "Status"
  ];

  const TMSHeaders = [
    translate("ID Trip"),
    translate("PSN"),
    translate("Start date"),
    translate("End date"),
    translate("Weight"),
    translate("Status")
  ];


  const downloadVehicleExcel = () => {

    const selectedData = list_Trip.filter((TMS) =>
      selectedTMSs.includes(TMS.id_trip.toString())
    ).map((TMS) => [
      TMS.id_trip,            // Identifiant du trajet
      TMS.id_user,              // Identifiant de l'utilisateur associé au trajet
      TMS.PSN,                // Identifiant unique ou numéro de sécurité pour le trajet
      TMS.lat_start,           // Latitude de départ
      TMS.lon_start,            // Longitude de départ
      TMS.lat_end,              // Latitude d'arrivée
      TMS.lon_end,              // Longitude d'arrivée
      toTimestamp(TMS.start_time),           // Heure de début du trajet
      toTimestamp(TMS.end_time),      // Heure de fin (peut être NULL si le trajet est en cours)
      TMS.weight,                // Poids transporté en kg
      TMS.status,
    ]);


    generateExcelFile(translate("List") + ' ' + translate("TMSs"), TMSHeaders, selectedData);
  };

  const downloadVehiclePDF = () => {


    const selectedData = list_Trip.filter((TMS) =>
      selectedTMSs.includes(TMS.id_trip.toString())
    ).map((TMS) => [
      TMS.id_trip,            // Identifiant du trajet
      TMS.id_user,              // Identifiant de l'utilisateur associé au trajet
      TMS.PSN,                // Identifiant unique ou numéro de sécurité pour le trajet
      TMS.lat_start,           // Latitude de départ
      TMS.lon_start,            // Longitude de départ
      TMS.lat_end,              // Latitude d'arrivée
      TMS.lon_end,              // Longitude d'arrivée
      toTimestamp(TMS.start_time),           // Heure de début du trajet
      toTimestamp(TMS.end_time),      // Heure de fin (peut être NULL si le trajet est en cours)
      TMS.weight,                // Poids transporté en kg
      TMS.status,
    ]);

    generatePDFFile(translate("List") + ' ' + translate("TMSs"), TMSHeaders, selectedData);
  };

  const onDownloadConfirm = (format: string) => {
    if (selectedTMSs.length > 0) {
      handleDownloadConfirm(format, downloadVehicleExcel, downloadVehiclePDF);
    } else {
      toast.warn("Please select at least one TMS", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const [isTMSsSelected, setIsTMSsSelected] = useState(false);

  // Dans la fonction handleDriverSelect
  const handleTMSsSelect = (TMSID: string) => {
    let updatedSelectedTMSs: string[] = [];

    console.log(TMSID);

    // Gère la sélection ou désélection en fonction de selectAll
    if (selectAll) {
      updatedSelectedTMSs = selectedTMSs.includes(TMSID)
        ? selectedTMSs.filter(id => id !== TMSID) // If already selected, deselect
        : list_Trip.map(TMS => TMS.id_trip.toString()); // Select all TMS
    } else {
      // Manages the selection/selection of an individual TMS
      if (selectedTMSs.includes(TMSID)) {
        updatedSelectedTMSs = selectedTMSs.filter(id => id !== TMSID);
      } else {
        updatedSelectedTMSs = [...selectedTMSs, TMSID];
      }
    }

    // Updates the list of selected TMS
    setSelectedTMSs(updatedSelectedTMSs);

    // Updates the TMS state
    setIsTMSsSelected(updatedSelectedTMSs.length > 0);
    console.log(updatedSelectedTMSs);
  };

  // Si vous souhaitez désélectionner la case à cocher globale lorsque toutes les cases individuelles sont désélectionnées.
  useEffect(() => {
    // Si la liste des TMSs sélectionnés est vide, désactivez la sélection générale
    if (selectedTMSs.length === 0 && selectAll) {
      setSelectAll(false);
    }
  }, [selectedTMSs, selectAll]);

  // Fonction pour gérer la sélection/désélection de tous les TMSs
  const handleSelectAllTMSs = (checked: boolean) => {
    setSelectAll(checked);

    if (checked) {
      // Sélectionner tous les TMSs
      const allTMSIDs = list_Trip.map((TMS) => TMS.id_trip.toString()); // Convertir en chaînes de caractères
      setSelectedTMSs(allTMSIDs);
      setIsTMSsSelected(true); // Marquer comme sélectionné
    } else {
      // Désélectionner tous les TMSs
      setSelectedTMSs([]);
      setIsTMSsSelected(false); // Marquer comme non sélectionné
    }
  };


  const handleDeleteTMS = async (id_vehicule: string, IdUser: number) => {
    try {
      console.log(id_vehicule);
      setModalStatus('Do you want to delete this geo-fence');
      setTitleStatus('Delete geo-fence');
      setIdUser(IdUser);
      setIdTMS(parseInt(id_vehicule));

    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setModalStatus(null);
  };



  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h4>
            <i className="las la-road"></i>
            {translate("Trips")} ({total})
          </h4>
        </div>
        <div className="col-md-6 col-sm-12 text-right">

          <NavLink to="/TMS/add" className="btn btn-primary  mr-1">
            <i className="las la-plus fs-5"></i>
            {translate('New Trip')}
          </NavLink >

          <Button
            variant=""
            className="mr-1 btn btn-outline-secondary"
            onClick={() => setShowDownloadModal(true)}
          >
            <i className="las la-download fs-5"></i>  {translate("Export")}
          </Button>

          <Button
            variant=""
            className="mr-1 btn btn-outline-secondary"
            onClick={handleShowCreateTMSModal}
          >
            <i className="las la-upload fs-5"></i>{translate("Upload Trip")}
          </Button>

        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4"
          style={{ margin: "0px 0px 10px 0px", padding: "10px" }}
        >
          <div className="input-group">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i
                  className="fas fa-chevron-down"
                  style={{ fontSize: "20" }}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu onClick={handleTypeSearch}>
                {options.map(option => (
                  <Dropdown.Item
                    key={option}
                    className={`${selectedSearch == translate(option) ? 'active' : ''}`}
                    onClick={() => setSelectedSearch(translate(option))}
                  >
                    {translate(option)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <input
              type="text"
              placeholder={` By ${typeSearch}`}
              onChange={handleAdvancedSearch}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <Button
            variant=""
            className="mr-1 border-0 btn btn-outline-secondary"
            onClick={generateExcelFileModel}
            title={translate("Please fill in the mandatory attributes: geo-fence code, name, description, geo-fence format")}
          >
            <i className="las la-file-excel fs-4"></i>{translate("Model of excel Trip")}
          </Button>
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label style={{ marginBottom: "0" }}>
              {translate("Show")}
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                style={{ width: "66px" }}
                onChange={handleSelectChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </label>
          </div>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              title="Colonnes dʼaffichage"
            >
              <i className="las la-eye"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.id_trip}
                  onChange={() => handleColumnChange("id_trip")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("ID Trip")}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.PSN}
                  onChange={() => handleColumnChange("PSN")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("PSN")}</span>
              </Dropdown.Item>
              {/* <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.address_start}
                  onChange={() => handleColumnChange("address_start")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Address start")}</span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.address_end}
                  onChange={() => handleColumnChange("address_end")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Address end")}</span>
              </Dropdown.Item> */}


              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.start_time}
                  onChange={() => handleColumnChange("start_time")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Date start")}</span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.end_time}
                  onChange={() => handleColumnChange("end_time")}
                />

                <span style={{ marginLeft: "10px" }}>
                  {translate("Date end")}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.weight}
                  onChange={() => handleColumnChange("weight")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Weight")}
                </span>
              </Dropdown.Item>


              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.status}
                  onChange={() => handleColumnChange("status")}
                />

                <span style={{ marginLeft: "10px" }}>
                  {translate("Status")}
                </span>
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row m-1">
        <div className="table-responsive">
          <Table className="dataTable">
            <thead className="bg-white text-uppercase">
              <tr className="ligth ligth-data">
                <th>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      checked={selectAll}
                      onChange={(e) => handleSelectAllTMSs(e.target.checked)}
                      type="checkbox" />
                    <label className="form-check-label"></label>
                  </div>
                </th>
                {selectedColumns.id_trip && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("id_trip")}
                  >
                    {translate("ID Trip")}
                  </th>
                )}
                {selectedColumns.PSN && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("PSN")}
                  >
                    {translate("PSN")}
                  </th>
                )}
                {selectedColumns.address_start && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("address_start")}
                  >
                    {translate("Address start")}
                  </th>
                )}
                {selectedColumns.address_end && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("address_end")}
                  >
                    {translate("Address end")}
                  </th>
                )}


                {selectedColumns.start_time && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("datecreategeof")}
                  >
                    {translate("Type")}
                  </th>
                )}

                {selectedColumns.end_time && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("end_time")}
                  >
                    {translate("end_time")}
                  </th>
                )}

                {selectedColumns.weight && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("weight")}
                  >
                    {translate("weight")}
                  </th>
                )}

                {selectedColumns.status && (
                  <th
                    className="sorting"
                    onClick={() => handleSortingColum("status")}
                  >
                    {translate("status")}
                  </th>
                )}


                {<th>{translate("Action")}</th>}
              </tr>
            </thead>
            <tbody key="#" className="ligth-body">
              {loading ? (
                <tr style={{ textAlign: "center" }}>
                  <td className="text-center" colSpan={10}>
                    <p><PropagateLoader
                      color={"#123abc"}
                      loading={loading}
                      size={20}
                    /></p>
                  </td>
                </tr>
              ) : list_Trip.length > 0 ? (
                list_Trip.map((TMS) => (
                  <tr key={TMS.id_trip}>
                    <td>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox-${TMS.id_trip}`}
                          checked={selectedTMSs.includes(TMS.id_trip.toString())}
                          onChange={() => handleTMSsSelect(TMS.id_trip.toString())}
                        />
                      </div>
                    </td>
                    {selectedColumns.id_trip && <td>{TMS.id_trip}</td>}
                    {selectedColumns.PSN && <td>{TMS.PSN}</td>}
                    {selectedColumns.address_start && <td>{TMS.lat_start} {TMS.lon_start}</td>}
                    {selectedColumns.address_end && <td>{TMS.lat_end} {TMS.lon_end}</td>}
                    {selectedColumns.start_time && <td>{formatToTimestamp(TMS.start_time)}</td>}
                    {selectedColumns.end_time && <td>{formatToTimestamp(TMS.end_time)}</td>}
                    {selectedColumns.weight && <td>{TMS.weight} Kg</td>}
                    {selectedColumns.status && (<td>
                      {
                        TMS.status === "in progress" ? (
                          <>
                            <i className="fas fa-hourglass-start" style={{ marginRight: "5px", color: "#ffc107" }}></i>
                            {translate("In Progress")}
                          </>
                        ) : TMS.status === "completed" ? (
                          <>
                            <i className="fas fa-check-circle" style={{ marginRight: "5px", color: "#28a745" }}></i>
                            {translate("Completed")}
                          </>
                        ) : TMS.status === "canceled" ? (
                          <>
                            <i className="fas fa-times-circle" style={{ marginRight: "5px", color: "#dc3545" }}></i>
                            {translate("Canceled")}
                          </>
                        ) : (
                          <>
                            <i className="fas fa-exclamation-circle" style={{ marginRight: "5px", color: "#6c757d" }}></i>
                            {translate("Pending")}
                          </>
                        )
                      }
                    </td>)}
                    <td>
                      <div className="d-flex align-items-center list-action">
                        <Link
                          to={`/tms/edit/${TMS.id_trip}`}
                          className="badge badge-success mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Editer Trip"
                        >
                          <i
                            className="las la-eye"
                            style={{ fontSize: "1.2em" }}
                          ></i>
                        </Link>
                        <Link
                          to={`#`}
                          className="badge badge-warning  mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Trip"
                        >
                          <i
                            className="las la-trash"
                            style={{ fontSize: "1.2em" }}
                            onClick={() => handleDeleteTMS(TMS.id_trip.toString(), parseInt(id_user ?? "0", 10))}
                          ></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10}>No trip available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {list_Trip.length} {translate("out of")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={currentPage - 1}
          />
        </div>
        <GeoFenceUploadModal
          show={showCreateTMSModal}
          onHide={handleCloseCreateTMSModal}
          refreshGeofences={refreshTMS}
        />
        <DownloadModal
          show={showDownloadModal}
          onHide={() => setShowDownloadModal(false)}
          onDownloadConfirm={onDownloadConfirm}
        />
        <DeleteTMSModal
          show={modalStatus !== null}
          onHide={closeModal}
          status={modalStatus}
          title={titleStatus}
          IdUser={IdUser}
          IdTMS={IdTMS}
          updateTMSList={refreshTMS}
        />
      </div>
    </>
  );
}
