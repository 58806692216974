import React from "react";
import { Form } from "react-bootstrap";

interface Option {
  value: any;
  label: string;
}

interface SelectGroupProps {
  controlId: string;
  label: string;
  icon: string;
  disabled?: boolean;
  options: Option[];
  valueType: { value: any; label: any };
  onChange?: (selectedOption: any) => void;
  handleCategoryChange?: (selectedOption: any) => void;
  placeholder?: string;
  noOptionsMessage?: string;
}

const SelectReport: React.FC<SelectGroupProps> = ({
  controlId,
  label,
  options,
  icon,
  disabled, 
  valueType,
  onChange, 
  handleCategoryChange,
}) => {

 

  const searchType: { [key: string]: string } = {
    "11": "Matriculation" ,
    "12": "PSN" ,
    "13": "Vehicle code" ,
    "14": "Vehicle group" ,
    "15": "User" ,
    "21": "Fleet" , 
    "31": "Driver" ,
    "32": "User" ,
    "33": "Agency" ,
 }; 
 
 return (
  <Form.Group controlId={controlId}>
    <Form.Label tyle={{margin: 0}}>{label}</Form.Label>
    <div className="d-flex align-items-center">
      <i className={`las la-${icon} icon-report`} style={{padding: "0px 5px"}}/>   
      <Form.Control
        as="select"
        value={valueType.value}
        disabled={disabled}
        onChange={(e) => {
          const selectedValue = e.target.value;
          const selectedLabel = searchType[selectedValue] || selectedValue;
          if (onChange) {
            onChange({ value: selectedValue, label: selectedLabel });
          } else if (handleCategoryChange) {
            handleCategoryChange({ value: selectedValue, label: selectedLabel });
          }
        }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Control>
    </div>
  </Form.Group>
);
};

export default SelectReport;
