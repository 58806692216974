// src/components/Dashboard/api.ts

import axios from 'axios';
import { Widget } from '../../type';


// URL de base de votre API backend
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000/api';

// Création d'une instance Axios configurée
const axiosInstance = axios.create({
  baseURL: backendUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercepteur pour ajouter le token d'authentification à chaque requête
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Supposant que le token est stocké dans localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
 

export const fetchDashboardWidgets = async (id_dashboard: number): Promise<Widget[]> => {
  try {
    const response = await axiosInstance.get(`/api/dashboard/${id_dashboard}/widgets`);
    return response.data.Dashboards || []; // Assure que le retour est toujours un tableau
  } catch (error) {
    console.error('Erreur lors de la récupération des widgets:', error);
    return []; // Retourne un tableau vide en cas d'erreur
  }
};
// Fonction pour ajouter un nouveau widget
export const addWidget = async (widget: Omit<Widget, 'id'>): Promise<{ id_widget: number }> => {
  try {
    const response = await axiosInstance.post('/api/dashboard/add', widget);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'ajout du widget:', error);
    throw error;
  }
};


export const updateDashboard = async (widgets: Widget[]) => {
  const response = await fetch(backendUrl+'/api/dashboard/update', {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ widgets }), // Envoyer les widgets dans un tableau
  });

  if (!response.ok) {
      throw new Error('Failed to update dashboard');
  }

  return response.json();
};

// Fonction pour supprimer un widget spécifique
export const deleteWidget = async (id_widget: string): Promise<{ message: string }> => {
  try {
    const response = await axiosInstance.delete(`/api/dashboard/delete/${id_widget}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression du widget:', error);
    throw error;
  }
};

// Export de l'instance Axios (si nécessaire)
export default axiosInstance;
