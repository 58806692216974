import React from 'react';
import ChatHistory from '../components/Chat/ChatHistory';
const Chat: React.FC = () => {
  return (
    <div className="flex-grow-1 container-p-y">
      <div className="app-chat card overflow-hidden"> 
        <div className="row g-0">
          <ChatHistory />
        </div>
      </div>
    </div>
  );
}; 

export default Chat;
