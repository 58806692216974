import React, { useEffect, useState } from 'react';
import {Button, Table } from 'react-bootstrap';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useTranslate } from "../../hooks/LanguageProvider";
import { convertDurationToSeconds, formatDuration, formatDurationWithDays, generateCustomPDF, toTimestamp } from '../../utilities/functions';
import { GetAddressesFromCoords } from "../../utilities/functions";
import ExcelJS from "exceljs";
import { Bounce, toast } from 'react-toastify';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';


const backendUrl = process.env.REACT_APP_BACKEND_URL;


interface ReportData {
  start: string;
  end: string;
  ENGINESTAT: number;
  duration: string;
  Odometer: string;
  distance: number;
  max_speed: number;
  LAT: number;
  LNG: number;
  SOG: number;
  address?: string;
}

interface ApiResponse {
  immatriculation: string;
  repportDatas: ReportData[];
}

interface VehicleWidgetProps {
  type_report?: string;
  turn?: string;
  id_report?: string;
}


const Report10: React.FC<VehicleWidgetProps> = ({ id_report , turn }) => {
  
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const { translate } = useTranslate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${backendUrl}/api/report2/${id_report}`);
        const data: ApiResponse = await response.json();
        if (data) {
          // Récupération des adresses
          const locations = data.repportDatas.map((report, index) => ({
            id: index, // Utilisation de l'index comme identifiant unique
            lat: report.LAT,
            lon: report.LNG
          }));
          let addressData :any;
          try {
            addressData = await GetAddressesFromCoords(locations);
          } catch (error) {
            console.error("Erreur lors de la récupération des adresses:", error);
            // En cas d'échec, utiliser les coordonnées directement
            addressData = locations.map(location => ({ address: `${location.lat}, ${location.lon}` }));
          }
  
          // Mettre à jour les données avec les adresses correspondantes
          data.repportDatas.forEach((report, index) => {
            report.address = addressData[index]?.address || ''; // Adresse ou une chaîne vide par défaut
          });
  
          setApiResponse(data);
        } else {
          console.error("Erreur lors de la récupération du rapport: Aucune donnée renvoyée par l'API");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du rapport:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id_report]);
  
  

  function groupDataByDay(data: ReportData[]): Record<string, ReportData[]> {
    return data.reduce((acc, report) => {
      const tripDate = new Date(report.start).toLocaleDateString();
      if (!acc[tripDate]) {
        acc[tripDate] = [];
      }
      acc[tripDate].push(report);
      return acc;
    }, {} as Record<string, ReportData[]>);
  }

  const startDate = apiResponse?.repportDatas.length ? new Date(apiResponse.repportDatas[0].start).toLocaleString() : '';
  const endDate = apiResponse?.repportDatas.length ? new Date(apiResponse.repportDatas[apiResponse.repportDatas.length - 1].end).toLocaleString() : '';


//**** Partie Excel **** */

// Fonction pour calculer les statistiques de la journée
const calculateDayStatistics = (reports :any) => {
  const totalDistance = reports.reduce((acc :any, report :any) => acc + report.distance, 0);
  const totalDuration = reports.reduce((acc :any, report :any) => {
    const durationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
    return acc + durationInSeconds;
  }, 0);
  const drivingDuration = reports.reduce((acc :any, report :any) => {
    if (report.ENGINESTAT === 1 && report.SOG > 5) {
      const durationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
      return acc + durationInSeconds;
    }
    return acc;
  }, 0);
  const totalStopDuration = reports.reduce((acc :any, report :any) => {
    if (report.ENGINESTAT === 0) {
      const durationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
      return acc + durationInSeconds;
    }
    return acc;
  }, 0);
  const stopDuration = reports.reduce((acc :any, report :any) => {
    if (report.ENGINESTAT === 1 && report.SOG <= 5) {
      const durationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
      return acc + durationInSeconds;
    }
    return acc;
  }, 0);

  return {
    totalDistance,
    totalDuration: formatDuration(totalDuration),
    drivingDuration: formatDuration(drivingDuration),
    totalStopDuration: formatDuration(totalStopDuration),
    stopDuration: formatDuration(stopDuration)
  };
};

 // Fonction pour calculer les statistiques globales à partir de toutes les données
 const calculateGlobalStatistics = (allReports :any) => {
  const totalDistance = allReports.reduce((acc  :any, report  :any) => acc + report.distance, 0);
  const totalDurationInSeconds = allReports.reduce((acc  :any, report  :any) => acc + convertDurationToSeconds(report.duration || '0h 0m 0s'), 0);
  const totalStopDurationInSeconds = allReports.reduce((acc  :any, report  :any) => {
    if (report.ENGINESTAT === 0) {
      return acc + convertDurationToSeconds(report.duration || '0h 0m 0s');
    }
    return acc;
  }, 0);
  const totalDrivingDurationInSeconds = allReports.reduce((acc  :any, report  :any) => {
    if (report.ENGINESTAT === 1 && report.SOG > 5) {
      return acc + convertDurationToSeconds(report.duration || '0h 0m 0s');
    }
    return acc;
  }, 0);
  const totalStopDurationWithEngineInSeconds = allReports.reduce((acc  :any, report  :any) => {
    if (report.ENGINESTAT === 1 && report.SOG <= 5) {
      return acc + convertDurationToSeconds(report.duration || '0h 0m 0s');
    }
    return acc;
  }, 0);

  return {
    totalDistance,
    totalDuration: formatDuration(totalDurationInSeconds),
    totalStopDuration: formatDuration(totalStopDurationInSeconds),
    totalDrivingDuration: formatDuration(totalDrivingDurationInSeconds),
    totalStopDurationWithEngine: formatDuration(totalStopDurationWithEngineInSeconds)
  };
};

// Fonction pour générer et télécharger le fichier Excel
const handleDownloadExcel = () => {
  if (apiResponse) {
    const workbook = new ExcelJS.Workbook();
    
   // Créer la première feuille pour le nom du rapport et la date de création
const firstSheet = workbook.addWorksheet(turn);

// Ajouter le titre du rapport et centrer dans les colonnes A à B
const titleRow = firstSheet.addRow(['Contact Report']);
titleRow.font = { bold: true, size: 20 }; // Style de police en gras et taille plus grande
firstSheet.mergeCells('A1:B1'); // Fusionner les cellules pour le titre
titleRow.alignment = { horizontal: 'center' }; // Centrer le titre

// Ajouter une ligne vide
firstSheet.addRow([]);

// Ajouter la date de création du rapport sous le titre et l'aligner à gauche
const dateRow = firstSheet.addRow(['Date de création', new Date().toLocaleString()]);
dateRow.font = { bold: false, size: 14 }; // Style de police régulière et taille plus petite
dateRow.alignment = { horizontal: 'center' }; // Aligner à gauche

// Fusionner les cellules pour le numéro de tournée et le véhicule
firstSheet.mergeCells('A4:B4'); 

// Ajouter le numéro de tournée et le véhicule sous la date de création
const tourInfo = `No. Tour : ${turn} / Véhicule : ${apiResponse?.immatriculation || 'Chargement...'}`;
const tourInfoRow = firstSheet.addRow([tourInfo]);
tourInfoRow.font = { bold: false, size: 14 }; // Style de police régulière et taille plus petite
tourInfoRow.alignment = { horizontal: 'center' }; // Aligner à gauche

// Ajuster la largeur des colonnes A et B pour le titre et la date de création
firstSheet.getColumn(1).width = 60;
firstSheet.getColumn(2).width = 60;

    // Diviser les données par jour
    const dataByDay = groupDataByDay(apiResponse.repportDatas);
    const allReports = apiResponse.repportDatas;
    const globalStats = calculateGlobalStatistics(allReports);

   
    
    // Pour chaque jour, créer une feuille Excel avec les données de ce jour
    Object.entries(dataByDay).forEach(([day, dayReports]) => {
      const sheetName = `Repport ${day.replace(/\//g, "-")}`;
      const secondSheet = workbook.addWorksheet(sheetName);
      // Ajouter le titre et les informations supplémentaires
      const reportTitle = `Contact Report for ${day}`;
      const tourInfo = `No. Tour : ${turn} / Véhicule : ${apiResponse?.immatriculation || 'Chargement...'}`;
      const titleRow2 = secondSheet.addRow([reportTitle]);
      titleRow2.font = { bold: true, size: 16 }; // Taille de la police plus grande
      const tourInfoRow = secondSheet.addRow([tourInfo]);
      tourInfoRow.font = { bold: true, size: 14 }; // Taille de la police plus grande
      secondSheet.addRow([]); // Ajouter une ligne vide pour séparer le titre des données
      // Ajouter les en-têtes de colonne
      const headerRow = secondSheet.addRow(['Départ', 'Arrivé', 'Adresse', 'Distance (KM)', 'Durée', 'État']);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'A9A9A9' },
          bgColor: { argb: 'A9A9A9' }
        };
        cell.font = { bold: true  ,size: 14};
        cell.alignment = { horizontal: 'left' };
      });
      // Ajuster la largeur des colonnes
      secondSheet.columns.forEach(column => {
        column.width = 25;
      });
      
      // Ajouter les données
  
// Ajouter les données
dayReports.forEach(report => {
  let stateText;
  let distanceValue;
  let distanceCellStyle :any; // Style de la cellule de distance
  
  if (report.ENGINESTAT === 1 && report.SOG > 5) {
    stateText = "En marche";
    distanceValue = report.distance; // Utiliser la distance normale si en marche
    distanceCellStyle = {}; // Aucun style spécial
  } else if (report.ENGINESTAT === 0) {
    stateText = "Arrêté";
    distanceValue = report.distance; // Utiliser la distance normale si arrêté
    distanceCellStyle = {}; // Aucun style spécial
  } else {
    stateText = "En pause";
    if (report.distance === 0) {
      distanceValue = "Stopping with the engine on"; // Remplacer la distance par le message
    } else {
      distanceValue = report.distance; // Utiliser la distance normale si différente de zéro
    }
    distanceCellStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F7AC34' } } }; // Style spécial pour la cellule de distance
  }
  
  const row = secondSheet.addRow([
    new Date(report.start).toLocaleTimeString(),
    new Date(report.end).toLocaleTimeString(),
    report.address,
    distanceValue, // Utiliser la distance ou le message
    report.duration,
    stateText
  ]);
  row.eachCell((cell, number) => {
    cell.alignment = { horizontal: 'left' };
  });
  // Appliquer le style spécial à la cellule de distance
  if (distanceCellStyle.fill) {
    const distanceCell = row.getCell(4); // Index de la cellule de distance (0-based)
    distanceCell.fill = distanceCellStyle.fill; // Appliquer le style spécial
  }
});


      // Ajouter les statistiques de la journée
      const dayStats = calculateDayStatistics(dayReports);
      secondSheet.addRow([]); // Ajouter une ligne vide pour séparer les données des statistiques
      secondSheet.addRow(['Statistiques de la journée']);
      secondSheet.addRow(['Total distance (KM)', dayStats.totalDistance]);
      secondSheet.addRow(['Durée totale', dayStats.totalDuration]);
      secondSheet.addRow(['Durée de conduite', dayStats.drivingDuration]);
      secondSheet.addRow(['Durée de stationnement', dayStats.totalStopDuration]);
      secondSheet.addRow(['Durée de stationnement (Moteur allumé)', dayStats.stopDuration]);
    });

     // Ajouter une feuille pour le résumé global
      const globalSummarySheet = workbook.addWorksheet('Statistiques Globales');
      globalSummarySheet.mergeCells('A1:B1'); // Fusionner les cellules pour le titre
      const titleCell = globalSummarySheet.getCell('A1');
      titleCell.value = 'Global Statistics';
      titleCell.font = { bold: true, size: 16 }; // Style du titre
      titleCell.alignment = { horizontal: 'center' }; // Centrer le titre

      // Ajouter les statistiques globales avec des libellés explicites
      const globalStatsLabels = [
        ['Total Distance (KM)', globalStats.totalDistance],
        ['Total Duration', globalStats.totalDuration],
        ['Total Driving Duration', globalStats.totalDrivingDuration],
        ['Total Stop Duration', globalStats.totalStopDuration],
        ['Total Stop Duration (Engine On)', globalStats.totalStopDurationWithEngine]
      ];

      // Ajouter les données dans la feuille
      globalStatsLabels.forEach((label, index) => {
        const [statLabel, statValue] = label;
        const rowNumber = index + 2; // Commencer à la deuxième ligne après le titre
        globalSummarySheet.getCell(`A${rowNumber}`).value = statLabel;
        globalSummarySheet.getCell(`B${rowNumber}`).value = statValue;
      });

      // Ajuster la largeur des colonnes
      globalSummarySheet.getColumn('A').width = 30;
      globalSummarySheet.getColumn('B').width = 20;

    // Télécharger le fichier Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'rapport2.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      // Afficher un toast pour indiquer que le téléchargement est terminé
      toast.success('Le fichier Excel a été téléchargé avec succès.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }).catch(error => {
      console.error('Erreur lors de la génération du fichier Excel :', error);
      // Afficher un toast pour indiquer qu'il y a eu une erreur lors de la génération du fichier Excel
      toast.error('Une erreur est survenue lors de la génération du fichier Excel.', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    });
  } else {
    // Afficher un toast pour indiquer qu'il n'y a pas de données à télécharger
    toast.warn('Aucune donnée disponible pour générer le fichier Excel.', {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
};

//**** Partie PDF ***/
// Fonction pour générer et télécharger le fichier PDF
const handleDownloadPDF = async () => {
  if (!apiResponse) return;

  try {
    const matricule = apiResponse.immatriculation || '';
    const tour = turn || '';
    // Créer un nouveau document PDF avec le titre et la date de création sur la première page
    const pdfBytes = await generateCustomPDF('Contact Report', tour, matricule);

    // Charger le document PDF généré
    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Ajouter les données de chaque jour à partir de la deuxième page
    Object.entries(groupDataByDay(apiResponse.repportDatas)).forEach(async ([day, dayReports]) => {
      const stats = calculateDayStatistics(dayReports);

      // Ajouter une nouvelle page pour chaque jour
      const page = pdfDoc.addPage([600, 800]);
      const { width, height } = page.getSize();
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      let y = height - 50;

       // Afficher la journée
       page.drawText(`No. Tour : ${tour} / Vehicles : ${matricule}`, { x: 40, y, size: 14, font });
       page.drawText(`${day}`, { x: 500, y, size: 14, font });
       y -= 30;

      // Ajouter les données de chaque rapport dans un tableau avec un titre pour chaque colonne
      const columnHeader = ['Départ', 'Arrivé', 'Distance (KM)', 'Durée', 'État'];
      const columnWidths = [100, 100, 100, 100, 100, 100]; // Largeur de chaque colonne

      // Définir les couleurs et la taille de la bordure
      const borderColor = rgb(0.2, 0.2, 0.2);
      const borderWidth = 1;

      // Définir la couleur de fond et la couleur du texte pour les en-têtes de colonnes
      const headerBackgroundColor = rgb(0.8, 0.8, 0.8);
      const headerTextColor = rgb(0, 0, 0);

      // Dessiner le tableau et les en-têtes de colonnes
      let x = 40;
      let headerY = y;
      columnHeader.forEach((title, index) => {
          // Dessiner le rectangle pour l'en-tête de colonne
          page.drawRectangle({
              x,
              y: headerY,
              width: columnWidths[index],
              height: 20,
              borderColor,
              borderWidth,
              color: headerBackgroundColor,
          });
          // Ajouter le texte de l'en-tête de colonne
          page.drawText(title, { x: x + 5, y: headerY + 5, size: 12, font, color: headerTextColor });
          x += columnWidths[index];
      });

      // Dessiner les lignes horizontales du tableau
      y -= 20;
   
     
      // Dessiner les lignes verticales du tableau
    

      // Ajouter les données de chaque rapport dans le tableau
      dayReports.forEach((report, reportIndex) => {
          let x = 40;
          const data = [
              new Date(report.start).toLocaleTimeString(),
              new Date(report.end).toLocaleTimeString(),
              report.distance,
              report.duration,
              report.ENGINESTAT === 1 && report.SOG > 5 ? 'En marche' : report.ENGINESTAT === 0 ? 'Arrêté' : 'En pause'
          ];
          data.forEach((value, index) => {
              page.drawText(value.toString(), { x, y, size: 12, font });
              x += columnWidths[index];
          });
          // Dessiner la ligne horizontale pour chaque rapport
          if (reportIndex < dayReports.length - 1) {
              y -= 20;
             
          }
      });

      // Mettre à jour la position y
      y -= 30;


        // Définir les statistiques de la journée
      const statsData = Object.entries(stats).map(([label, value]) => ({ label, value }));

      // Définir les en-têtes de colonnes pour les statistiques
      const statsColumnHeader = ['Statistique', 'valeur'];
      const statsColumnWidths = [200, 200]; // Largeur de chaque colonne

      // Dessiner les en-têtes de colonnes pour les statistiques
      let statsX = 40;
      let statsHeaderY = y;
      statsColumnHeader.forEach((title, index) => {
          // Dessiner le rectangle pour l'en-tête de colonne
          page.drawRectangle({
              x: statsX,
              y: statsHeaderY,
              width: statsColumnWidths[index],
              height: 20,
              borderColor,
              borderWidth,
              color: headerBackgroundColor,
          });
          // Ajouter le texte de l'en-tête de colonne
          page.drawText(title, { x: statsX + 5, y: statsHeaderY + 5, size: 12, font, color: headerTextColor });
          statsX += statsColumnWidths[index];
      });

      // Dessiner les lignes horizontales du tableau de statistiques
      y -= 20;
      
      // Ajouter les données de statistiques dans le tableau
      statsData.forEach((stat, statIndex) => {
          let statsX = 40;
          const data = [stat.label, stat.value];
          data.forEach((value, index) => {
              page.drawText(value.toString(), { x: statsX, y, size: 12, font });
              statsX += statsColumnWidths[index];
          });
          // Dessiner la ligne horizontale pour chaque statistique
          if (statIndex < statsData.length - 1) {
              y -= 20;
          }
      });

      // Mettre à jour la position y
      y -= 20;


     
    });

    // Enregistrer le document PDF
    const modifiedPdfBytes = await pdfDoc.save();

    // Télécharger le fichier PDF
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'contact_report.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    // Afficher un toast de succès
    toast.success('Le fichier PDF a été téléchargé avec succès.', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  } catch (error) {
    console.error('Erreur lors de la génération du fichier PDF :', error);

    // Afficher un toast d'erreur
    toast.error('Une erreur est survenue lors de la génération du fichier PDF.', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  }
};





return (
  <>
    {loading ? (
      <div className="text-center">
        <PropagateLoader color={'#123abc'} loading={loading} size={20} />
      </div>
    ) : (
      <>
        
       <div className="row d-flex align-items-center">
        <div className="col-sm-6 col-md-6">
          <h6 className="">
            <i className="las la-chart-bar" data-rel="bootstrap-tooltip" title="Increased"></i> {translate("Contact Report")} {translate('From')} {' '}
            <span style={{ color: '#3468C0' }}>{startDate}</span> {translate('To')} {' '}
            <span style={{ color: '#3468C0' }}>{endDate}</span>
          </h6>
        </div>
        <div className="col-sm-6 col-md-6" style={{ padding: '10px' }}>
          <div id="DataTables_Table_0_filter" className="float-right dataTables_filter mr-3">
            <Button className="btn btn-info mt-2 mr-1" onClick={handleDownloadExcel}>
              <i className="las la-file-excel mr-3"></i>
              {translate('Download Excel')}
            </Button>
            <button className="btn btn-outline-danger mt-2" onClick={handleDownloadPDF}>
              <i className="las la-file-pdf mr-3"></i>
              {translate("Download PDF")}
            </button>
          </div>
        </div>
      </div>



    {apiResponse &&
        Object.entries(groupDataByDay(apiResponse.repportDatas)).map(([day, dayReports]) => {
          // Calcul des statistiques pour chaque journée
          const totalDistance = Math.round(dayReports
            .filter(report => !(report.ENGINESTAT === 1 && report.SOG <= 5))
            .reduce((acc, report) => acc + parseFloat(report.Odometer), 0)) || 0;
          

      
        const roundedTotalDistance = Math.round(totalDistance); // Arrondir à l'entier le plus proche

          const totalDurationInSeconds = dayReports.reduce((acc, report) => {
            const durationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
            return acc + durationInSeconds;
          }, 0) || 0;

          const totalDurationFormatted = formatDuration(totalDurationInSeconds);

          const SOGdiffzero = dayReports.filter(report => report.SOG > 5);
          const totalSOG = SOGdiffzero.reduce((sum, report) => sum + report.SOG, 0) || 0;
          const averageSOG = SOGdiffzero.length ? totalSOG / SOGdiffzero.length : 0;
          const averageSpeedText = `${averageSOG.toFixed(2)} km/h`;

          const drivingDurationInSeconds = dayReports
            .filter(report => report.ENGINESTAT === 1 && report.SOG > 5)
            .reduce((acc, report) => {
              const durationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
              return acc + durationInSeconds;
            }, 0) || 0;

          const drivingDurationFormatted = formatDuration(drivingDurationInSeconds);

          const totalStopDurationInSeconds = dayReports.reduce((acc, report) => {
            if (report.ENGINESTAT === 0) {
              const stopDurationInSeconds = convertDurationToSeconds(report.duration || '0h 0m 0s');
              return acc + stopDurationInSeconds;
            }
            return acc;
          }, 0) || 0;

          const totalStopDurationFormatted = formatDuration(totalStopDurationInSeconds);
      
          const totalSOGGreaterThan5 = dayReports.reduce((acc, report) => {
              return report.SOG > 5 ? acc + 1 : acc;
          }, 0) || 0;
          
          let maxSpeed = 0;
          if (totalSOGGreaterThan5 > 0) {
              const sumMaxSpeeds = dayReports.reduce((acc, report) => {
                  return report.SOG > 5 ? acc + report.max_speed : acc;
              }, 0);
              maxSpeed = sumMaxSpeeds / totalSOGGreaterThan5;
          }
          


          

          return (
            <div key={day}>
              <Table>
                <thead className="bg-white">
                  <tr className="ligth ligth-data">
                    <th colSpan={8} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}> 
                      <div style={{ float: 'left' }}>
                      {translate("No. Tour")} : {turn} /   {translate("Vehicle")}  : {apiResponse?.immatriculation || 'Chargement...'} 
                      </div>
                      <div style={{ float: 'right' }}>
                      {day}
                      </div>
                    </th>
                  </tr>
                  <tr className="ligth ligth-data">
                    <th></th>
                    <th>{translate("Starting")}</th>
                    <th>{translate("Arrived")}</th>
                    <th>{translate("Address")}</th>
                    <th>{translate("Distance")}</th>
                    <th>{translate("Duration")}</th>
                    <th>{translate("State")}</th>
                  </tr>
                </thead>
                <tbody>
                {dayReports.map((report, index) => {
                      return (
                        <tr key={index}>
                          <td></td>
                          <td>{toTimestamp(report.start).split(' ')[1]}</td>
                          <td>{toTimestamp(report.end).split(' ')[1]}</td>
                          <td>{report.address}</td>
                          {report.ENGINESTAT === 1 && report.SOG <= 5 ? (
                            <td style={{ backgroundColor: '#f7ac34', color: '#fff' }}>{translate("Stopping with the engine on")}</td>
                          ) : (
                            <td>{report.Odometer == '0' ? '--' : `${report.Odometer} Km`}</td>
                          )}
                          <td>{report.duration}</td>
                          <td>
                            {report.ENGINESTAT === 1 && report.SOG > 5 ? (
                              <i className="fa fa-play" style={{ color: 'green' }} title="En marche"></i>
                            ) : report.ENGINESTAT === 0 ? (
                              <i className="fa fa-stop" style={{ color: 'blue' }} title="Arrêté"></i>
                            ) : (
                              <i className="fa fa-pause" style={{ color: '#b94a48' }} title="En pause"></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
              </Table>

              <hr style={{ border: '1px solid #191919', margin: '20px 0' }} />

              {/* Table for  Statistics */}
              <Table>
                <thead className="bg-white text-uppercase">
                  <tr className="ligth ligth-data">
                    <th colSpan={8} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                    {translate("Statistics of the Day")} - {day}  
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h6>{translate("Total distance")}</h6>
                    </td>
                    <td>{roundedTotalDistance} KM</td>
                    <td>
                      <h6>{translate("Total duration")}</h6>
                    </td>
                    <td>{totalDurationFormatted}</td>
                  </tr>
                  <tr>
                  <td>
                    <h6>{translate("Maximum speed")}</h6>
                  </td>
                  <td>
                  {Math.round(maxSpeed)} km/h
                    </td>
                    <td>
                      <h6>{translate("Driving time")}</h6>
                    </td>
                    <td>{drivingDurationFormatted}</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>{translate("Average speed")}</h6>
                    </td>
                    <td>
                      {averageSpeedText}
                    </td>
                    <td>
                      <h6>{translate("Parking duration")}</h6>
                    </td>
                    <td>{totalStopDurationFormatted}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          );
        })}
        <hr style={{ border: '1px solid #191919', margin: '20px 0' }} /> 
         {/* Section pour les statistiques globales */}
        {apiResponse && Object.keys(groupDataByDay(apiResponse.repportDatas)).length > 2 && (
          <div>
            <Table>
              <thead className="bg-white text-uppercase">
                <tr className="ligth ligth-data">
                  <th colSpan={8} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  {translate("Global statistics")}
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <h6>{translate("Total distance")}</h6>
                </td>
                <td>
                    {apiResponse.repportDatas.reduce((acc, report) => {
                      if (report.ENGINESTAT === 1 && report.SOG > 5) {
                        return acc + report.distance;
                      }
                      return acc;
                    }, 0)} KM
                  </td>                
                  <td>
                  <h6>{translate("Total duration")}</h6>
                </td>
                <td>
                  {formatDurationWithDays(
                    apiResponse.repportDatas.reduce((acc, report) => acc + convertDurationToSeconds(report.duration || '0h 0m 0s'), 0)
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <h6>{translate("Parking duration")}</h6>
                </td>
                <td>
                  {formatDurationWithDays(
                    apiResponse.repportDatas.reduce(
                      (acc, report) => acc + (report.ENGINESTAT === 0 ? convertDurationToSeconds(report.duration || '0h 0m 0s') : 0),
                      0
                    )
                  )}
                </td>
                <td>
                  <h6>{translate("Driving time")}</h6>
                </td>
                <td>
                  {formatDurationWithDays(
                    apiResponse.repportDatas.reduce(
                      (acc, report) => acc + (report.ENGINESTAT === 1 && report.SOG > 5 ? convertDurationToSeconds(report.duration || '0h 0m 0s') : 0),
                      0
                    )
                  )}
                </td>
              </tr>
            </tbody>

            </Table>
          </div>
        )}
    </>
  )}
</>
);
};

export default Report10;