import { PDFDocument, StandardFonts } from 'pdf-lib';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { polygon } from '@turf/helpers';
import area from '@turf/area';
import Highcharts from 'highcharts';
import { lineString } from '@turf/helpers';
import length from '@turf/length';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast, Bounce } from 'react-toastify';

export function formatToTimestamp(timestamp: string | number | Date) {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleString(); // ou utilisez des méthodes spécifiques pour obtenir le format souhaité
  return formattedDate;
}


export function engineStat(enginestat: number, speed: number, translate?: (key: string) => string) {
  let iconState = "asset/images/mapicon/stop.png";
  let tooltipMessage = translate ? translate("engineStopped") : "Engine stopped";


  if (enginestat == 0) {

    iconState = "asset/images/mapicon/stop.png";
    tooltipMessage = translate ? translate("engineStopped") : "Engine stopped";

  } else {

    if (enginestat == 1 && speed > 5) {
      iconState = "asset/images/mapicon/on-icon.png";
      tooltipMessage = translate ? translate("engineRunning") : "Engine running";
    } else {
      iconState = "asset/images/mapicon/pause.png";
      tooltipMessage = translate ? translate("enginePaused") : "Engine paused";
    }
  }

  return { iconState, tooltipMessage };
}

export function engineStatItinerary(enginestat: number, speed: number, translate?: (key: string) => string) {
  let iconState = "asset/images/mapicon/stop.png";
  let tooltipMessage = translate ? translate("engineStopped") : "Engine stopped";


  if (enginestat == 0) {

    iconState = "asset/images/mapicon/stop.png";
    tooltipMessage = translate ? translate("engineStopped") : "Engine stopped";

  } else {

    if (enginestat == 1 && speed > 5) {
      iconState = "asset/images/mapicon/direction-icon.png";
      tooltipMessage = translate ? translate("engineRunning") : "Engine running";
    } else {
      iconState = "asset/images/mapicon/pause.png";
      tooltipMessage = translate ? translate("enginePaused") : "Engine paused";
    }
  }

  return { iconState, tooltipMessage };
}

export function engineStatClass(enginestat: number, speed: number) {

  let iconState = "stop";

  if (enginestat == 0) {
    iconState = "stop";
  }
  else {
    if (enginestat == 1 && speed > 5) {
      iconState = "move";
    } else {
      iconState = "pause";
    }
  }

  return iconState;
}


export function Distance(distanceInNeters: number | null): string {
  if (distanceInNeters === null || isNaN(distanceInNeters)) {
    return "0 m";
  }

  if (distanceInNeters < 1000) {
    return `${distanceInNeters} m`;
  } else {
    const distanceInKilometers = distanceInNeters / 1000;
    return distanceInKilometers.toLocaleString(undefined, { maximumFractionDigits: 2 }) + " km";
  }
}


export function toTimestamp(dateString: string): string {
  if (!dateString) {
    return "-";
  }
  // Créer une nouvelle instance de Date à partir de la chaîne de caractères
  const date = new Date(dateString);

  // Extraire les composantes de la date
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 car les mois vont de 0 à 11
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Concaténer les composantes dans le format souhaité
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}


export function directionStat(direction: number) {



  let iconState = "las la-stop blue";


  return iconState;
}

// functions.ts
export function BarreReseau(gsmlvl: number) {
  if (gsmlvl < 20) {
    return "las la-signal fs-2 ; color: red;";
  } else if (gsmlvl >= 20 && gsmlvl < 50) {
    return "las la-signal fs-2 ; color: orange;";
  } else if (gsmlvl >= 50 && gsmlvl < 80) {
    return "las la-signal fs-2 ; color: yellow;";
  } else {
    return "las la-signal fs-2 ; color: green;";
  }
}

export function ValidatePosition(NST: number): string {
  return NST === 1 ? "lab la-font-awesome-flag fs-2 green" : "lab la-font-awesome-flag fs-2 red";
}

export function renderStateCell(state: number, onClickCallback?: () => void) {
  const handleClick = () => {
    if (onClickCallback && state === 1) {
      onClickCallback();
    }
  };

  if (state === 1) {
    return (
      <td onClick={handleClick} style={{ cursor: 'pointer' }}>
        <i className="las la-file-invoice" style={{ color: 'green', fontSize: '24px' }}></i>
      </td>
    );
  } else {
    if (state === 0) {
      return (
        <td onClick={handleClick} style={{ cursor: 'not-allowed' }}>
          <i className="las la-file-invoice" style={{ color: 'gray', fontSize: '24px' }}></i>
        </td>
      );
    }
    else {
      return (
        <td onClick={handleClick} style={{ cursor: 'not-allowed' }}>
          <i className="fas fa-circle-notch fa-spin" style={{ color: 'orange', fontSize: '24px' }}></i>
        </td>
      );
    }

  }
};



export function getReportName(type: string, translate: (key: string) => string): string {
  switch (type) {
    case "1":
      return translate("Reconstitution d'itinéraire");
    case "2":
      return translate("Rapport de proximité");
    case "3":
      return translate("Alarmes");
    case "4":
      return translate("Diagramme de distance, vitesse et contact");
    case "5":
      return translate("Diagramme de réservoir de carburant (IO)");
    case "6":
      return translate("Diagramme de signal GSM et GPS");
    case "7":
      return translate("Diagramme du moteur");
    case "8":
      return translate("Diagramme de distance et de consommation (CAN)");
    case "9":
      return translate("POI reports");
    case "10":
      return translate("Rapport de contact");
    case "11":
      return translate("Statistiques de RPM (CAN)");
    case "12":
      return translate("Statistiques de vitesse (CAN)");
    case "13":
      return translate("Statistiques de vitesse");
    case "14":
      return translate("Diagramme de Gantt sur la proximité");
    case "15":
      return translate("Diagramme de Gantt sur le contact");
    case "16":
      return translate("Diagramme d'état du système");
    case "17":
      return translate("Trafic mobile");
    case "18":
      return translate("Diagramme de réservoir de carburant");
    case "19":
      return translate("Diagramme de température (RHT)");
    case "20":
      return translate("Diagramme de distance et de consommation (FFS)");
    case "21":
      return translate("Diagrammes de tachygraphe (TCO)");
    case "25":
      return translate("Rapport de violation de vitesse");
    case "26":
      return translate("QHSE (Qualité, Hygiène, Sécurité, Environnement)");
    case "27":
      return translate("Rapport de comportement du conducteur");
    case "29":
      return translate("Rapport d'amplitude de travail");
    case "30":
      return translate("Rapport de flotte");
    case "31":
      return translate("Rapport de flotte (CAN)");
    case "32":
      return translate("HOS Hour of service");
    case "33":
      return translate("HOS (Heure de service) - PSN");
    case "34":
      return translate("Rapport de trajet");
    case "35":
      return translate("Driver behavior");
    case "36":
      return translate("HOS Report");
    case "45":
      return translate("Rapport CO2/Kg");
    default:
      return translate("Type de rapport inconnu");
  }
}

export const Round = (value: number, decimals: number) => {
  const multiplier = Math.pow(10, decimals);
  return Math.round(value * multiplier) / multiplier;
};

export function handleDownloadExcel(data: any, nameFile: string) {
  // /*exemple : 
  // interface GroupeDeviceCSV {
  //   'id': string;
  //   'Nom du groupe': string;
  //   'Description du groupe': string;
  //   "Date de mise à jour"?: string;
  // } 
  // loop data:
  //   return {
  //     'id': user.id_groupe,
  //     'Nom du groupe': user.nom_groupe,
  //     'Description du groupe': user.description_groupe,
  //   };*/


  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "GroupesVehicles");
  return XLSX.writeFile(wb, `${nameFile}.xlsx`);


};


export async function getAddressFromCoordinates(lat: number, lon: number): Promise<string> {

  const apiUrl = `https://geotrackin.xyz/nominatim/reverse.php?format=jsonv2&lat=${lat}&lon=${lon}`;
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch address');
    }
    const data = await response.json();
    // Assuming your API returns an object with an 'address' property
    const address = data.display_name;
    return address;
  } catch (error: any) { // Explicitly type error as 'any' or 'unknown'
    console.error('Error:', error.message);
    throw new Error('Failed to fetch address');
  }
}




// export async function getAdresse(LAT: number, LNG: number) {
//   try {
//     const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${LAT}&lon=${LNG}&format=json`);

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const res = await response.json();
//     const objadd: any = res.address;

//     return ` Pays : ${objadd.country} , Wilaya : ${objadd.state} , ${objadd.road}  `;
//   } catch (error) {
//     console.error(error);
//     return 'Erreur';
//   }
// }

export function getMessageDayStatus(isoDateString: string) {
  const messageDate = new Date(isoDateString);
  const now = new Date();

  // Utilise getTime() pour récupérer la valeur en millisecondes des dates
  const diffInMs = now.getTime() - messageDate.getTime(); // Différence en millisecondes
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  // Si c'est il y a moins d'une minute
  if (diffInSeconds < 60) {
    return "il y a quelques secondes";
  }

  // Si c'est il y a moins d'une heure
  if (diffInMinutes < 60) {
    return diffInMinutes === 1 ? "il y a une minute" : `il y a ${diffInMinutes} minutes`;
  }

  // Si c'est il y a moins d'un jour
  if (diffInHours < 24) {
    return diffInHours === 1 ? "il y a une heure" : `il y a ${diffInHours} heures`;
  }

  // Si c'est aujourd'hui
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const messageDateOnly = new Date(messageDate.getFullYear(), messageDate.getMonth(), messageDate.getDate());

  if (messageDateOnly.getTime() === today.getTime()) {
    const hours = messageDate.getHours().toString().padStart(2, '0');
    const minutes = messageDate.getMinutes().toString().padStart(2, '0');
    return `aujourd'hui à ${hours}:${minutes}`;
  }

  // Si c'était hier
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (messageDateOnly.getTime() === yesterday.getTime()) {
    const hours = messageDate.getHours().toString().padStart(2, '0');
    const minutes = messageDate.getMinutes().toString().padStart(2, '0');
    return `hier à ${hours}:${minutes}`;
  }

  // Si c'est plus ancien que hier
  const hours = messageDate.getHours().toString().padStart(2, '0');
  const minutes = messageDate.getMinutes().toString().padStart(2, '0');
  return `${messageDate.getDate()} ${messageDate.toLocaleString('fr-FR', { month: 'long' })} ${messageDate.getFullYear()} à ${hours}:${minutes}`;
}




// Fonction utilitaire pour convertir la durée au format 'HH:mm:ss' en secondes BY HICHEM
export function convertDurationToSeconds(duration: string): number {
  const parts = duration.match(/(\d+)h (\d+)m (\d+)s/);

  if (parts) {
    const hours = parseInt(parts[1], 10);
    const minutes = parseInt(parts[2], 10);
    const seconds = parseInt(parts[3], 10);

    return hours * 3600 + minutes * 60 + seconds;
  }

  return 0;
}
// Fonction utilitaire pour formater la durée à partir du nombre de secondes BY HICHEM
export function formatDuration(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
}

// Fonction utilitaire pour formater la durée en jours, heures, minutes et secondes BY HICHEM
export function formatDurationWithDays(totalSeconds: number): string {
  const days = Math.floor(totalSeconds / (24 * 3600));
  const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  let formattedDuration = '';
  if (days > 0) {
    formattedDuration += `${days}j `;
  }
  if (hours > 0) {
    formattedDuration += `${hours}h `;
  }
  if (minutes > 0) {
    formattedDuration += `${minutes}m `;
  }
  formattedDuration += `${seconds}s`;

  return formattedDuration.trim();
}





// By Younes
// Fonction utilitaire pour récupérer les adresses des rapports depuis un tableau de données contenant Juste les coordonnées
export async function GetAddressesFromCoords(
  locations: {
    id: number;
    lat: number;
    lon: number;
  }[]
) {
  try {
    const geoResponse = await fetch(
      "https://geotrackin.xyz/reverse_geocode.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(locations),
      }
    );

    if (!geoResponse.ok) {
      throw new Error(`Erreur HTTP ! Statut : ${geoResponse.status}`);
    }

    return await geoResponse.json();
  } catch (error) {
    console.error("Erreur lors de la récupération des adresses:", error);
    // Gérer l'erreur en lançant une nouvelle exception ou en retournant une valeur par défaut
    throw new Error("Erreur lors de la récupération des adresses.");
  }
}


interface HasLatAndLng {
  LAT: number;
  LNG: number;
}

interface ReportData extends HasLatAndLng {
  [key: string]: any;
}

interface ReportDataWithAddress extends ReportData {
  address: string;
}

// Fonction utilitaire pour récupérer les adresses des rapports depuis un tableau de données contenant les coordon avec les données
export async function getAdressesFromData<T extends ReportData>(
  data: T[]
): Promise<(T & ReportDataWithAddress)[]> {
  const locations = data.map((report: T, index: number) => ({
    id: index,
    lat: report.LAT,
    lon: report.LNG
  }));

  try {
    const geoResponse = await fetch(
      "https://geotrackin.xyz/reverse_geocode.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(locations),
      }
    );

    if (!geoResponse.ok) {
      throw new Error(`Erreur HTTP ! Statut : ${geoResponse.status}`);
    }

    const addresses = await geoResponse.json();

    return data.map((report: T, index: number) => {
      const address = addresses[index].address;
      // Vérifier si l'API a retourné une adresse valide
      if (!address) {
        // Si l'adresse est vide, retourner les coordonnées sous forme de chaîne de caractères
        return {
          ...report,
          address: `Latitude: ${report.LAT}, Longitude: ${report.LNG}`
        };
      }
      return {
        ...report,
        address: address
      };
    });
  } catch (error) {
    // console.error(error);
    // Si une erreur se produit lors de la récupération des adresses, retourner les coordonnées avec un message d'erreur
    return data.map((report: T) => ({
      ...report,
      address: `Latitude: ${report.LAT}, Longitude: ${report.LNG}`
    }));
  }
}


export function formatDateForAlgeriaTimeZone(dateString: any) {
  // Parse the dateString into a Date object
  const date = new Date(dateString);

  // Convert the date to Algeria time zone
  const options = { timeZone: 'Africa/Algiers' };
  const formattedDate = date.toLocaleString(undefined, options);

  // Return the formatted date
  return formattedDate;
}
// Fonction By Hichem Pour généré un pdf 
export async function generateCustomPDF(title: string, turn: string, matricule: string) {
  try {
    // Créer un nouveau document PDF
    const pdfDoc = await PDFDocument.create();

    // Définir la police
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Définir les paramètres de style
    const fontSize = 12;
    const titleFontSize = 25;
    const spacing = 10;


    // Ajouter la première page avec le titre et les informations générales
    const firstPage = pdfDoc.addPage([600, 800]);


    // Le titre du rapport
    const titleWidth = font.widthOfTextAtSize(title, titleFontSize);
    const titleHeight = titleFontSize;
    const titleX = 600 / 2 - titleWidth / 2;
    const titleY = 800 / 2 - titleHeight / 2;
    firstPage.drawText(title, {
      x: titleX,
      y: titleY,
      size: titleFontSize,
      font: font,
    });

    // Date de création du rapport
    const creationDateText = `${new Date().toLocaleString()}`;
    const creationDateWidth = font.widthOfTextAtSize(creationDateText, fontSize);
    const creationDateX = 600 / 2 - creationDateWidth / 2;
    const creationDateY = titleY - fontSize - spacing;
    firstPage.drawText(creationDateText, {
      x: creationDateX,
      y: creationDateY,
      size: fontSize,
      font: font,
    });

    // Ajouter la tournée
    const tourneeText = `Tournée: ${turn}`;
    const tourneeWidth = font.widthOfTextAtSize(tourneeText, fontSize);
    const tourneeX = 600 / 2 - tourneeWidth / 2;
    const tourneeY = creationDateY - fontSize - spacing;
    firstPage.drawText(tourneeText, {
      x: tourneeX,
      y: tourneeY,
      size: fontSize,
      font: font,
    });

    // Ajouter la matricule
    const matriculeText = `Matricule: ${matricule}`;
    const matriculeWidth = font.widthOfTextAtSize(matriculeText, fontSize);
    const matriculeX = 600 / 2 - matriculeWidth / 2;
    const matriculeY = tourneeY - fontSize - spacing;
    firstPage.drawText(matriculeText, {
      x: matriculeX,
      y: matriculeY,
      size: fontSize,
      font: font,
    });

    // Sauvegarder le document PDF dans un tableau d'octets
    const pdfBytes = await pdfDoc.save();

    // Retourner les octets du PDF généré
    return pdfBytes;
  } catch (error) {
    console.error('Erreur lors de la génération du fichier PDF :', error);
    throw new Error('Une erreur est survenue lors de la génération du fichier PDF.');
  }
}


export function calculateTimeDifferenceInSeconds(date1: Date, date2: Date) {
  // Ensure the inputs are Date objects
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    throw new Error('Both arguments must be Date objects');
  }

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());

  // Convert milliseconds to seconds
  const differenceInSeconds = differenceInMilliseconds / 1000;

  return differenceInSeconds;
}



// logger.js
const backendUrl = process.env.REACT_APP_BACKEND_URL;
export async function logHistory(userID: any, action: any, page: any, operation: any, details: any) {
  try {
    const timestamp = new Date(Date.now() + 60 * 60 * 1000)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    await fetch(`${backendUrl}/api/log-user-action/${userID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action,
        page,
        operation,
        timestamp,
        details,
      }),
    });
  } catch (error) {
    console.error("Error logging user action:", error);
  }
}

export function extractObjectName(str: string) {
  // Utilisation d'une expression régulière pour extraire le nom de l'objet
  const match = str.match(/^([A-Z]+)\(.+\)$/);
  console.log(match)
  // Vérification si le nom de l'objet a été trouvé dans la chaîne
  if (match && match[1]) {
    // Retourne le nom de l'objet extrait
    return match[1];
  } else {
    return null; // Retourne null si aucun correspondance n'a été trouvée
  }
}

export interface Geofence {
  id: number;
  type?: "circle" | "polygon" | "polyline" | "marker" | "rectangle";
  center?: [number, number];
  radius?: number;
  positions?: [number, number][];
  position?: [number, number];
}



export function parseGeofenceAttributes(areageof: string, id: number): Geofence {
  try {
    let result: Geofence = { id };

    if (areageof.startsWith("POLYGON")) {
      result.type = "polygon";
      const coordinatesString = areageof.slice(9, -2); // Remove "POLYGON((" at the start and "))" at the end
      const coordinatesArray: [number, number][] = coordinatesString.split(",")
        .map((coordinatePairString: string) => {
          const [lat, lon] = coordinatePairString.trim().split(" ").map(parseFloat);
          return [lat, lon];
        });
      result.positions = coordinatesArray;
    } else if (areageof.startsWith("LINESTRING")) {
      result.type = "polyline";
      const coordinatesString = areageof.slice(11, -1); // Remove "LINESTRING(" at the start and ")" at the end
      const coordinatesArray: [number, number][] = coordinatesString.split(",")
        .map((coordinatePairString: string) => {
          const [lat, lon] = coordinatePairString.trim().split(" ").map(parseFloat);
          return [lat, lon];
        });
      result.positions = coordinatesArray;
    } else if (areageof.startsWith("CIRCLE")) {
      result.type = "circle";
      const coords = areageof.slice(7, -1).split(",").map(parseFloat); // Remove "CIRCLE(" at the start and ")" at the end
      result.center = [coords[0], coords[1]];
      result.radius = coords[2];
    } else {
      console.error("Type de zone non pris en charge :", areageof);
    }

    return result;
  } catch (error) {
    console.error("Erreur lors de l'analyse de la chaîne d'attributs geofence :", error);
    return {
      id: id,
      type: undefined,
    };
  }
}




// Définir les types pour les géofences
type CircleGeofence = {
  type: 'CIRCLE';
  radius: number; // en mètres
};

type RectangleGeofence = {
  type: 'RECTANGLE';
  width: number; // en mètres
  height: number; // en mètres
};

type PolygonGeofence = {
  type: 'POLYGON';
  vertices: { x: number; y: number }[]; // Liste des sommets du polygone
};

type LineStringGeofence = {
  type: 'LINESTRING';
  points: { x: number; y: number }[]; // Liste des points de la ligne
};

// Type générique pour les géofences
type Geofencing = CircleGeofence | PolygonGeofence | LineStringGeofence;

// Fonction pour analyser la chaîne et retourner un objet Geofence
export function parseGeofencing(input: string): Geofencing {
  const match = input.match(/^(\w+)\(([^,]+),[^,]+,(\d+(\.\d+)?)\)$/);


  if (match && match[1] === 'CIRCLE') {
    return {
      type: 'CIRCLE',
      radius: parseFloat(match[3]),
    };
  }


  // Expression régulière pour capturer le type POLYGON
  const polygonMatch = input.match(/^POLYGON\(\(([^)]+)\)\)$/);
  if (polygonMatch) {

    // Capturer les coordonnées des sommets
    const vertices = polygonMatch[1]
      .split(',')
      .map(coord => coord.trim().split(' ')
        .map(Number))
      .map(([x, y]) => ({ x, y })); // Convertir en objets { x, y }

    return {
      type: 'POLYGON',
      vertices,
    };
  }

  const lineStringMatch = input.match(/^LINESTRING\(([^)]+)\)$/);
  if (lineStringMatch) {
    const points = lineStringMatch[1].split(',').map(point => {
      const [x, y] = point.split(' ').map(coord => parseFloat(coord));
      return { x, y };
    });
    return {
      type: 'LINESTRING',
      points,
    };
  }

  throw new Error('Type de géofence inconnu ou format invalide');
};

// Fonction pour calculer la surface ou la longueur
export const calculateSurfaceOrLength = (geofence: Geofencing): number => {
  switch (geofence.type) {
    case 'CIRCLE':
      // Surface d'un cercle: π * r^2
      return Math.PI * Math.pow(geofence.radius, 2)/1000;

    case 'POLYGON':
      // Surface d'un polygone: algorithme de l'aire de Shoelace

      console.log("geofence.vertices");

      const vertices = geofence.vertices;
      const coordinates = vertices.map(vertex => [vertex.x, vertex.y]);

      // Ajouter le premier point à la fin pour fermer le polygone
      coordinates.push(coordinates[0]);

      // Créer le polygone avec Turf
      const poly = polygon([coordinates]);

      // Calculer l'aire en mètres carrés
      const areaInSquareMeters = area(poly);


      // Conversion en kilomètres carrés
      const areaInSquareKilometers = areaInSquareMeters / 1000; 
      console.log('Area in square:', areaInSquareKilometers);
      return areaInSquareKilometers;

    case 'LINESTRING':
      // Longueur d'une ligne: somme des distances entre les points


      const points = geofence.points.map(vertex => [vertex.x, vertex.y])

      // Créer un LINESTRING avec Turf
      const line = lineString(points);

      // Calculer la distance du LINESTRING
      const distance = length(line, { units: 'kilometers' });



      return distance;

    default:
      throw new Error('Type de géofence inconnu');
  }

  
};

// Exporter la fonction de copie vers le presse-papiers
export function useClipboard(name:string) {
  const [copiedId, setCopiedId] = useState<string>(name);

  function copyToClipboard(tagValue: string, tagId: string) {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = tagValue;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);

    // Marquer le tag comme copié
    setCopiedId(tagId);
    setTimeout(() => setCopiedId(name), 2000); // Effacer le feedback après 2 secondes
  }

  return { copyToClipboard, copiedId };
}


// Fonction généralisée pour générer un fichier Excel
export async function generateExcelFile(
  sheetName: string,
  headers: string[],
  data: any[][]
) {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    // Définition des propriétés de la feuille de calcul pour un affichage plus esthétique
    worksheet.properties.defaultRowHeight = 30;
    worksheet.properties.defaultColWidth = 25;

    // Ajouter les en-têtes à la feuille
    worksheet.addRow(headers).font = { bold: true };

    // Ajouter les données à la feuille
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Générer un fichier Excel en buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Créer un blob pour le téléchargement
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${sheetName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);

    // Notification de succès
    toast.success("Le fichier Excel a été téléchargé avec succès.", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  } catch (error) {
    console.error("Erreur lors de la génération du fichier Excel :", error);

    // Notification d'erreur
    toast.error("Une erreur s'est produite lors de la génération du fichier Excel.", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
}

// Fonction généralisée pour générer un fichier PDF
export async function generatePDFFile(
  sheetName: string,
  headers: string[],
  data: any[][]
) {
  try {
    const doc = new jsPDF();

    // Ajouter un titre
    doc.setFontSize(16);
    doc.text(sheetName, 14, 16);

    // Ajouter les données au PDF
    autoTable(doc, {
      head: [headers],
      body: data,
      startY: 30,
      margin: { top: 30 },
      styles: { fontSize: 10 },
    });

    // Générer le PDF en buffer
    const blob = doc.output("blob");

    // Créer un URL pour le téléchargement
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${sheetName}.pdf`; // Nom du fichier basé sur le nom de l'onglet
    a.click();
    window.URL.revokeObjectURL(url);

    // Notification de succès
    toast.success("Le fichier PDF a été téléchargé avec succès.", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  } catch (error) {
    console.error("Erreur lors de la génération du fichier PDF :", error);

    // Notification d'erreur
    toast.error("Une erreur s'est produite lors de la génération du fichier PDF.", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
}


// Fonction pour gérer la confirmation de téléchargement
export function handleDownloadConfirm(
  format: string,
  downloadExcelFunction: () => void,
  downloadPDFFunction: () => void
) {
  // Sélectionnez le format de téléchargement
  if (format === 'excel') {
    downloadExcelFunction();
  } else if (format === 'pdf') {
    downloadPDFFunction();
  }
}

// Fonction pour le modal de téléchargement
export function DownloadModal({
  show,
  onHide,
  onDownloadConfirm,
}: {
  show: boolean;
  onHide: () => void;
  onDownloadConfirm: (format: string) => void;
 
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Download Format</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
       Please select the format to download the data
        <div className="mt-3 d-flex justify-content-center">
          <Button
            variant="success"
            className="mr-2"
            onClick={() => onDownloadConfirm("excel")}
          >
            Excel
          </Button>
          <Button
            variant="danger"
            onClick={() => onDownloadConfirm("pdf")}
          >
            PDF
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// Fonction TypeScript pour obtenir les options du thème sombre
export // Fonction TypeScript pour obtenir les options du thème sombre
const getDarkThemeOptions = (): Highcharts.Options => {
    return {
        chart: {
            backgroundColor: '#1a1a1a',
            style: {
                fontFamily: 'Arial, sans-serif'
            }
        },
        title: {
            style: {
                color: '#ffffff'
            }
        },
        subtitle: {
            style: {
                color: '#dddddd'
            }
        },
        xAxis: {
            gridLineColor: '#333333',
            labels: {
                style: {
                    color: '#aaaaaa'
                }
            },
            lineColor: '#333333',
            minorGridLineColor: '#222222',
            tickColor: '#333333',
            title: {
                style: {
                    color: '#cccccc'
                }
            }
        },
        yAxis: {
            gridLineColor: '#333333',
            labels: {
                style: {
                    color: '#aaaaaa'
                }
            },
            lineColor: '#333333',
            minorGridLineColor: '#222222',
            tickColor: '#333333',
            tickWidth: 1,
            title: {
                style: {
                    color: '#cccccc'
                }
            }
        },
        tooltip: {
            backgroundColor: '#333333',
            style: {
                color: '#ffffff'
            }
        },
        legend: {
            itemStyle: {
                color: '#e0e0e0'
            },
            itemHoverStyle: {
                color: '#ffffff'
            },
            itemHiddenStyle: {
                color: '#606060'
            }
        },
        credits: {
            style: {
                color: '#666666'
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    color: '#e0e0e0'
                },
                marker: {
                    lineColor: '#333333'
                }
            },
            boxplot: {
                fillColor: '#505053'
            },
            candlestick: {
                lineColor: 'white'
            },
            errorbar: {
                color: 'white'
            }
        },
        colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1']
    };
};




