import React from 'react';
import ListReports from './ListReports';


interface ReportSelectionProps {
  selectedReports: number[];
  onReportSelect: (reports: number[]) => void;
}

const ReportSelection: React.FC<ReportSelectionProps> = ({ selectedReports, onReportSelect }) => {
  return (
    <div>
      <ListReports 
        selectedReports={selectedReports}
        onReportsChange={(reports) => onReportSelect(reports)}  
      />
    </div>
  );
}

export default ReportSelection;

// Ajouter une déclaration d'exportation vide
export {};