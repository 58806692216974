import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Bounce, toast } from 'react-toastify';
import { useTranslate } from "../../../hooks/LanguageProvider";
import SelectUsers from "./SelectUsers";
import SelectEmails from "./SelectEmail";


const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface AlarmGroupModalProps {
  show: boolean;
  onHide: () => void;
  refreshAlarmGroups: () => void;
  idAlarmGroup: number; // 0 for creation, >0 for update
}

interface GroupInfoProps {
  id_user: string;
  id_groupe_alarme: number;
  name_groupe_alarme: string;
  description: string;
  notify_email: number;
  notify_app: number;
  notify_mail_type: number;
  notify_type_app_list: number[];  // List of notification types via the app
  notify_user_app_list: number[];  // List of selected user IDs
  notify_mail_list: number[];      // List of selected email IDs
}

const AlarmGroupModal: React.FC<AlarmGroupModalProps> = ({ show, onHide, refreshAlarmGroups, idAlarmGroup }) => {
  const userID = localStorage.getItem("userID");
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useTranslate();

  const [groupInfo, setGroupInfo] = useState<GroupInfoProps>({
    id_user: userID || "0",
    id_groupe_alarme: idAlarmGroup || 0,
    name_groupe_alarme: "",
    description: "",
    notify_email: 0,
    notify_app: 0,
    notify_mail_type: 0,
    notify_type_app_list: [] as number[],
    notify_user_app_list: [] as number[],
    notify_mail_list: [] as number[]
  });

  const [usersOptions, setUsersOptions] = useState<{ value: number; label: string }[]>([]);
  const [usersEmails, setUsersEmails] = useState<{ value: number; label: string }[]>([])

  const [error, setError] = useState<string>("");

  interface CheckboxItem {
    id: string;
    name: string;
    value: number;
    label: string;
  }

  const checkboxItems: CheckboxItem[] = [
    {
      id: 'notify_type_app_list',
      name: 'notify_type_app_list',
      value: 1,
      label: translate("Show Alarm Pop-Ups")
    },
    {
      id: 'notify_type_app_list',
      name: 'notify_type_app_list',
      value: 2,
      label: translate("Allow alarm in reports"),
    },
  ];

  const handleCheckboxNotifyTypeAppListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setGroupInfo(prevGroupInfo => {
      if (checked) {
        // Add the selected value to the `notify_type_app_list` array
        return {
          ...prevGroupInfo,
          notify_type_app_list: [...prevGroupInfo.notify_type_app_list, parseInt(value)],
        };
      } else {
        // Remove the deselected value from the `notify_type_app_list` array
        return {
          ...prevGroupInfo,
          notify_type_app_list: prevGroupInfo.notify_type_app_list.filter(item => item !== parseInt(value)),
        };
      }
    });
  };


  useEffect(() => {
    if (show) {
      if (userID) {
        getUser(userID);
      }

      if (idAlarmGroup > 0) {
        const loadGroupData = async () => {
          setLoading(true);
          try {
            const response = await axios.get(`${backendUrl}/api/alarm/group/find/${idAlarmGroup}`);
            if (response.status === 200) {
              setGroupInfo({
                id_user: userID || "0",
                id_groupe_alarme: idAlarmGroup,
                name_groupe_alarme: response.data.name_groupe_alarme,
                description: response.data.description,
                notify_email: response.data.notify_email,
                notify_app: response.data.notify_app,
                notify_mail_type: response.data.notify_mail_type,
                notify_type_app_list: response.data.notify_type_app_list || [],
                notify_user_app_list: response.data.notify_user_app_list || [],
                notify_mail_list: response.data.notify_mail_list || [],
              });
 
              setIsNotifyEmailActive(response.data.notify_email===1 ? true : false)
            } else {
              setError("Failed to load group data");
            }
          } catch (error: any) {
            if (error.response) {
              console.error("Error loading group data:", error.response.data);
              setError("Error loading group data. Please try again.");
            } else {
              console.error("Error loading group data:", error.message);
              setError("An error occurred while loading the group data. Please try again.");
            }
          } finally {
            setLoading(false);
          }
        };

        loadGroupData();
      } else {
        resetForm();
      }
    }
  }, [show, idAlarmGroup]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (idAlarmGroup > 0) {
      // Call the update function here if you have one
      updateGroupAlarm(); // Call the group update function
    } else {
      // Call the group creation function
      await createGroupAlarm(groupInfo);
    }
  };

  const createGroupAlarm = async (groupInfo: any) => {
    try {
      const response = await fetch(`${backendUrl}/api/alarm/group/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(groupInfo),  // Group data
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error('Error creating group. Please try again.');
      }

      const groupResponse = await response.json();
      console.log(groupResponse);

      toast.success(translate("Group created successfully"), {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      // Reload alarm groups
      refreshAlarmGroups();
    } catch (error) {
      toast.error(translate("Can't create group. Please try again."), {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      console.error('Error creating group:', error);
    }
  };

  const resetForm = () => {
    setGroupInfo({
      id_user: userID || "0",
      id_groupe_alarme: 0,
      name_groupe_alarme: "",
      description: "",
      notify_email: 0,
      notify_app: 0,
      notify_mail_type: 0,
      notify_type_app_list: [],
      notify_user_app_list: [],
      notify_mail_list: [],
    });
    setError("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = event.target;
    const checked = (event.target as HTMLInputElement).checked;

    setGroupInfo((prevGroupInfo) => ({
      ...prevGroupInfo,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleUserChange = (selectedOptions: { value: number; label: string }[] | null) => {
    if (Array.isArray(selectedOptions)) {
      const selectedUserIds = selectedOptions.map(option => option.value);
      setGroupInfo(prevGroupInfo => ({
        ...prevGroupInfo,
        notify_user_app_list: selectedUserIds,
      }));
    } else {
      setGroupInfo(prevGroupInfo => ({
        ...prevGroupInfo,
        notify_user_app_list: [],
      }));
    }
  };

  const handleNotifyMailTypeChange = (event: any) => {
    const { value } = event.target;
  console.log(value)
    setGroupInfo(prevGroupInfo => ({
      ...prevGroupInfo,
      notify_mail_type: parseInt(value), 
    }));
  };

  const handleEmailChange = (selectedOptions: { value: number; label: string }[] | null) => {
    if (Array.isArray(selectedOptions)) {
      const notify_mail_list = selectedOptions.map(option => option.value);
      setGroupInfo(prevGroupInfo => ({
        ...prevGroupInfo,
        notify_mail_list: notify_mail_list,
      }));
    } else {
      setGroupInfo(prevGroupInfo => ({
        ...prevGroupInfo,
        notify_mail_list: [],
      }));
    }
  };

  const getUser = async (userId: any) => {
    try {
      const res = await fetch(`${backendUrl}/api/users/find/${userId}`, {
        mode: "cors",
      });

      if (!res.ok) {
        console.error("Error retrieving users");
        return;
      }

      const usersData = await res.json();

      const usersOptionsData = usersData.map((user: any) => ({
        value: user.id_user,
        label: `${user.nom_user} ${user.prenom_user || ""}`,
      }));

      // Filter users without email
      const usersEmailData = usersData
        .filter((user: any) => user.email_user !== "" && user.email_user !== null)
        .map((user: any) => ({
          value: user.id_user,
          label: `${user.email_user}`,
        }));


      setUsersOptions(usersOptionsData);
      setUsersEmails(usersEmailData);

    } catch (error) {
      console.error("Error retrieving users", error);
    }
  };

  const updateGroupAlarm = async () => {
    try {
      setLoading(true);

      const response = await axios.put(
        `${backendUrl}/api/alarm/group/update/${idAlarmGroup}`,
        groupInfo
      );

      if (response.status === 200 || response.status === 204) {
        toast.success(translate("Group updated successfully"), {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        resetForm();
        onHide();
        refreshAlarmGroups();
      } else {
        setError("Failed to update group");
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Error updating group:", error.response.data);
        setError("Error updating group. Please try again.");
      } else if (error.request) {
        console.error("Request error updating group:", error.request);
        setError("Error updating group. Please try again.");
      } else {
        console.error("Error updating group:", error.message);
        setError("An error occurred while updating the group. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const [isNotifyAppActive, setIsNotifyAppActive] = useState(false);
  const handleCheckboxNotifyAppChange = (event: any) => {
    const { checked } = event.target;
  
    // Update the groupInfo state
    setGroupInfo(prevState => ({
      ...prevState,
      notify_app: checked ? 1 : 0, // Update notify_email based on the checkbox state
    }));

    setIsNotifyAppActive(checked ? true : false)
  };


  const [isNotifyEmailActive, setIsNotifyEmailActive] = useState(false);
  const handleCheckboxNotifyEmailChange = (event: any) => {
    const { checked } = event.target;
  
    // Update the groupInfo state
    setGroupInfo(prevState => ({
      ...prevState,
      notify_email: checked ? 1 : 0, // Update notify_email based on the checkbox state
    }));

    setIsNotifyEmailActive(checked ? true : false)
  };
  

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="las la-bell" style={{ color: "orange" }}></i>
          {idAlarmGroup > 0 ? translate("Edit a group") : translate("Add a group")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row" style={{ marginTop: 10 }}>
            <Form.Group className="col-md-2 col-sm-2">
              <Form.Label>{translate('Group name')}</Form.Label>
            </Form.Group>
            <Form.Group className="col-md-10 col-sm-10">
              <Form.Control
                type="text"
                name="name_groupe_alarme"
                placeholder={translate('Group name')}
                value={groupInfo.name_groupe_alarme}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Form.Group className="col-md-2 col-sm-2">
              <Form.Label> {translate('Description')}</Form.Label>
            </Form.Group>
            <Form.Group className="col-md-10 col-sm-10">
              <Form.Control
                as="textarea"
                name="description"
                placeholder={translate('Description')}
                value={groupInfo.description}
                onChange={handleChange}
                rows={3}
              />
            </Form.Group>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Form.Group className="col-md-2 col-sm-2">
              <Form.Label>{translate("Notification Methods")}</Form.Label>
            </Form.Group>
            <Form.Group className="row col-md-10 col-sm-10">
              <div className="row">
                <div className="col-6 col-md-12">
                  <div className="checkbox" >
                    <input
                      type="checkbox"
                      id="notify_email"
                      name="notify_email"
                      value={groupInfo.notify_email}
                      checked={groupInfo.notify_email === 1 ? true : false}
                      onChange={handleCheckboxNotifyEmailChange}
                    />
                    <label style={{ paddingLeft: "5px" }}>{translate("Notify by email")}</label>
                  </div>
                  <SelectEmails
                    controlId="notify_mail_list"
                    name="notify_mail_list"
                    label={translate("Email")}
                    icon="at"
                    options={usersEmails}
                    valueType={usersEmails.filter(option => groupInfo.notify_mail_list.includes(option.value))}
                    onChange={handleEmailChange}
                    onClick={handleEmailChange}
                    isMulti
                    placeholder={translate("Choose Emails")}
                    isDisabled={!isNotifyEmailActive} 
                  />
                </div>
                <div className="col-6 col-md-12" style={{ marginTop: '30px' }}>
                  <div>
                    <div className="checkbox">
                      <Form.Check
                        id="notify_mail_type_0"
                        type="radio"
                        name="notify_mail_type_0"
                        value={0}
                        checked={groupInfo.notify_mail_type === 0 ? true : false}
                        onChange={handleNotifyMailTypeChange}
                        label="Alarm start only"
                        style={{ marginLeft: '10px', marginRight: '5px' }}
                        disabled={!isNotifyEmailActive} 
                      />
                    </div>

                    <div className="checkbox">
                      <Form.Check
                        id="notify_mail_type_1"
                        type="radio"
                        name="notify_mail_type_1"
                        value={1}
                        checked={groupInfo.notify_mail_type === 1 ? true : false} // Check this radio if state is "email"
                        onChange={handleNotifyMailTypeChange}
                        label="Start and end of alarm"
                        style={{ marginLeft: '10px', marginRight: '5px' }}
                        disabled={!isNotifyEmailActive} 
                      />
                    </div>
                  </div>  
                </div>
              </div>
              <div className="border-top my-4"></div>
              <div className="col-6 col-md-12" >
                <div className="checkbox" >
                  <input
                    type="checkbox"
                    name="notify_app"
                    id="notify_app"
                    value={groupInfo.notify_app}
                    checked={groupInfo.notify_app === 1 ? true : false}
                    onChange={handleCheckboxNotifyAppChange}
                  />
                  <label style={{ paddingLeft: "5px" }}>
                    {translate("Notify app users")}
                  </label>
                </div>
                <SelectUsers
                  controlId="user"
                  name="user"
                  label={translate("User")}
                  icon="user"
                  options={usersOptions}
                  valueType={usersOptions.filter(option => groupInfo.notify_user_app_list.includes(option.value))}
                  onChange={handleUserChange}
                  onClick={handleUserChange}
                  isMulti
                  placeholder={translate("Choose users")}
                  isDisabled={!isNotifyAppActive} 
                />
              </div>
              <div className="col-6 col-md-12" style={{ marginTop: '40px' }}>
                {checkboxItems.map(item => (
                  <Form.Group key={item.id} className="checkbox">
                    <Form.Check
                      type="checkbox"
                      id={item.id}
                      name={item.name}
                      value={item.value}
                      checked={groupInfo.notify_type_app_list.includes(item.value) ? true : false}
                      label={item.label}
                      style={{ marginLeft: '10px', marginRight: '5px' }}
                      onChange={handleCheckboxNotifyTypeAppListChange}
                      disabled={!isNotifyAppActive} 
                    />
                  </Form.Group>
                ))}
              </div>
            </Form.Group>
          </div>

          {error && <div className="text-danger">{error}</div>}
          <div className="text-center" style={{ marginTop: 20 }}>
            <Button variant="secondary" onClick={onHide} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : translate("Cancel")}
            </Button>
            <Button type="submit" variant="primary" style={{ marginLeft: 10, }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : idAlarmGroup > 0 ? translate("Edit") : translate("Add")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AlarmGroupModal;
