import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { formatToTimestamp, engineStat, engineStatClass, Distance, getAddressFromCoordinates } from '../../utilities/functions';
import { Row } from 'react-bootstrap';
import { useTranslate } from "../../hooks/LanguageProvider";
import { PropagateLoader } from 'react-spinners';


interface VehicleWidgetProps {
  id: number;
  matriculation: string;
  LAT: number;
  LON: number;
  SOG: number;
  COG: number;
  timestamp: string;
  enginestat: number;
  gpsdist: number;
  detail: string;
  PSN: string;
  nom_conducteur: string;
  nom_groupe: string;
  prenom_conducteur: string;
  onClose: (matriculation: any) => void;
  handleItineraryClick: (PSN: any) => void;
  handleDistanceSpeedClick: (PSN: any) => void;
  handleFuelTankClick: (PSN: any) => void;
  handleTemperatureDiagramClick: (PSN: any) => void;
}


const VehicleWidget: React.FC<VehicleWidgetProps> = ({ id, matriculation, timestamp, SOG, LAT, LON, enginestat, gpsdist, nom_conducteur, nom_groupe, prenom_conducteur, detail, onClose, PSN, handleItineraryClick, handleTemperatureDiagramClick, handleFuelTankClick, handleDistanceSpeedClick }) => {


  const { translate } = useTranslate();
  const [address, setAddress] = useState<string>(translate("None"));
  const [loading, setLoading] = useState<boolean>(true);

  const getAddress = async () => {
    if (LAT) {
      setLoading(true);
      try {
        const fetchedAddress = await getAddressFromCoordinates(LAT, LON);
        setAddress(fetchedAddress);
      } catch (error) {
        console.error("Error retrieving address", error);
        setAddress(translate("None"));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Card id={`v-${id.toString()}`} className={`map-card ${engineStatClass(enginestat, SOG)}`} style={{ width: '90%', marginBottom: "10px" }} onClick={(event) => { getAddress() }}  >
        <Card.Body style={{ padding: "0.25rem" }}>
          <Card.Title style={{ fontSize: "17px" }}> <img src={engineStat(enginestat, SOG, translate).iconState} alt="" />  {matriculation} <i className='las la-chevron-left return-to-list' onClick={(event) => { event.stopPropagation(); onClose(matriculation); }} ></i></Card.Title>
          <Card.Subtitle style={{ fontSize: "14px" }} className="mb-2 "> <i className="las la-clock map-icon-orange" data-rel="bootstrap-tooltip" title="Date & time"></i> {timestamp} </Card.Subtitle>
          <Card.Text style={{ marginBottom: "4px" }}><i className="las la-map-marker map-icon-orange" data-rel="bootstrap-tooltip" title="Address"></i> {LAT.toFixed(6)} , {LON.toFixed(6)}</Card.Text>
          <Row className='row'>
            <span className='col-6'>
              <p style={{ fontSize: "13px", margin: "0" }} className="text-muted">
                <i className="las la-tachometer-alt map-icon-orange" style={{ fontSize: "20px" }} data-rel="bootstrap-tooltip" title="Speed"></i>   {translate("Speed")}
              </p>
              <span>
                {SOG} km/h
              </span>
            </span>

            <span className='col-6'>
              <p style={{ fontSize: "13px", margin: "0" }} className="text-muted" >
                <i className="las lar la-road map-icon-orange" data-rel="bootstrap-tooltip" title="Distence"></i>   {translate("Odometer")}
              </p>
              <span>
                {Distance(gpsdist)}
              </span>
            </span>
          </Row>
          <Row className='row' style={{ display: detail, padding: '10px 0', transition: " box-shadow 0.3s, transform 0.3s" }}>

            {/* 
            <span className='col-6'>
              <p style={{ fontSize: "13px", margin: "0" }} className="text-muted" >
                <i className="las lar la-temperature-low map-icon-orange" data-rel="bootstrap-tooltip" title="Distence"></i> Temperature
              </p>
              <span>
                19 °C
              </span>
            </span> */}


            <span className='col-6'>
              <p style={{ fontSize: "13px", margin: "0" }} className="text-muted" >
                <i className="las lar la-user-friends map-icon-orange" data-rel="bootstrap-tooltip" title="Distence"></i>   {translate("Group")}
              </p>
              <span style={{ fontSize: "13px" }}>
                {nom_groupe ? nom_groupe : translate("None")}
              </span>
            </span>

            <span className='col-6'>
              <p style={{ fontSize: "13px", margin: "0" }} className="text-muted" >
                <i className="las lar la-tag  map-icon-orange" data-rel="bootstrap-tooltip" title="Distence"></i> {translate("Driver")}
              </p>
              <span style={{ fontSize: "13px" }}>
                {nom_conducteur ? (nom_conducteur + " " + prenom_conducteur) : translate("None")}
              </span>
            </span>

            <span className='col-12' style={{ margin: "5px 0" }}> 
              {loading ? (
                <p className="text-center" style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "13px", margin: "0" }} className="text-muted">
                    <PropagateLoader color={"#123abc"} loading={loading} size={8} />
                  </span>
                </p>
              ) : (
                <p style={{ fontSize: "13px", margin: "0" }} className="">
                  <i className="las la-home map-icon-orange" style={{ fontSize: "22px" }} data-rel="bootstrap-tooltip" title="Address"></i> {address}
                </p>
              )}
            </span>

            <h6>{translate("Report")} & {translate("Statistics")}</h6>
            <div className='col-6' style={{ cursor: 'pointer', textAlign: "center" }} onClick={() => handleItineraryClick(PSN)}>
              <p style={{ border: '1px solid rgb(221, 221, 221)', borderRadius: '5px', padding: '10px' }}>
                <i className="las la-Itinerary reconstitution" style={{ fontSize: '28px' }}></i>
                <img src="asset/images/icon-report/1.png" style={{ width: '32px', marginRight: '5px' }} alt="Icon" />
                <p style={{ margin: "0px", fontSize: "14px" }}>{translate("Itinerary reconstitution")}</p>
              </p>
            </div>

            <div className='col-6' style={{ cursor: 'pointer', textAlign: "center" }} onClick={() => handleDistanceSpeedClick(PSN)}>
              <p style={{ border: '1px solid rgb(221, 221, 221)', borderRadius: '5px', padding: '10px' }}>
                <i className="las la-Itinerary reconstitution" style={{ fontSize: '28px' }}></i>
                <img src="asset/images/icon-report/4.png" style={{ width: '32px', marginRight: '5px' }} alt="Icon" />
                <p style={{ margin: "0px", fontSize: "14px" }}>{translate("Distance diagram")}</p>
              </p>
            </div>

            <div className='col-6' style={{ cursor: 'pointer', textAlign: "center" }} onClick={() => handleFuelTankClick(PSN)}>
              <p style={{ border: '1px solid rgb(221, 221, 221)', borderRadius: '5px', padding: '10px' }}>
                <i className="las la-Itinerary reconstitution" style={{ fontSize: '28px' }}></i>
                <img src="asset/images/icon-report/5.png" style={{ width: '32px', marginRight: '5px' }} alt="Icon" />
                <p style={{ margin: "0px", fontSize: "14px" }}>{translate("Fuel Tank Diagram")}</p>
              </p>
            </div>

            <div className='col-6' style={{ cursor: 'pointer', textAlign: "center" }} onClick={() => handleTemperatureDiagramClick(PSN)}>
              <p style={{ border: '1px solid rgb(221, 221, 221)', borderRadius: '5px', padding: '10px' }}>
                <i className="las la-Itinerary reconstitution" style={{ fontSize: '28px' }}></i>
                <img src="asset/images/icon-report/19.png" style={{ width: '32px', marginRight: '5px' }} alt="Icon" />
                <p style={{ margin: "0px", fontSize: "14px" }}>{translate("Temperature Diagram")}</p>
              </p>
            </div>

          </Row>
        </Card.Body>
      </Card>
    </>
  );

};

export default VehicleWidget;


