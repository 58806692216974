import React, { useEffect, useState } from "react";
import { useTranslate } from "../../hooks/LanguageProvider";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getReportName } from "../../utilities/functions";
import ExcelJS, { Style } from "exceljs";
import { Table } from "react-bootstrap";

interface Report6Props {
  type_report?: string;
  turn?: string;
  id_report?: string;
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Report6: React.FC<Report6Props> = ({ type_report, turn, id_report }) => {
  const [reportData, setReportData] = useState<any>({});
  const { translate } = useTranslate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/Rapport6/${id_report}`);
        const data = await response.json();

        console.log("Données récupérées de l'API :", data);

        setReportData(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données API :",
          error
        );
        setReportData({
          success: false,
          message: "Erreur lors de la récupération des données API.",
        });
      }
    };

    fetchData();
  }, [type_report, turn, id_report]);

  const gsmlvlData =
    reportData.data && reportData.data.query1Result
      ? reportData.data.query1Result.map((item: any) => [
          new Date(item.datetime).getTime(),
          item.GSMLVL,
        ])
      : [];

  const combinedData =
    reportData.data && reportData.data.query1Result
      ? reportData.data.query1Result.map((item: any) => ({
          x: new Date(item.datetime).getTime(),
          y: [item.HDOP, item.GUS],
        }))
      : [];

  const optionsGSMLVL: Highcharts.Options = {
    chart: {
      //@ts-ignore
      zoomType: "x", // Activer le zoom horizontal
    },
    title: {
      text: translate("Diagramme du signal GSM"),
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%Y-%m-%d %H:%M}", // Format de la date et de l'heure
      },
    },
    yAxis: {
      title: {
        text: "GSM %",
      },
    },
    series: [
      {
        type: "column",
        name: translate("Niveau signal GSM"),
        data: gsmlvlData,
      },
    ],
  };

  const optionsHDOPGUS: Highcharts.Options = {
    chart: {
      //@ts-ignore
      zoomType: "x", // Activer le zoom horizontal
    },
    title: {
      text: translate("Diagramme Des satellites GPS"),
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%Y-%m-%d %H:%M}", // Format de la date et de l'heure
      },
    },
    yAxis: {
      title: {
        text: "PDOP & GUS",
      },
    },
    series: [
      {
        type: "column",
        name: translate("position dilution of precision"),
        data: combinedData.map((item: { x: number; y: number[] }) => [
          item.x,
          item.y[0],
        ]),
      },
      {
        type: "column",
        name: translate("Nombre Des satellites GPS"),
        data: combinedData.map((item: { x: number; y: number[] }) => [
          item.x,
          item.y[1],
        ]),
      },
    ],
  };

  return (
    <>

<div style={{ textAlign: "center", marginTop: "20px" }}>
<h5>
          {[
            `[${
              reportData.data && reportData.data.query2Result
                ? reportData.data.query2Result[0].type_report
                : "Nan"
            }] ${getReportName(
              reportData.data && reportData.data.query2Result
                ? reportData.data.query2Result[0].type_report
                : "Nan",
              translate
            )}`,
          ]}
        </h5>
</div>
<div style={{ marginTop: "20px", position: "relative" }}>
  <h5>
    <i
      className="las la-chart-bar"
      data-rel="bootstrap-tooltip"
      title="Increased"
    ></i>{" "}
     {translate("Rapport Du")}:{" "}
        {reportData.data && reportData.data.query2Result
          ? new Date(
              reportData.data.query2Result[0].date_debut
            ).toLocaleString()
          : "Nan"}
        {"     "}
        {translate("Au")}:{" "}
        {reportData.data && reportData.data.query2Result
          ? new Date(reportData.data.query2Result[0].date_fin).toLocaleString()
          : "Nan"}
  </h5>
</div>


    

      <Table>
        <thead className="bg-white text-uppercase">
          <tr className="ligth ligth-data">
            <th
              colSpan={8}
              style={{ borderBottom: "1px solid #ddd", textAlign: "left" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <span>{translate("Immatriculation du véhicule")}: </span>
                  <span>
                    {reportData.data && reportData.data.query2Result
                      ? reportData.data.query2Result[0].immatriculation_vehicule
                      : "Nan"}
                  </span>
                </div>
                <div style={{ flex: 1 }}>
                  <span>{translate("Turn Report")}: </span>
                  <span>
                    {" "}
                    {reportData.data && reportData.data.query2Result
                      ? reportData.data.query2Result[0].turn_report
                      : "Nan"}
                  </span>
                </div>
                <div style={{ flex: 0 }}>
                  <span>{translate("Date de création")}: </span>
                  <span>
                    {" "}
                    {reportData.data && reportData.data.query2Result
                      ? new Date(
                          reportData.data.query2Result[0].date_creation
                        ).toLocaleString()
                      : "Nan"}
                  </span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
      </Table>
      <div style={{ minHeight: "300px", border: "1px solid #000" }}>
        <HighchartsReact highcharts={Highcharts} options={optionsGSMLVL} />
        <HighchartsReact highcharts={Highcharts} options={optionsHDOPGUS} />
      </div>
    </>
  );
};

export default Report6;
