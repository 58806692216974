/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Dropdown, Table } from "react-bootstrap";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../hooks/LanguageProvider";
import LineChartComponent from "../components/Charts/Gpsactivity";
import { PropagateLoader } from "react-spinners";
import ExcelJS from "exceljs";
import MapModal from "../components/Map/MapModal";

import {
  engineStat,
  BarreReseau,
  ValidatePosition,
  getAddressFromCoordinates,
  GetAddressesFromCoords,
  formatDateForAlgeriaTimeZone,
} from "../utilities/functions";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export function Fleet() {
  interface Vehicle {
    id: number;
    immatriculation_vehicule: string;
    psn_dispositif: string;
    nom_groupe: string;
    nom_user: string;
    prenom_user: string;
    nom_conducteur: string;
    prenom_conducteur: string;
    TIMESTAMP: string;
    GPSDIST: number;
    GSMLVL: number | null;
    NST: number;
    ENGINESTAT: number;
    LAT: number;
    LON: number;
    vehicule_type: string;
    telephone_conducteur: string;
    SOG: number;
  }

  const { translate } = useTranslate();
  const [list_Fleet, setItems] = useState<Vehicle[]>([]);
  let [total, setTotal] = useState(0);
  let [limit, setSelectedLimit] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [address, setAddress] = useState<string>("");
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchOption, setSearchOption] = useState("PSN");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAllChecked, setSelectAllChecked] = useState(false); // État pour suivre si toutes les lignes sont sélectionnées
  const [showMapModal, setShowMapModal] = useState(false);
  const [markerCoordinates, setMarkerCoordinates] = useState({ LAT: 0, LON: 0, });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [typeSearch, setTypeSearch] = useState("ID Vehicle");
  const [selectedSearch, setSelectedSearch] = useState(translate("ID Vehicle"));
  const [type, setType] = useState(0);
  const [search, setSearch] = useState("");
  const [colum, setSortColum] = useState("0");
  const [sort, setSort] = useState("ASC");

  const [page, setPage] = useState<number>(1);

  const handleRowClick = async (rowId: string, lat: number, lon: number) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);

    if (expandedRow !== rowId) {
      try {
        setLoadingAddress(true); // Indicate the start of loading
        const address = await getAddressFromCoordinates(lat, lon);
        setAddress(address);
      } catch (error) {
        console.error("Error fetching address:", error);
      } finally {
        setLoadingAddress(false); // Indicate the end of loading, whether there was an error or not
      }
    }
  };



  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setPage(1);
    setSelectedLimit(newValue);
    const commentsFormServer = await getFleet(
      parseInt(newValue),
      1,
      search,
      type,
      colum,
      sort
    );
    setItems(commentsFormServer);
    window.scrollTo(0, 0);
  };



  const getFleet = async (
    limit: number,
    page: number,
    search: string,
    type: number,
    colum: string,
    sort: string) => {

    const bodyData = JSON.stringify({
      limit: limit,
      page: page,
      search: search,
      type: type,
      id_user: userID,
      colum: colum,
      sort: sort,
    });

    try {
      setLoading(true);

      const total_pages = await fetch(`${backendUrl}/api/fleet/totalpage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const totalPages = await total_pages.json();
      total = totalPages[0]["total"];
      setTotal(totalPages[0]["total"]);

      const vehicleResponse = await fetch(`${backendUrl}/api/fleet/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      if (!vehicleResponse.ok) {
        console.error("Error retrieving vehicles list");
        setLoading(false);
        return;
      }

      const data = await vehicleResponse.json();
      setPageCount(Math.ceil(total / limit));
      setItems(data);
      return data;
    } catch (error) {
      console.error(
        "Error retrieving data from table",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const userID = localStorage.getItem("userID");
  useEffect(() => {

    getFleet(limit, page, search, type, colum, sort);
  }, [userID, limit, search, type]);

  const handlePageClick = async (data: any) => {
    let page = data.selected + 1;
    // await getVehicles(limit, parseInt(page), search, type, colum, sort);
    await getFleet(limit, parseInt(page), search, type, colum, sort);
    setPage(page)
    window.scrollTo(0, 0)
  };



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //check box selection for excel
  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    const newSelectedRows = new Set(selectedRows);
    if (isChecked) {
      newSelectedRows.add(index);
    } else {
      newSelectedRows.delete(index);
    }
    setSelectedRows(newSelectedRows);
  };


  const handleSelectAllCheckboxChange = (isChecked: boolean) => {
    setSelectAllChecked(isChecked);
    const newSelectedRows = new Set<number>();
    if (isChecked) {

      list_Fleet.forEach((item, index) => {
        newSelectedRows.add(index);
      });
    } else {

      setSelectedRows(newSelectedRows);
      return;
    }

    setSelectedRows(newSelectedRows);
  };

  // function for export to excel file
  async function exportToExcel() {
    const selectedData = list_Fleet.filter((item, index) =>
      selectedRows.has(index)
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Fleet Data");

    // Define headers with style
    const headers = [
      { name: "Date Time", style: { font: { bold: true } } },
      { name: "Vehicle", style: { font: { bold: true } } },
      { name: "PSN", style: { font: { bold: true } } },
      { name: "Vehicles Groups", style: { font: { bold: true } } },
      { name: "User", style: { font: { bold: true } } },
      { name: "Driver", style: { font: { bold: true } } },
      { name: "Distance", style: { font: { bold: true } } },
      { name: "GSM", style: { font: { bold: true } } },
      { name: "State", style: { font: { bold: true } } },
      { name: "ENGINESTAT", style: { font: { bold: true } } },
      { name: "Speed", style: { font: { bold: true } } },
      { name: "Address", style: { font: { bold: true } } }, // Add Address column
    ];
    worksheet.addRow(headers.map((header) => header.name));
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "DDDDDD" }, // Gray color
    };

    // Get addresses for each location
    const locations = selectedData.map((item) => ({
      id: item.id,
      lat: item.LAT,
      lon: item.LON,
    }));
    const addresses = await GetAddressesFromCoords(locations);

    // Add data rows
    for (let i = 0; i < selectedData.length; i++) {
      const item = selectedData[i];
      const address = addresses[i].address; // Assuming the address is returned in the 'address' property

      const engineState =
        item["ENGINESTAT"] === 0
          ? "Engine off"
          : item["ENGINESTAT"] === 1 && item["SOG"] > 5
            ? "Engine running"
            : item["ENGINESTAT"] === 1
              ? "Engine on"
              : "Unknown";

      worksheet.addRow([
        formatDateForAlgeriaTimeZone(item["TIMESTAMP"]),
        item["immatriculation_vehicule"],
        item["psn_dispositif"],
        item["nom_groupe"],
        `${item["nom_user"]} ${item["prenom_user"]}`,
        `${item["nom_conducteur"]} ${item["prenom_conducteur"]}`,
        `${Math.floor(item["GPSDIST"] / 1000)} km`,
        item["GSMLVL"] !== null ? Number(item["GSMLVL"]).toFixed(0) + "%" : "0",
        item["NST"] === 1 ? "Valid position" : "Invalid position",
        engineState,
        `${item["SOG"]}`,
        address, // Add address to the row
      ]);
    }

    // Auto adjust column widths to fit the content
    worksheet.columns.forEach((column) => {
      column.width = Math.max(column.width ?? 0, 20); // Use 0 as the default width if column.width is undefined
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentDate = new Date();
    const dateString = currentDate.toISOString().split("T")[0]; // Get the date part of the ISO string
    const timeString = currentDate
      .toTimeString()
      .split(" ")[0]
      .replace(/:/g, "-"); // Get the time part of the ISO string and replace colons with dashes
    const fileName = `fleet_Repport_${dateString}_${timeString}.xlsx`;

    // Create download link and click it to trigger download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    handleSelectAllCheckboxChange(false);
  }
  const handleMapIconClick = (LAT: number, LON: number) => {
    setMarkerCoordinates({ LAT, LON });
    setShowMapModal(true);
  };

  let placeholderText = translate("Search by ");

  switch (searchOption) {
    case "PSN":
      placeholderText += translate("PSN");
      break;
    case "vehicule":
      placeholderText += translate("Vehicle");
      break;
    case "user":
      placeholderText += translate("User");
      break;
    case "conducteur":
      placeholderText += translate("Driver");
      break;
    default:
      placeholderText += "...";
  }


  const handleTypeSearch = (selectedValue: string) => {


    switch (selectedValue) {

      case translate("Matriculation"):
        setSelectedSearch(translate("Matriculation"))
        setType(0);
        break;
      case translate("PSN"):
        setSelectedSearch(translate("PSN"))
        setType(1);
        break;
      case translate("User"):
        setSelectedSearch(translate("User"))
        setType(2);
        break;
      case translate("Driver"):
        setSelectedSearch(translate("Driver"))
        setType(3);
        break;
      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSearch(translate(selectedValue));
    console.log("Selected value:", selectedValue);
  };

  const columns = [
    { key: "immatriculation_vehicule", label: translate("Matriculation") },
    { key: "psn_dispositif", label: translate("PSN") },
    { key: "nom_groupe", label: translate("Group") },
    { key: "nom_user", label: translate("User") },
    { key: "nom_conducteur", label: translate("User") },
    { key: "TIMESTAMP", label: translate("Driver") },
    { key: "GPSDIST", label: translate("Driver") },
    { key: "GSMLVL:", label: translate("Driver") }, 
  ];


  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    // await getVehicles(limit, page, newValue, type, colum, sort);

    await getFleet(limit, page, newValue, type, colum, sort);
  };

  const [selectedColumns, setSelectedColumns] = useState({
    immatriculation_vehicule: true,
    psn_dispositif: true,
    nom_groupe: true,
    nom_user: true,
    nom_conducteur: true,
    TIMESTAMP: true,
    GPSDIST: true,
    GSMLVL: true
  });


  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };




  const handleSortingColum = (currentColumn: string) => {

    sort === "DESC" ? setSort("ASC") : setSort("DESC");
    setSortColum(currentColumn);
    setPage(1)
    // getVehicles(limit, 1, search, type, colum, sort);
    getFleet(limit, 1, search, type, colum, sort);
  };


  const menuItems = [
    translate("Matriculation"),
    translate("PSN"),
    translate("User"),
    translate("Driver"),
  ];


  const handleResetSearch = async () => {
    setSearch("")

    await getFleet(limit, page, search, type, colum, sort)
  };

  type ColumnKey = 'immatriculation_vehicule' | 'psn_dispositif' | 'nom_groupe' | 'nom_user' | 'nom_conducteur' | 'TIMESTAMP' | 'GPSDIST' | 'GSMLVL';

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-6 d-flex">
          <h4>
            <i className="fa fa-light fa-chart-line"></i>
            {" "} {translate("Fleet view")} ({total})
          </h4>
        </div>

        <div className="float-right col-sm-6 col-md-6">
          <button
            className="btn btn-outline-info btn-generate-csv float-right"
            onClick={exportToExcel}
            disabled={selectedRows.size === 0}
          >
            <i className="las la-file-alt mr-3"></i>
            {translate("Export")}
          </button>
        </div>

      </div>
      <div className="row">
        <div
          className="col-md-4"
          style={{ margin: "0px 0px 10px 0px", padding: "10px" }}
        >
          <div className="input-group">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic" >
                <i
                  className="fas fa-chevron-down"
                  style={{ fontSize: "20" }}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {menuItems.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleTypeSearch(item)}
                    eventKey={item}
                    active={typeSearch === item}
                    className={typeSearch === item ? "select-active" : ""}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <input type="text" placeholder={` ${translate("Search by")} ${translate(typeSearch)}`} onChange={handleAdvancedSearch} className="form-control" />
            <Button
              variant="secondary"
              onClick={handleResetSearch}
              className="btn-reset"
            >
              <i className="las la-times" style={{ color: "#fff" }}></i>
            </Button>
          </div>
        </div>
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <label className="ml-auto">
            {translate("Show")}
            <select
              name="DataTables_Table_0_length"
              aria-controls="DataTables_Table_0"
              className="custom-select custom-select-sm form-control form-control-sm mr-2 ml-2"
              style={{ width: "66px" }}
              onChange={handleSelectChange}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </label>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="" id="dropdown-basic" title={translate("Display columns")}>
            <i className="las la-eye"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {columns.map((column) => (
              <Dropdown.Item key={column.key} as="button" style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns[column.key as ColumnKey]}
                  onChange={() => handleColumnChange(column.key )}
                />
                <span style={{ marginLeft: "10px" }}>
                  {column.label}
                </span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>
        <div className="row">
          <div className="table-responsive">
            <Table className="dataTable">
              <thead className="bg-white text-uppercase">
                <tr className="ligth ligth-data">
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={(event) =>
                        handleSelectAllCheckboxChange(event.target.checked)
                      }
                    />
                  </th>
                  {selectedColumns.immatriculation_vehicule && (
                    <th className="sorting" onClick={() => handleSortingColum("immatriculation_vehicule")}>
                      {translate("Vehicle")}
                    </th>
                  )}

                  {selectedColumns.psn_dispositif && (
                    <th className="sorting" onClick={() => handleSortingColum("psn_dispositif")}>
                      {translate("PSN")}
                    </th>
                  )}

                  {selectedColumns.nom_groupe && (
                    <th className="sorting" onClick={() => handleSortingColum("nom_groupe")}>
                      {translate("Vehicles Groups")}
                    </th>
                  )}

                  {selectedColumns.nom_user && (
                    <th className="sorting" onClick={() => handleSortingColum("nom_user")}>
                      {translate("User")}
                    </th>
                  )}

                  {selectedColumns.nom_conducteur && (
                    <th className="sorting" onClick={() => handleSortingColum("nom_conducteur")}>
                      {translate("Driver")}
                    </th>
                  )}

                  {selectedColumns.TIMESTAMP && (
                    <th className="sorting" onClick={() => handleSortingColum("TIMESTAMP")}>
                      {translate("Date Time")}
                    </th>
                  )}

                  {selectedColumns.GPSDIST && (
                    <th className="sorting" onClick={() => handleSortingColum("GPSDIST")}>
                      {translate("Distance")}
                    </th>
                  )}

                  {selectedColumns.GSMLVL && (
                    <th className="sorting" onClick={() => handleSortingColum("GSMLVL")}>
                      {translate("GSM")}
                    </th>
                  )}

                  <th>{translate('State')}</th>

                </tr>
              </thead>
              {loading ? (
                <tr>
                  <td colSpan={12}>
                    <div className="text-center">
                      <PropagateLoader
                        color={"#123abc"}
                        loading={loading}
                        size={20}
                      />
                    </div>
                  </td>
                </tr>
              ) : list_Fleet.length === 0 ? (
                <tr>
                  <td colSpan={12} className="text-center">
                    No vehicles available
                  </td>
                </tr>
              ) : (
                <>
                  {list_Fleet.map((item: any, index: number) => {
                    const rowId = `row_${index}`;
                    return (
                      <tbody key={item.id_vehicule} className="ligth-body">
                        <tr data-id={rowId}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.has(index)}
                              onChange={(event) =>
                                handleCheckboxChange(index, event.target.checked)
                              }
                            />
                          </td>
                          <td>
                            <div
                              className="d-flex align-items-center"
                              onClick={() =>
                                handleRowClick(rowId, item.LAT, item.LON)
                              }
                            >
                              <span
                                className="icon-chevron"
                                style={{
                                  background: "#FFff",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  fontSize: "20px",
                                }}
                              >
                                <i
                                  className={`las ${expandedRow === rowId
                                    ? "la-chevron-down"
                                    : "la-chevron-right"
                                    }`}
                                ></i>
                              </span>

                              <div>
                                {item["immatriculation_vehicule"]}
                                <p className="mb-0">
                                  <small>{item["vehicule_type"]}</small>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>{item["psn_dispositif"]}</td>
                          <td>{item["nom_groupe"]}</td>
                          <td>
                            {item["nom_user"]} {item["prenom_user"]}{" "}
                          </td>
                          <td>
                            {item["nom_conducteur"]} {item["prenom_conducteur"]}
                          </td>
                          <td>{formatDateForAlgeriaTimeZone(item["TIMESTAMP"])}</td>
                          <td>{Math.floor(item["GPSDIST"] / 1000)} km</td>

                          <td>
                            {item["GSMLVL"] !== null
                              ? item["GSMLVL"].toFixed(0)
                              : "0"}
                            %{""}
                            {item["GSMLVL"] !== null && (
                              <i
                                style={{
                                  color: BarreReseau(item["GSMLVL"])
                                    .split("color:")[1]
                                    .slice(0, -1),
                                }}
                                className={`las la-signal ${BarreReseau(
                                  item["GSMLVL"]
                                )}`}
                              ></i>
                            )}
                          </td>

                          <td>
                            {item["NST"] === 1 ? (
                              <i className={ValidatePosition(item["NST"])}></i>
                            ) : (
                              <i className={ValidatePosition(item["NST"])}></i>
                            )}
                            <img
                              src={
                                engineStat(
                                  item["ENGINESTAT"],
                                  item["SOG"],
                                  translate
                                ).iconState
                              }
                              alt=""
                              title={
                                engineStat(
                                  item["ENGINESTAT"],
                                  item["SOG"],
                                  translate
                                ).tooltipMessage
                              }
                            />
                            <i
                              className="las la-map-marker-alt"
                              onClick={() =>
                                handleMapIconClick(item.LAT, item.LON)
                              }
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                fontSize: "20px",
                                color: "red",
                              }}
                            ></i>
                          </td>
                        </tr>
                        {expandedRow === rowId && (
                          <tr>
                            <td colSpan={12}>
                              <div className="responsive bordered hover">
                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <td
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                        }}
                                        className={`h6 ${selectedHeader === "additionalInfo"
                                          ? "table-active"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          setSelectedHeader("additionalInfo")
                                        }
                                      >
                                        {translate("Additional information")}
                                      </td>
                                      <td
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                        }}
                                        className={`h6 ${selectedHeader === "gpsActivity"
                                          ? "table-active"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          setSelectedHeader("gpsActivity")
                                        }
                                      >
                                        {translate("Activate GPS")}
                                      </td>
                                    </tr>
                                  </thead>
                                </Table>
                                <Table className="overflow-auto">
                                  <tbody>
                                    {selectedHeader === "additionalInfo" && (
                                      <>
                                        <tr>
                                          <td colSpan={12}>
                                            {translate("Vehicle")}:
                                          </td>
                                          <td colSpan={12}>
                                            {item["immatriculation_vehicule"]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={12}>
                                            {translate("Vehicles Groups")}:
                                          </td>
                                          <td colSpan={12}>
                                            {item["nom_groupe"]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={12}>
                                            {translate("Phone")}:
                                          </td>
                                          <td colSpan={12}>
                                            {item["telephone_conducteur"]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={12}>
                                            {translate("Date Time")}:
                                          </td>
                                          <td colSpan={12}>
                                            {item["TIMESTAMP"]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={12}>
                                            {translate("Speed")}:
                                          </td>
                                          <td colSpan={12}>{item["SOG"]} km/h</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={12}>
                                            {translate("Address")}:
                                          </td>
                                          <td colSpan={12}>
                                            {loadingAddress
                                              ? "Loading address..."
                                              : address}
                                            <i
                                              className="las la-map-marker-alt"
                                              onClick={() =>
                                                handleMapIconClick(
                                                  item.LAT,
                                                  item.LON
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                              }}
                                            ></i>
                                          </td>
                                        </tr>
                                      </>
                                    )}

                                    {selectedHeader === "gpsActivity" && (
                                      <>
                                        <td colSpan={12}>
                                          <LineChartComponent
                                            psnDispositif={item["psn_dispositif"]}
                                          />
                                        </td>
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    );
                  })}
                </>
              )}

            </Table>
            <MapModal
              show={showMapModal}
              onClose={() => setShowMapModal(false)}
              lat={markerCoordinates.LAT}
              lng={markerCoordinates.LON}
            />
          </div>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <span>
                {translate("Displaying")} {list_Fleet.length} {translate("out of")}{" "}{total}
              </span>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
