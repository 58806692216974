// src/components/Dashboard/AddWidgetModal.tsx

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface AddWidgetModalProps {
  show: boolean;
  handleClose: () => void;
  handleAdd: (content: string, type: string) => void;
}

const AddWidgetModal: React.FC<AddWidgetModalProps> = ({ show, handleClose, handleAdd }) => {
  const [content, setContent] = useState<string>('');
  const [type, setType] = useState<string>('text');

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (content.trim() === '') return;
    handleAdd(content, type);
    setContent('');
    setType('text');
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un Nouveau Widget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formWidgetContent">
            <Form.Label>Contenu du Widget</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez le contenu du widget"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            />
            <Form.Text className="text-muted">
              Cela sera le titre ou le contenu principal de votre widget.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formWidgetType" className="mt-3">
            <Form.Label>Type de Widget</Form.Label>
            <Form.Control
              as="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="text">Texte</option>
              <option value="chart">Graphique</option>
              <option value="table">Tableau</option>
              {/* Ajoutez plus d'options selon vos besoins */}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={content.trim() === ''}
          >
            Ajouter le Widget
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddWidgetModal;
