import React, { useEffect, useState } from 'react';
import alertComponents from '../components/Alarm/AlertComponents';
import { useTranslate } from '../hooks/LanguageProvider';
import SelectReport from '../components/Vehicle/SelectReport';
import { Form } from 'react-bootstrap';
import { Bounce, toast } from 'react-toastify';
import { useNavigate, useParams, Link } from 'react-router-dom';
const backendUrl = process.env.REACT_APP_BACKEND_URL;



interface Dispositif {
    id: string;
    value: string;
}

interface AlarmInterface {
    id_alarme: string;
    id_user?: string;
    items: Dispositif[];
    alarm_type: number;
    id_type_alarme: number;
    alarm_metrics: string;
    alarm_name: string;
    id_alarm_group: number;
    date_creation?: string;
    date_modification?: string;
    name_groupe_alarme: string;
}

const Alarm: React.FC = () => {

    const [step, setStep] = useState(1);
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const { id_alarme } = useParams<{ id_alarme?: string }>();
    const [loading, setLoading] = useState(true);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [inputTypeSearchValue, setTypeSearchValue] = useState<number>(11);
    const [inputTypeSearchLabel, setTypeSearchLabel] = useState<string>(translate("Matriculation"));
    const [inputAdvenceSearch, setInputAdvancedSearch] = useState<string>("Vehicle");
    const [inputAdvenceSearchGroup, setInputAdvancedSearchGroup] = useState<string>("Group");
    const [itemsAlerts, setItemsAlerts] = useState<{ value: any; id: any }[]>([]);
    const [SelectReportState, setSelectReportState] = useState(false);
    const [SelectGroupState, setSelectGroupState] = useState(false);
    const [SearchByIcon, setSearchByIcon] = useState("filter");
    const [autocomplateStateIcon, setAutocompleteStateIcon] = useState("search");
    let [alarmFor, setAlarmFor] = useState("Vehicles");
    let [itemSearch, setItemSearch] = useState("");
    let [itemGroupSearch, setItemGroupSearch] = useState("");
    let [itemGroupName, setItemGroupName] = useState("");
    const id_user = localStorage.getItem("userID");
    const [autocompleteState, setAutocompleteState] = useState("");
    const [autocompleteGroupState, setAutocompleteGroupState] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [alarmSuggestions, setAlarmSuggestions] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean; }>({});
    const [selectAll, setSelectAll] = useState(false);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [groupsOptions, setGroupsOptions] = useState<any[]>([{ value: "Aucun", label: "Aucun" },]);
    const [group, setGroups] = useState<any[]>();

    const [Alarm, setAlarm] = useState<AlarmInterface>({
        id_user: id_user ?? undefined,
        id_alarme:id_alarme ?? "0",
        items: [],
        alarm_type: 0,
        id_type_alarme:0,
        alarm_metrics: "",
        alarm_name: '',
        id_alarm_group: 0,
        name_groupe_alarme: ""
    });

    const addAlarmParams = () => {
        // Ajoutez ici la logique pour passer à l'étape suivante et charger les paramètres de l'alarme
        setStep(2);
    };

    const wizardBack = () => {
        setStep(step - 1);
    };

    const checkStep3 = () => {
        // Ajoutez ici la logique pour vérifier les champs du formulaire de l'étape 3
        console.log('Checking step 3');
    };

    //Alaerts components
    const [selectedAlarm, setSelectedAlarm] = useState<number | null>(null);

    const handleAlarmTypeChange = (typeAlert: string) => {

        const value = parseInt(typeAlert, 10);
        setSelectedAlarm(value !== 0 ? value : null);
        console.log(value+"="+Alarm.id_type_alarme) 
        setAlarm(prevAlarm => ({
            ...prevAlarm,
            alarm_metrics: value===Alarm.id_type_alarme ? Alarm.alarm_metrics : createEmptyMetrics(value), 
            alarm_type: value
        }));

       
    };

    const createEmptyMetrics = (alarmType: number): any => {
       
        switch (alarmType) {
            case 48: // SpeedAlarm
                return {
                    type: "SpeedAlarm",
                    max_speed: Alarm.id_type_alarme==48 ? JSON.parse(Alarm.alarm_metrics).max_speed : 0 // Vous pouvez initialiser à 0 ou une valeur par défaut
                };
            case 2: // PoiAlarm
                return {
                    type: "PoiAlarm",
                    ids: Alarm.id_type_alarme==2 ? JSON.parse(Alarm.alarm_metrics).ids : []// Liste vide pour les identifiants
                }; 
            case 3: // AbsenceOfTransmission
            console.log(Alarm.id_type_alarme) 
                return {
                 
                    type: "AbsenceOfTransmission", 
                    hour:  Alarm.id_type_alarme==3 ?  JSON.parse(Alarm.alarm_metrics).hours : 0 // Valeur par défaut, peut être une chaîne vide
                };
            default:
                return {}; // Par défaut, retourne un objet vide 
        }
    };

    const updateMetrics = (newMetrics: any) => {
        setAlarm(prevAlarm => ({
            ...prevAlarm,
            alarm_metrics: newMetrics,
        }));
    };
 
    const handleAlarmMetricsChange = (newMetrics: any) => {
        setAlarm(prevAlarm => ({
            ...prevAlarm,
            alarm_metrics: newMetrics,
        }));
    };
    
    // Dans la méthode de rendu du composant Alarm
    let AlarmComponent = selectedAlarm !== null && alertComponents[selectedAlarm]
        ? React.createElement(alertComponents[selectedAlarm], { alarm: Alarm.alarm_metrics, onChange: handleAlarmMetricsChange })
        : null;


    const [searchTypeOptions, setsearchTypeOptions] = useState([
        { value: "11", label: "Matriculation" },
        { value: "12", label: "PSN" },
        { value: "14", label: "Vehicle group" },
        { value: "15", label: "User" },
    ]);

    const addReportBy = async (type: string) => {

        if (type == "1") { setAlarmFor("Vehicles") }
        else { setAlarmFor("Drivers") }

        try {
            setLoading(true);

            const res = await fetch(`${backendUrl}/api/search/report-for/${type}`, {
                mode: "cors",
            });
            const data = await res.json();

            const optionsArray = data["data"][0]["searchfor"].split(",");
            setsearchTypeOptions(
                optionsArray.map((option: any, index: any) => ({
                    value: type.toString() + (index + 1).toString(),
                    label: translate(option.trim()),
                }))
            );

            setTypeSearchValue(parseInt(type.toString() + "1"));
            console.log("Autocomplete: " + type);

            setTypeSearchLabel(translate(optionsArray[0]));
            setInputAdvancedSearch(translate(optionsArray[0]));

            setItemsAlerts([]);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const searchBy = async (option: any) => {
        setInputAdvancedSearch(translate(option.label));
        setTypeSearchLabel(translate(option.label));
        setTypeSearchValue(option.value);
        setItemsAlerts([]);
    };

    const getAutocomplete = async (search: string) => {

        setItemSearch(search);
        setAutocompleteState("");
        setAutocompleteStateIcon("spinner");

        try {
            if (search.trim() !== "") {
                const res = await fetch(
                    `${backendUrl}/api/search/${inputTypeSearchValue}/${search}/${id_user}`,
                    { mode: "cors" }
                );
                const data = await res.json();

                const getSuggestions = data.data.map((item: any) => {
                    let newItem: any = {};

                    if (inputTypeSearchValue == 11 || inputTypeSearchValue == 34) {
                        newItem.value = item.immatriculation_vehicule;
                        newItem.id = item.id_dispositif;
                    } else if (inputTypeSearchValue == 12) {
                        newItem.value = item.psn_dispositif;
                        newItem.id = item.id_dispositif;
                    } else if (inputTypeSearchValue == 14) {
                        newItem.value = item.nom_groupe;
                        newItem.id = item.id_groupe;
                    } else if (inputTypeSearchValue == 15) {
                        newItem.value = item.username_user;
                        newItem.id = item.id_user;
                    } else if (inputTypeSearchValue == 31 || inputTypeSearchValue == 51) {
                        newItem.value = item.nom_conducteur + " " + item.prenom_conducteur;
                        newItem.id = item.id_dispositif;
                    } else if (inputTypeSearchValue == 33) {
                        newItem.value = item.nom;
                        newItem.id = item.id_agence;
                    }

                    return newItem;
                });

                setSuggestions(getSuggestions);
                setAutocompleteStateIcon("search");

                getSuggestions.forEach((item: { value: any }) => {
                    updateCheckboxState(item.value);
                });



            } else {
                setAutocompleteStateIcon("search");
                setSuggestions([]);
            }
        } catch (error) {
            setAutocompleteStateIcon("search");
            setSuggestions([]);
        }
    };

    const getGroupsAlarm = async (name_groupe: string) => {
        setItemGroupSearch(name_groupe)
        setAutocompleteGroupState("")
        setAutocompleteStateIcon("spinner");

        try {

            const bodyData = JSON.stringify({
                id_user,
                name_groupe
            });

            const groupsData = await fetch(`${backendUrl}/api/alarm/group-options`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: bodyData,
                mode: "cors",
            });


            const data = await groupsData.json();

            const groupsOptionsData = data.map((group: any) => ({
                value: group.id_groupe_alarme,
                label: `${group.name_groupe_alarme || ""}`,
            }));

            setGroupsOptions(groupsOptionsData)
            setAlarmSuggestions(groupsOptionsData)


        } catch (error) {
            setAlarmSuggestions([]);
        }
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setSelectAll(checked);
        const updatedCheckboxStates: { [key: string]: boolean } = {};
        itemsAlerts.forEach((item: any) => {
            updatedCheckboxStates[item.value] = checked;
        });
        setCheckboxStates(updatedCheckboxStates);

        // Construire la liste d'objets à passer à onVehicleSelect
        const selectedAlarms: Dispositif[] = itemsAlerts.map(item => ({
            id: item.id.toString(), // Assuming item.id is of type number or string
            value: item.value.toString(), // Assuming item.value is of type number or string
        }));
        //   setCreateButtonDisabled(!checked); // Désactiver le bouton de création si aucune case n'est cochée

        setAlarm(prevAlarm => ({
            ...prevAlarm,
            items: selectedAlarms,
        }));

        if (Object.keys(Alarm.items).length === 0) {
            // Si Alarm.items est un objet vide
            setIsCreateButtonDisabled(true);
        } else {
            setIsCreateButtonDisabled(false);
        }
 

    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setCheckboxStates((prevState) => ({
            ...prevState,
            [name]: checked,
        }));

        const updatedCheckboxStates = {
            ...checkboxStates,
            [name]: checked,
        };

        const anySelected = Object.values(updatedCheckboxStates).some(value => value);
        setIsCreateButtonDisabled(!anySelected);

        const selectedAlarms = Object.keys(updatedCheckboxStates)
            .filter(key => updatedCheckboxStates[key])
            .map(value => ({ id: value.split('.')[0], value: value }));



        setAlarm(prevAlarm => ({
            ...prevAlarm,
            items: selectedAlarms
        }));

        console.log(Alarm);


    };

    const setItems = async (newItem: any) => {
        const itemExists = itemsAlerts.some((item) => item.value === newItem.value);

        if (!itemExists) {
            const updatedItemsAlarms = [...itemsAlerts, newItem];
            setItemsAlerts(updatedItemsAlarms);

            setAlarm(prevAlarm => ({
                ...prevAlarm,
                items: updatedItemsAlarms
            }));

            setAutocompleteState("autocomplete-state");
            setItemSearch(newItem.value);
            handleAlarmSelect(updatedItemsAlarms);
            updateCheckboxState(newItem);

        } else {
            setAutocompleteState("autocomplete-state");
            console.log("L'élément existe déjà dans la liste.");
        }

    };
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(true);

    const handleAlarmSelect = (alarms: { value: any, id: any; }[]) => {
        setItemsAlerts(alarms);
        setIsCreateButtonDisabled(alarms.length === 0);
    };

    const handleAlarmGroupChange = (newIdAlarmGroup: any) => {
        setItemGroupSearch(newIdAlarmGroup.label)
        setAutocompleteGroupState("autocomplete-state")
        setAlarm(prevAlarm => ({
            ...prevAlarm,
            id_groupe_alarme: newIdAlarmGroup.value, 
            name_groupe_alarme: newIdAlarmGroup.label 
        }));

        console.log(Alarm);
    };

    const handleAlarmNameChange = (newIdAlarmName: string) => {
        setItemGroupName(newIdAlarmName)
        setAlarm(prevAlarm => ({
            ...prevAlarm,
            alarm_name: newIdAlarmName
        }));

        console.log(Alarm);
    };

    const updateCheckboxState = (newItem: { value: any }) => {
        const updatedCheckboxStates = { ...checkboxStates };
        updatedCheckboxStates[newItem.value] = true; // Marquer comme sélectionné
        setCheckboxStates(updatedCheckboxStates);
    };


    const createAlarm = async () => {
        try {
            console.log('Form submitted', Alarm);

            const response = await fetch(
                `${backendUrl}/api/alarm/create`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(Alarm),
                    mode: 'cors',
                }
            );

            const alarmResponse = await response.json();
            console.log(alarmResponse);

            toast.success(translate("Alarm create successfully"), {
                position: "bottom-right",
                autoClose: 2400,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

             navigate("/alarms");

            // Traitez la réponse comme nécessaire ici

        } catch (error) {
            toast.warn(translate("Can't create Alarm"), {
                position: "bottom-right",
                autoClose: 2400,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

            console.error('Error creating alarm:', error);
            // Gérez les erreurs comme nécessaire ici
        }
    };


    const updateAlarm = async () => {
        try {
            console.log('Alarm rorm submitted', Alarm);

            const response = await fetch(
                `${backendUrl}/api/alarm/update`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(Alarm),
                    mode: 'cors',
                }
            );

            const alarmResponse = await response.json();
            console.log(alarmResponse);

            toast.success(translate("Alarm update successfully"), {
                position: "bottom-right",
                autoClose: 2400,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

            navigate("/alarms");

            // Traitez la réponse comme nécessaire ici

        } catch (error) {
            toast.warn(translate("Can't update alarm"), {
                position: "bottom-right",
                autoClose: 2400,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true, 
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

            console.error('Error update alarm:', error);
            // Gérez les erreurs comme nécessaire ici
        }
    };


    const getAlarm = async () => {
        try {
            const res = await fetch(`${backendUrl}/api/alarm/find/${id_alarme}`, {
                mode: "cors",
            });
    
            if (!res.ok) {
                console.error("Erreur lors de la récupération de l'alarme");
                return;
            }
    
            const data = await res.json();
            setAlarm(data); 
        
            setItemsAlerts(data.items);
            data.items.forEach((item: { value: any }) => {
                updateCheckboxState(item);
            });
    
            if (data.items.length == 0) {
                setIsCreateButtonDisabled(true);   
            } else {
                setIsCreateButtonDisabled(false);
            }
    
            setItemGroupName(data.name_alarme);
            setItemGroupSearch(data.name_groupe_alarme);
            setSelectedAlarm(data.id_type_alarme.toString());
            handleAlarmTypeChange(data.id_type_alarme.toString());
    
            // Mettre à jour les métriques spécifiques pour les types d'alarme connus
            if (data.id_type_alarme === 2 || data.id_type_alarme === 3 || data.id_type_alarme === 48) {
                updateMetrics(JSON.parse(data.alarm_metrics));
            } 
    
        } catch (error) {
            console.error("Erreur lors de la récupération de l'alarme", error);
        }
    };
    
    useEffect(() => {
        if (id_alarme) { 
            getAlarm();
            console.log(Alarm);  
        }
    }, []); // 

    const alarmOptions = [
        { value: "0", label: "-" },
        { value: "2", label: "A l'extérieur du POI dans l'horaire interdit" },
        { value: "3", label: "Absence de transmission" },
        // { value: "4", label: "Alarme accéléromètre (dispositif)" },
        // { value: "5", label: "Alarme authentification iButton (dispositif)" },
        // { value: "6", label: "Alarme brouillage GPS (dispositif)" },
        // { value: "7", label: "Alarme brouillage GSM (dispositif)" },
        // { value: "8", label: "Alarme CAN (dispositif)" },
        // { value: "9", label: "Alarme credit SIM" },
        // { value: "10", label: "Alarme groupe iButton (dispositif)" },
        // { value: "11", label: "Alarme I/O" },
        // { value: "12", label: "Alarme mode privé (dispositif)" },
        // { value: "13", label: "Alarme spécifique iButton (dispositif)" },
        // { value: "14", label: "Alimentation interrompue" },
        // { value: "15", label: "Batterie déchargé (dispositif)" },
        // { value: "16", label: "Capteur de mouvement (dispositif)" },
        // { value: "17", label: "Date/Heure" },
        // { value: "18", label: "Dépassement vitesse (dispositif)" },
        // { value: "19", label: "Erreur iButton (dispositif)" },
        // { value: "20", label: "Etat du contact moteur (dispositif)" },
        // { value: "21", label: "Etat du relais (dispositif)" },
        // { value: "22", label: "Etat panique (dispositif)" },
        // { value: "23", label: "Incrément du compteur d’événements EV1" },
        // { value: "24", label: "Incrément du compteur d’événements EV2" },
        // { value: "25", label: "Interface ou ecran Garmin déconnecté (dispositif)" },
        // { value: "26", label: "Moteur démarré dans l'horaire interdit" },
        // { value: "27", label: "Mouvement avec moteur arrêté (dispositif)" },
        // { value: "28", label: "Mémoire pleine (dispositif)" },
        // { value: "29", label: "Niveau du signal GPS" },
        // { value: "30", label: "Niveau du signal GSM" },
        // { value: "31", label: "Nombre de réinitialisations" },
        // { value: "32", label: "Odomètre CAN" },
        // { value: "33", label: "Odomètre GPS" },
        // { value: "34", label: "Points d'intérêts" },
        // { value: "35", label: "Relais activé" },
        // { value: "36", label: "Relais déconnecté (dispositif)" },
        // { value: "37", label: "Régime moteur CAN" },
        // { value: "38", label: "Réseau GSM inconnu" },
        // { value: "39", label: "Signal GPS invalide (dispositif)" },
        // { value: "40", label: "Sous alimentation (dispositif)" },
        // { value: "41", label: "Stationnement avec moteur démarré" },
        // { value: "42", label: "Stationnement avec moteur démarré (dispositif)" },
        // { value: "43", label: "Stationnement véhicule avec moteur arrêté (dispositif)" },
        // { value: "44", label: "Sur alimentation (dispositif)" },
        // { value: "45", label: "Température moteur CAN" },
        // { value: "46", label: "Trafic mobile journalier (dispositif)" },
        // { value: "47", label: "Trafic mobile mensuel (dispositif)" },
        { value: "48", label: "Vitesse" }
    ];



    return (
        <>
            <div id="content">
                <div id="add_alarm_wizard" className="wizard">
                    <ul className="steps">
                        <li data-target="#step1" className={step === 1 ? 'active' : ''}>
                            <span className="badge badge-info">1</span> Alarme pour <span className="chevron"></span>
                        </li>
                        <li className='ml-2 mr-2'><i className="las la-exchange-alt"></i></li>
                        <li data-target="#step2" className={step === 2 ? 'active' : ''}>
                            <span className="badge badge-info">2</span> Paramètres<span className="chevron"></span>
                        </li>
                    </ul>
                </div>
                <div className="" >
                    <form id="add_alarm_form" style={{ width: "100%", }}>
                        <input type="hidden" name="step" value={step} />
                        {step === 1 && (
                            <div className="step-pane active panel panel-default" id="step1" style={{ marginTop: '5px',  padding: '15px'  }}>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4" style={{ marginTop: '2px' }}>
                                            <div
                                                className="input-group-addon input-sm"
                                                style={{ paddingRight: "15px", paddingTop: "3px" }}
                                            >
                                                {translate("Add alarm for")}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <i className={`las la-file-alt icon-report`} style={{ padding: "0px 5px" }} />
                                                <select
                                                    id="add_report_for"
                                                    name="add_report_for"
                                                    onChange={(e) => addReportBy(e.target.value)}
                                                    className="form-control input-sm"
                                                >
                                                    <option value="1">{translate("Vehicles")}</option>
                                                    <option value="3">{translate("Drivers")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4" style={{ marginTop: '2px' }}>
                                            <SelectReport
                                                controlId="category_vehicule"
                                                label={translate("Search") + " type"}
                                                icon={SearchByIcon}
                                                options={searchTypeOptions}
                                                valueType={{
                                                    value: inputTypeSearchValue,
                                                    label: translate(inputTypeSearchLabel),
                                                }}
                                                onChange={searchBy}
                                                disabled={SelectReportState}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-4" style={{ marginTop: '2px' }}>

                                            <div
                                                className="input-group-addon input-sm"
                                                style={{ paddingRight: "15px", paddingTop: "3px" }}
                                            >
                                                {translate("Search by")}{" "}
                                            </div>
                                            <div>
                                                <div style={{ position: "relative" }}>
                                                    <div className="d-flex align-items-center">
                                                        <i
                                                            className={`las la-${autocomplateStateIcon} icon-report`}
                                                            style={{ padding: "0px 5px" }}
                                                        />
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`${translate(inputAdvenceSearch)}`}
                                                            className="me-2"
                                                            aria-label={`${translate(inputAdvenceSearch)}`}
                                                            onChange={(e) => getAutocomplete(e.target.value)}
                                                            value={itemSearch}
                                                            disabled={SelectReportState}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`autocomplete-items ${autocompleteState}`}
                                                        style={{ listStyleType: "none", padding: 0 }}
                                                    >
                                                        {suggestions &&
                                                            suggestions.map((suggestion: any, index: number) => (
                                                                <div key={index} onClick={(e) => setItems(suggestion)}>
                                                                    <p> {suggestion.value}</p>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ marginTop: "15px" }}>
                                            <div
                                                className="row"
                                                style={{ marginBottom: "15px" }}
                                            >
                                                <div className="col-md-12">
                                                    <div
                                                        className=""
                                                        style={{
                                                            padding: "3px 9px",
                                                            border: "1px solid #ccc",
                                                            borderBottom: "0px",
                                                        }}
                                                    >
                                                        <input
                                                            id="mf_cb"
                                                            name="selectAll"
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        />
                                                        <span id="mf_title" style={{ fontWeight: "bold" }}>
                                                            {" "}
                                                            <span id="nbrofvehicule">({itemsAlerts.length})</span>
                                                            {translate(alarmFor)}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="table-responsive"
                                                        id="scroll"
                                                        style={{
                                                            border: "1px solid #ccc",
                                                           
                                                            padding: "5px 5px",
                                                            marginBottom: "15px",
                                                            overflow: "auto;",
                                                            display: "block;",
                                                            height: "333px",
                                                        }}
                                                    >
                                                        <ul
                                                            id="mf_list"
                                                            style={{
                                                                width: "100%",
                                                                padding: "5px",
                                                                listStyleType: "none",
                                                            }}
                                                        >
                                                            {itemsAlerts && itemsAlerts.map((itemsAlert: any, index: number) => (
                                                                <div key={index}>
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={handleCheckboxChange}
                                                                        checked={checkboxStates[itemsAlert.value]}
                                                                        value={itemsAlert.value}
                                                                        name={itemsAlert.value}
                                                                    />
                                                                    { } {itemsAlert.value}
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link
                                        to={`/alarms`}
                                        className="btn btn-outline-secondary  mr-2"
                                        data-toggle="Cancel"
                                        data-placement="top"
                                        title="Cancel"
                                    >
                                        <i className="fas fa-chevron-left" style={{ fontSize: "1.2em" }}></i>
                                        {translate("Cancel")}
                                    </Link>
                                    <button
                                        className={`btn btn-outline-secondary  mr-2 ${isCreateButtonDisabled ? 'disabled' : ''}`}
                                        type="button"
                                        name="set_params"
                                        onClick={addAlarmParams}
                                    >
                                        <i className="fas fa-chevron-right"></i>  {translate("Next")}
                                    </button>
                                </div>
                            </div>
                        )}
                        {step === 2 && (
                            <div className="step-pane panel panel-default" id="step2" style={{ marginTop: '5px',padding: '8px', border:"1px solid #ddd" }}>
                                <div className="panel-body">
                                    <div className="row" id="criteria" style={{ display: 'block' }}>
                                        <div className="col-md-12 col-sm-12" style={{ marginTop: '2px' }}>
                                            <div className="input-group">
                                                <div className="col-md-4 col-sm-3" style={{ marginTop: '2px' }}>
                                                    <span className="input-group-addon" style={{ paddingRight: " 10px" }}>Alarme type:</span>
                                                    <div className="input-group">
                                                        <div className="d-flex align-items-center">
                                                            <i className={`las la-bell  ${autocomplateStateIcon} icon-report`} style={{ padding: "0px 5px" }} />
                                                            <select className="form-control input-sm" value={selectedAlarm || "0"} name="alarm_type" onChange={(e) => { handleAlarmTypeChange(e.target.value) }}>
                                                                {alarmOptions.map(option => (
                                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-3" style={{ marginTop: '2px' }}>
                                                    <span className="input-group-addon" style={{ paddingRight: " 10px" }}>{translate("Alarm name")}:</span>
                                                    <div className="">
                                                        <div className="d-flex align-items-center">
                                                            <i className={`las la-edit ${autocomplateStateIcon} icon-report`} style={{ padding: "0px 5px" }} />
                                                            <input className="form-control input-sm" type="text" name="name" placeholder='Name your alarm' value={itemGroupName} onInput={checkStep3} onChange={(e) => { handleAlarmNameChange(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-3' style={{ marginTop: '2px' }}>
                                                    <span className="input-group-addon" style={{ paddingRight: " 10px" }}>{translate("Group alarm")}: </span>
                                                    <div className="">
                                                        <div className="d-flex align-items-center">
                                                            <i className={`las la-users-cog ${autocomplateStateIcon} icon-report`} style={{ padding: "0px 5px" }} />
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={`${translate("Set group of alarm")}`}
                                                                className="me-2 w-100"
                                                                aria-label={`${translate(inputAdvenceSearchGroup)}`}
                                                                onChange={(e) => getGroupsAlarm(e.target.value)}
                                                                value={itemGroupSearch}
                                                                disabled={SelectGroupState}

                                                            />
                                                        </div>
                                                        <div
                                                            className={`autocomplete-items ${autocompleteGroupState}`}
                                                            style={{ listStyleType: "none", padding: 0 }}
                                                        >

                                                            {alarmSuggestions &&
                                                                alarmSuggestions.map((alarmSuggestion: any, index: number) => (
                                                                    <div key={index} onClick={(e) => { handleAlarmGroupChange(alarmSuggestion) }}>
                                                                        <p> {alarmSuggestion.label}</p>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12" style={{ margin: '30px 0' }}>  {AlarmComponent }</div> 
                                        </div>
                                        <div className="col-md-3 col-sm-3" id="opts_cell" style={{ display: 'none', marginTop: '2px' }}></div>
                                        <div className="col-md-3 col-sm-3" id="extra_cell_inline" style={{ display: 'none', marginTop: '2px' }}></div>
                                    </div>
                                    <div className="row" style={{ display: 'none' }}>
                                        <div className="col-md-12" id="extra_cell" style={{ marginTop: '10px' }}></div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <button className="btn btn-outline-secondary  mr-2" type="button" name="back" onClick={wizardBack}>
                                        <i className="fas fa-chevron-left" style={{ fontSize: "1.2em", color: "#ff7e41" }}></i> {translate("Previous")}
                                    </button>
                                    <button
                                        className="btn btn-outline-secondary  mr-2"
                                        type="button"
                                        onClick={() => {
                                            setButtonClicked(true); 
                                  
                                                id_alarme ? updateAlarm() : createAlarm();
                                           
                                        }}
                                        disabled={buttonClicked}
                                    >
                                        <i className="las la-bell" style={{ fontSize: "1.2em", color: "#ff7e41" }}></i>
                                        {id_alarme ? translate("Update Alarm") : translate("Create Alarm")}
                                    </button>

                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Alarm; 
