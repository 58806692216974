import React, { useState, useEffect, useRef } from 'react';
import ChatInput from './ChatInput';
import { Dropdown, Button } from 'react-bootstrap';
import io from 'socket.io-client';
import ChatContacts from './ChatContacts';
import { useParams } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { getMessageDayStatus } from '../../utilities/functions';

interface Message {
  id_message?: number;
  id_ticket?: number;
  id_sender?: number;
  id_received: number;
  content: string[];
  time: string;
  is_right: boolean;
}

interface Contact {
  id_user: number;
  nom_user: string;
  prenom_user: string;
  username_user: string;
  position: string;
  phone_number: string;
  email_user: string;
  status: string;
}


const ChatHistory: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  const id_user = parseInt(localStorage.getItem("userID") ?? "0");

  const params = useParams<{ id_received?: string, id_ticket?: string }>();

  const id_ticket = parseInt(params.id_ticket ?? "0");

  let id_received = parseInt(params.id_received ?? "0");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [contact, setContact] = useState<Contact | undefined>(undefined);
  const [isSidebarRightOpen, setIsSidebarRightOpen] = useState(false);
  const [loading, setLoading] = useState(true);


  const chatEndRef = useRef<HTMLDivElement>(null);
  const socket = io("https://geotrackin.com:8050", {
    transports: ['websocket'],
    withCredentials: true,
  });

  useEffect(() => {

    if (id_ticket) {
      console.log("id_ticket: " + id_ticket);
      getMessages(id_ticket);
    }

 


  }, []);



  useEffect(() => {


    socket.on('connect', () => {
      console.log('Successfully connected to the server');
      socket.emit('registerUser', id_user);
    });


    // Recevoir des messages du serveur
    socket.on('receive_message', (newMessage: Message) => {
      newMessage = { ...newMessage, is_right: false};

      // Check if the message is NOT from the current user
      if (newMessage.id_sender && newMessage.id_sender !== id_user) {
        setMessages(prevMessages => [...prevMessages, newMessage]);
      }
    });

    return () => {
      socket.off('receive_message');
    };
  }, [ id_user, socket]);


  useEffect(() => {
    // Scroll down when messages change
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = (content: string) => {
    let newMessage: Message = {
      content: [content],
      time: new Date().toISOString(),
      is_right: false,
      id_sender: id_user ?? 0,
      id_received: id_received ?? 0, 
    };


    console.log(newMessage)

    socket.emit('send_private_message', newMessage);
    newMessage = { ...newMessage, is_right: true };
    setMessages([...messages, newMessage]);
    socket.emit('message', { ...newMessage, contact });
  };

  const handleContactClick = (contact: Contact) => {
    if (contact) {
      console.log(contact.id_user)
      id_received = contact.id_user;
      setContact(contact);
    }
  };


  const toggleSidebarRight = () => {
    setIsSidebarRightOpen(!isSidebarRightOpen);
  };

  const getMessages = async (
    id_ticket: number
  ) => {
    try {

      setLoading(true);

      const bodyData = JSON.stringify({
        id_ticket,
      });

      const messagesResponse = await fetch(
        `${backendUrl}/api/chat/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const data: Message[] = await messagesResponse.json();

      console.log("data");
      console.log(data);

      setMessages(data);


      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <style type="content/css">
        {`
          .dropdown-toggle::after {
            display: none !important;
          }
        `}
      </style>
      <ChatContacts handleContactClick={handleContactClick} id_contact={id_user} />
      <div className="col app-chat-history" style={{ padding: "0" }}>
        <div className="chat-history-wrapper">
          <div className="chat-history-header border-bottom" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex overflow-hidden align-items-center">
                <i className="ti ti-menu-2 ti-lg cursor-pointer d-lg-none d-block me-4"></i>
                {contact?.nom_user ? (
                  <div className="d-flex align-items-center" onClick={toggleSidebarRight}>
                    {contact.id_user && (
                      <div className="flex-shrink-0 avatar avatar-online">
                        <img
                          src={`../asset/images/avatars/${contact.id_user}.png`}
                          alt="Avatar"
                          className="rounded-circle"
                          style={{ height: "41px" }}
                        />
                      </div>
                    )}
                    <div className="chat-contact-info flex-grow-1 ms-4">
                      <h6 className="m-0 fw-normal">{contact.nom_user}</h6>
                      {contact.status && (
                        <small className="user-status content-body">{contact.status}</small>
                      )}
                    </div>
                  </div>
                ) : (
                  <h6 className="m-0 fw-normal">{"..."}</h6>
                )}

              </div>
              <div className="d-flex align-items-center">
                <i className="las la-phone-alt cursor-pointer" style={{
                  transition: 'background-color 0.3s ease',
                  padding: '10px',
                  borderRadius: '50%',
                  fontSize: '20px'
                }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}> </i>
                <i className="las la-video cursor-pointer " style={{
                  transition: 'background-color 0.3s ease',
                  padding: '10px',
                  borderRadius: '50%',
                  fontSize: '20px'
                }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}></i>
                <i className="las la-search" style={{
                  transition: 'background-color 0.3s ease',
                  padding: '10px',
                  borderRadius: '50%',
                  fontSize: '20px'
                }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}></i>

                <Dropdown>
                  <Dropdown.Toggle
                    as={Button}
                    variant="light"
                    className="icon-wrapper"
                    style={{
                      padding: '10px',
                      borderRadius: '50%',
                      fontSize: '20px',
                      border: 'none'
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                  >
                    <i className="las la-ellipsis-v"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    <Dropdown.Item href="#">View Contact</Dropdown.Item>
                    <Dropdown.Item href="#">Mute Notifications</Dropdown.Item>
                    <Dropdown.Item href="#">Block Contact</Dropdown.Item>
                    <Dropdown.Item href="#">Clear Chat</Dropdown.Item>
                    <Dropdown.Item href="#">Report</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="chat-history-body" style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
           
          }}>
            <ul className="list-unstyled chat-history" style={{ height: "100%" }}>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <p>
                    <PropagateLoader color={"#123abc"} loading={loading} size={20} />
                  </p>
                </div>
              ) : messages.length > 0 ? (
                messages.map((message) => (
                  <li key={message.id_message} className={`chat-message ${message.is_right ? 'chat-message-right' : 'chat-message-left'}`} style={{ marginBottom: "0px" }}>
                    <div className="d-flex overflow-hidden">
                      {!message.is_right && (
                        <div className="user-avatar flex-shrink-0">
                          <div className="avatar avatar-sm">
                            <img src={`../asset/images/avatars/${message.id_sender}.png`} alt="Avatar" className="rounded-circle" style={{ height: "41px" }} />
                          </div>
                        </div>
                      )}
                      <div className="chat-message-wrapper flex-grow-1" style={{ margin: "0 15px" }}>
                        <div className={`chat-message-content`}>
                          <p className={`mb-1 ${message.is_right ? 'is-right' : 'is-left'}`}>
                            {message.content}
                          </p>
                        </div>
                        <div className={`content-${message.is_right ? 'end' : 'start'} content-muted mt-1`}>
                          {message.is_right ? <i className={`ti ti-checks ti-16px me-1 content-success`}></i> : ""}
                          <small style={{ color: "#000" }}>{<i className="las la-check-double me-1"></i>} {getMessageDayStatus(message.time)}</small>
                        </div>
                      </div>
                      {message.is_right && (
                        <div className="user-avatar flex-shrink-0">
                          <div className="avatar">
                            <img src={`../asset/images/avatars/${message.id_sender}.png`} alt="Avatar" className="rounded-circle" style={{ height: "41px" }} />
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))
              ) : (
                <div className="no-messages d-flex justify-content-center align-items-center" style={{ textAlign: "center", flexDirection: "column", height: "100% " }}>
                  <span>No messages available</span>
                </div>
              )}
            </ul>

            <div ref={chatEndRef} />
          </div>

          <div className="chat-input" style={{ margin: "0 30px" }}>
            <ChatInput onSendMessage={handleSendMessage} />
          </div>
        </div>
        <div className={`col app-chat-sidebar-right app-sidebar overflow-hidden ${isSidebarRightOpen ? 'sidebar-right-open' : ''}`} id="app-chat-sidebar-right" style={{ background: "#fff", border: "1px solid #ddd" }}>
          <div className="sidebar-header d-flex flex-column justify-content-center align-items-center flex-wrap px-6 pt-12" onClick={toggleSidebarRight} style={{ margin: "15px 0", height: "108px !important" }}>
            <div className="avatar avatar-xl avatar-online chat-sidebar-avatar" onClick={toggleSidebarRight}>
              <img src="../asset/images/avatars/4.png" alt="Avatar" className="rounded-circle" style={{ height: '41px' }} />
            </div>
            <h5 className="mt-4 mb-0">Felecia Rower</h5>
            <span>NextJS Developer</span>
            <i className="ti ti-x ti-lg cursor-pointer close-sidebar" onClick={toggleSidebarRight}></i>
          </div>
          <div className="sidebar-body p-6 pt-0">
            <div className="my-6">
              <p className="content-uppercase mb-1 content-muted">About</p>
              <p className="mb-0">
                It is a long established fact that a reader will be distracted by the readable content.
              </p>
            </div>
            <div className="my-6">
              <p className="content-uppercase mb-1 content-muted">Personal Information</p>
              <ul className="list-unstyled d-grid gap-4 mb-0 ms-2 py-2 content-heading">
                <li className="d-flex align-items-center">
                  <i className="ti ti-mail ti-md"></i>
                  <span className="align-middle ms-2">josephGreen@email.com</span>
                </li>
                <li className="d-flex align-items-center">
                  <i className="ti ti-phone-call ti-md"></i>
                  <span className="align-middle ms-2">+1(123) 456 - 7890</span>
                </li>
                <li className="d-flex align-items-center">
                  <i className="ti ti-clock ti-md"></i>
                  <span className="align-middle ms-2">Mon - Fri 10AM - 8PM</span>
                </li>
              </ul>
            </div>
            <div className="my-6">
              <p className="content-uppercase content-muted mb-1">Options</p>
              <ul className="list-unstyled d-grid gap-4 ms-2 py-2 content-heading">
                <li className="cursor-pointer d-flex align-items-center">
                  <i className="ti ti-badge ti-md"></i>
                  <span className="align-middle ms-2">Add Tag</span>
                </li>
                <li className="cursor-pointer d-flex align-items-center">
                  <i className="ti ti-star ti-md"></i>
                  <span className="align-middle ms-2">Important Contact</span>
                </li>
                <li className="cursor-pointer d-flex align-items-center">
                  <i className="ti ti-photo ti-md"></i>
                  <span className="align-middle ms-2">Shared Media</span>
                </li>
                <li className="cursor-pointer d-flex align-items-center">
                  <i className="ti ti-trash ti-md"></i>
                  <span className="align-middle ms-2">Delete Contact</span>
                </li>
                <li className="cursor-pointer d-flex align-items-center">
                  <i className="ti ti-ban ti-md"></i>
                  <span className="align-middle ms-2">Block Contact</span>
                </li>
              </ul>
            </div>
            <div className="d-flex mt-6">
              <button className="btn btn-danger w-100">
                Delete Contact<i className="ti ti-trash ti-16px ms-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
