import React, { useState, ChangeEvent, FC, useEffect, useRef } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { extractObjectName } from '../../../utilities/functions';

interface Alarm {
  searchByPoi?: string;
  searchFieldPoi?: string;
  searchValuePoi?: string;
  subclass?: string;
  totalPoints?: number;
  ids?: number[];
}

interface PoiAlarmProps {
  alarm: any;
  onChange: (newMetrics: Alarm) => void;
}

interface PoiResult {
  idgeof: number;
  codegeof?: string;
  namegeof: string;
  descriptiongeof?: string;
  attributesgeof?: string;
  areageof: string;
  datecreategeof: string;
  userid: number;
  intrash: number;
  etatgeof?: string;
  numPoints?: number;
  classgeof?: string;
  typegeof?: string;
}

const PoiAlarm: FC<PoiAlarmProps> = ({ alarm, onChange }) => {
  const [searchByPoi, setSearchByPoi] = useState('0');
  const [searchFieldPoi, setSearchFieldPoi] = useState('');
  const [searchValuePoi, setSearchValuePoi] = useState('');
  const [subclass, setSubclass] = useState('');
  const [totalPoints, setTotalPoints] = useState(0);
  const [poiResults, setPoiResults] = useState<PoiResult[]>([]);
  const [pois, setPois] = useState<PoiResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDiv, setShowDiv] = useState(false);
  const id_user = localStorage.getItem("userID");
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (alarm) {
      setSearchByPoi(alarm.searchByPoi || '0');
      setSearchFieldPoi(alarm.searchFieldPoi || '');
      setSearchValuePoi(alarm.searchValuePoi || '');
      setSubclass(alarm.subclass || '');
      setTotalPoints(alarm.totalPoints || 0);
    }
  }, [alarm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setShowDiv(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const loadSearchPois = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSearchByPoi(value);
    onChange({ ...alarm, searchByPoi: value });
    // Hide the div when changing search criteria
    if (!searchFieldPoi.trim()) {
      setShowDiv(false);
    }
  };

  const handleSearchFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchFieldPoi(value);
    onChange({ ...alarm, searchFieldPoi: value });
    setSearchValuePoi(value);
    searchPois(value);
  };

  const searchPois = async (searchValue: string) => {
    setLoading(true);
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/api/search/${searchByPoi}/${searchValue}/${id_user}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const POIS = await response.json();
      const result: PoiResult[] = POIS.data;
      setPoiResults(result);
      setTotalPoints(result.length);
      
      // Show or hide the div based on POI results and search field
      if (searchValue.trim() === '') {
        setShowDiv(false);
      } else {
        setShowDiv(result.length > 0 || loading);
      }
    } catch (error) {
      console.error('Error fetching POIs:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPois = async () => {
    setLoading(true);
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/api/poi/finds`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_pois: alarm.ids,
          id_user: id_user,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result: PoiResult[] = await response.json();
      setPois(result);
    } catch (error) {
      console.error('Error fetching POIs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPois();
  }, [alarm.ids, id_user]);

  const addPoiToList = (poi: PoiResult) => {
    // Check if the POI is already in the list
    if (!pois.find(p => p.idgeof === poi.idgeof)) {
      const updatedPois = [...pois, poi];
      setPois(updatedPois);
      
      // Update the metrics with the new list of POIs
      onChange({
        ...alarm,
        ids: updatedPois.map(p => p.idgeof), // Update the ids with the new POI ids
        totalPoints: updatedPois.length // Update total points
      });
    }
  };

  const handlePoiClick = (poi: PoiResult) => {
    addPoiToList(poi);
    // Optionally hide the div after selecting a POI
    setShowDiv(false);
  };

  return (
    <div className="col-md-12" id="extra_cell" style={{ marginTop: '10px' }}>
      <div className="row" id="alarm_4_2">
        <div className="col-md-3 col-sm-3" style={{ marginTop: '2px' }}>
          <div className="input-group">
            <span className="input-group-addon">Recherche par</span>
            <select
              className="form-control input-sm"
              name="search_by_poi"
              value={searchByPoi}
              onChange={loadSearchPois}
            >
              <option value="0">-</option>
              <option value="36">Nom</option>
              <option value="37">Code</option>
              <option value="38">Type</option>
              <option value="39">Toutes</option>
            </select>
          </div>
        </div>
        <div className="col-md-3 col-sm-3" id="search_box_poi" style={{ marginTop: '2px' }}>
          <div className="input-group">
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-search"></span>
            </span>
            <input
              className="form-control input-sm ui-autocomplete-input"
              type="text"
              id="search_field_poi"
              name="search_field_poi"
              value={searchFieldPoi}
              onChange={handleSearchFieldChange}
              autoComplete="off"
            />
            <input type="hidden" name="search_value_poi" value={searchValuePoi} disabled />
          </div>
        </div>
        <div className="col-md-2 col-sm-2" style={{ marginTop: '2px' }}>
          <span>Points totaux: </span>
          <span id="total_points">{totalPoints}</span>
        </div>
        <div className="col-md-1 col-sm-1" style={{ marginTop: '2px' }}>
          <a
            className="btn btn-default btn-sm pull-right"
            href="/poi/add"
            title="Ajouter"
            data-toggle="tooltip"
            target="_blank"
          >
            <i className="fas fa-map-pin"></i>
          </a>
        </div>
      </div>
      {showDiv && (
        <div className="row">
          {loading ? (
            <div className="col-md-12" style={{ marginTop: '5px' }}>
              <div className="overlay" style={{ display: 'none' }}></div>
              <div
                id="alarm_4"
                className='autocomplete-items'
                style={{ border: '1px solid #ccc', overflow: 'auto', backgroundColor: 'white', height: '250px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <p>
                  <PropagateLoader color={"#123abc"} loading={loading} size={20} />
                </p>
              </div>
            </div>
          ) : poiResults.length > 0 ? (
            <div className="col-md-12" style={{ marginTop: '5px' }}>
              <div className="overlay" style={{ display: 'none' }}></div>
              <div
                id="alarm_4"
                className='autocomplete-items'
                style={{ border: '1px solid #ccc', overflow: 'auto', backgroundColor: 'white', height: '250px', paddingLeft: '5px' }}
              >
                <table id="poi_table" style={{ width: '90%' }}>
                  <thead>
                    <tr>
                      <th>ID POI</th>
                      <th>Nom</th>
                      <th>Code</th>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poiResults.map((poi) => (
                      <tr key={poi.idgeof} style={{ cursor: "pointer"}} onClick={() => handlePoiClick(poi)}>
                        <td>{poi.idgeof}</td>
                        <td>{poi.namegeof}</td>
                        <td>{poi.codegeof}</td>
                        <td>{extractObjectName(poi.areageof)}</td>
                        <td>{poi.descriptiongeof}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : searchFieldPoi.trim() ? (
            <div className="col-md-12" style={{ marginTop: '5px' }}>
              <div className="overlay" style={{ display: 'none' }}></div>
              <div
                id="alarm_4"
                className='autocomplete-items'
                style={{ border: '1px solid #ccc', overflow: 'auto', backgroundColor: 'white', height: '250px', paddingLeft: '5px' }}
              >
                <p>Aucun POI trouvé</p>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className="col-md-12" style={{ marginTop: '5px' }}>
        <div className="overlay" style={{ display: 'none' }}></div>
        <div style={{ border: '1px solid #ccc', overflow: 'auto', backgroundColor: 'white', height: '300px', paddingLeft: '5px' }}>
          <table id="poi_table" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th className="checkbox" style={{ width: '1.5em' }}>
                  <input type="checkbox" id="selectPoi" name="selectPoi" />
                  <label htmlFor="selectPoi" style={{ verticalAlign: 'middle' }}></label>
                </th>
                <th>ID POI</th>
                <th>Nom</th>
                <th>Code</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {pois.length > 0 ? (
                pois.map((poi) => (
                  <tr key={poi.idgeof}>
                    <td className="checkbox">
                      <input
                        type="checkbox"
                        id={`poi_${poi.idgeof}_cb`}
                        name="poi_id"
                        value={poi.idgeof}
                        data-numpts={poi.numPoints}
                     
                      />
                      <label htmlFor={`poi_${poi.idgeof}_cb`}></label>
                    </td>
                    <td>{poi.idgeof}</td>
                    <td>{poi.namegeof}</td>
                    <td>{poi.codegeof}</td>
                    <td>{extractObjectName(poi.areageof)}</td>
                    <td>{poi.descriptiongeof}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    Aucun POI associé
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PoiAlarm;
